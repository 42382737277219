
export class BillingUtils {

    static free: string[] = [
        "5 minutes audio and video testimonial length",
        "5 text testimonials",
        "1 Space",
        "10 Email Invites",
        "100 Ai credits",
        "100 MB storage",
        "Unlimited imports",
        "Unlimited embed widgets",
        "All Analytics",
        "Spin wheel and rewards"
    ];

    static basic: string[] = [
        "60 minutes audio and video testimonial length",
        "Unlimited Text Testimonials",
        "3 Spaces",
        "3 Users",
        "1,000 Ai credits",
        "Remove branding",
        "Google rich snippets",
        "Unlimited imports",
        "Unlimited embed widgets",
        "Spin wheel and rewards"
    ];

    static essential: string[] = [
        "120 minutes audio and video testimonial length",
        "Unlimited Text Testimonials",
        "20 Spaces",
        "10 Users",
        "30,000 Ai credits",
        "Remove branding",
        "Google rich snippets",
        "Unlimited imports",
        "Unlimited embed widgets",
        "Spin wheel and rewards"
    ];

    static professional: string[] = [
        "120 minutes audio and video testimonial length",
        "Unlimited Text Testimonials",
        "20 Spaces",
        "10 Users",
        "30,000 Ai credits",
        "Remove branding",
        "Google rich snippets",
        "Unlimited imports",
        "Unlimited embed widgets",
        "Spin wheel and rewards"
    ];

    static enterprise: string[] = [
        "Unlimited minutes video and audio testimonial length",
        "Unlimited Text Testimonials",
        "Unlimited Spaces",
        "Unlimited Users",
        "50,000 Ai credits",
        "Remove branding",
        "Google rich snippets",
        "Unlimited imports",
        "Unlimited embed widgets",
        "Spin wheel and rewards",
        "1 Custom Domain"
    ];

    static appSumoTier1: string[] = [
        "1 Team User",
        "3 Workspaces",
        "30 minutes video and audio testimonial length",
        "Unlimited text testimonials",
        "10,000 Ai credits per month",
        "100 email invites",
        "15 GB storage"
    ];

    static appSumoTier2: string[] = [
        "5 Team Users",
        "10 Workspaces",
        "60 minutes video and audio testimonial length",
        "Unlimited text testimonials",
        "20,000 Ai credits per month",
        "200 email invites",
        "40 GB storage",
        "Remove testimonial branding"
    ];

    static appSumoTier3: string[] = [
        "10 Team Users",
        "20 Workspaces",
        "100 minutes video and audio testimonial length",
        "Unlimited text testimonials",
        "30,000 Ai credits per month",
        "300 email invites",
        "100 GB storage",
        "Remove testimonial branding",
        "Remove loading branding"
    ];

    static appSumoTier4: string[] = [
        "20 Team Users",
        "40 Workspaces",
        "150 minutes video and audio testimonial length",
        "Unlimited text testimonials",
        "40,000 Ai credits per month",
        "400 email invites",
        "200 GB storage",
        "Remove testimonial branding",
        "Remove loading branding"
    ];

    static appSumoTier5: string[] = [
        "Unlimited Team Users",
        "Unlimited Workspaces",
        "Unlimited minutes video and audio testimonial length",
        "Unlimited text testimonials",
        "50,000 Ai credits per month",
        "1,000 email invites",
        "400 GB storage",
        "Remove testimonial branding",
        "Remove loading branding",
        "1 Custom Domain"
    ];

    public static get(plan: Api.Billing.Plans) {

        if (plan === "free") return this.free;
        else if (plan === "Basic") return this.basic;
        else if (plan === "Essential") return this.essential;
        else if (plan === "Professional") return this.professional;
        else if (plan === "Enterprise") return this.enterprise;
        else if (plan === "appsumo_tier_1") return this.appSumoTier1;
        else if (plan === "appsumo_tier_2") return this.appSumoTier2;
        else if (plan === "appsumo_tier_3") return this.appSumoTier3;
        else if (plan === "appsumo_tier_4") return this.appSumoTier4;
        else if (plan === "appsumo_tier_5") return this.appSumoTier5;
        else throw new Error("invalid plan type.");
    }

    static isBundlePlan(plan: string): boolean {

        const bundlePlans = [
            "basic-bundle", "essential-bundle",
            "professional-bundle", "enterprise-bundle"
        ];

        return bundlePlans.includes(plan) ? true : false;
    }

    static getSubscriptionPricing(plan: string) {

        if (plan === "free") return { monthlyPrice: 0, yearlyPrice: 0 };
        else if (plan === "Basic") return { monthlyPrice: 9, yearlyPrice: 7 };
        else if (plan === "Essential") return { monthlyPrice: 24, yearlyPrice: 19 };
        else if (plan === "Professional") return { monthlyPrice: 49, yearlyPrice: 39 };
        else if (plan === "Enterprise") return { monthlyPrice: 99, yearlyPrice: 79 };
        else throw new Error("invalid plan type.");

    }

}