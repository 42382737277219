import { Text } from "@components/text";
import { cn } from "@utils/cn";
import toast from "react-hot-toast";
import ImagePlaceholder from "@assets/image-placeholder.png";
import { useRef } from "react";


type Props = {
    id: string,
    defaultImage?: string,
    file: File | null,
    titleClassName?: string,
    title?: string,
    maxAllowedSizeInMB?: number,
    allowedFormats?: string[],
    imgClassName?: string,
    containerClassName?: string,
    buttonText?: string,
    onChange: (file: File) => void;
};


export function UploadPicture({
    id,
    defaultImage, containerClassName, file, title,
    titleClassName, maxAllowedSizeInMB, imgClassName, buttonText,
    allowedFormats, onChange
}: Props) {

    const formats = allowedFormats ?? ["png", "jpg", "jpeg"];

    const ref = useRef<any>(null);

    const imgSrc = () => {

        if (file) return URL.createObjectURL(file);

        if (defaultImage) return defaultImage;

        return ImagePlaceholder;

    };

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const file = (e.target.files as FileList)[0];

            const format = file.type.split("/")[1].toLowerCase();

            const isValidImageType = formats.includes(format);

            if (!isValidImageType) {
                toast.error("invalid image type");
                e.target.value = "";
                return;
            }

            const sizeInMb = Math.floor((file.size / 1024) / 1024);

            console.log({ sizeInMb, maxAllowedSizeInMB });

            if (sizeInMb > (maxAllowedSizeInMB ?? 3)) {
                toast.error("image too large. image should less 3mb.");
                e.target.value = "";
                return;
            }

            console.log(5);

            onChange(file);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={cn("space-y-3", containerClassName)}>

            {title ?
                <Text className={titleClassName}>
                    {title}
                </Text> : null
            }

            <input
                id={id ?? "change-space-logo"}
                type="file"
                ref={ref.current}
                accept="image/png, image/jpg, image/jpeg"
                className={cn("hidden")}
                onChange={(e) => onFileChange(e)}
            />

            <div className="flex items-center space-x-3">

                <img
                    onInvalid={() => { }}
                    src={imgSrc()}
                    className={cn("w-[40px] h-[40px]", imgClassName)}
                />

                <label
                    htmlFor={id ?? "change-space-logo"}
                    className={cn(`
                        inline-block border-2 border-gray-300 py-1 px-4 rounded-md font-medium tracking-wide
                        transition-all duration-200 ease-in-out cursor-pointer text-sm 
                        hover:bg-blue-100 hover:text-primary hover:border-blue-500
                    `)}
                >

                    {buttonText ?? "Change"}

                </label>

            </div>

        </div>
    );
}