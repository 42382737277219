import { QueryKeys, useGetQueryData } from "@hooks";
import { useSelectTestimonialsStore, useSocialProofStore } from "@store";
import { cn } from "@utils/cn";
import { Fragment } from "react";
import { ProfileSection } from "./profile";
import { TestimonialSection } from "./testimonial";
import { useLocation } from "react-router-dom";

export function ListTestimonials() {

    const location = useLocation();

    const isSocialProofPage = location.pathname.includes("social-proof");

    const testimonialsQuery = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const selectedTestimonialIds = useSelectTestimonialsStore((store) => store.selectedTestimonialIds);

    const onSelect = (item: Api.Testimonials.Type) => {

        if (isSocialProofPage) {

            useSocialProofStore.getState().setImageTemplateContent({
                company: item.customerDetails["company"]?.answer as string ?? "",
                customerName: item.customerDetails["name"]?.answer as string ?? "",
                profileUrl: item.customerDetails["profile"]?.answer as string ?? "",
                rating: item.rating as number,
                role: item.customerDetails["role"]?.answer as string ?? "",
                text: item.textTestimonialContent ?? "",
            });

        }

        useSelectTestimonialsStore.getState().setSelectedTestimonialId(item.id);

    };

    return (
        <div className="h-[88%] max-h-full overflow-y-auto space-y-4 mt-2 pb-14">

            {testimonialsQuery?.testimonials?.length ?
                <Fragment>
                    {testimonialsQuery?.testimonials.map((item, idx) => {

                        const isSelected = selectedTestimonialIds.includes(item.id);

                        if (isSelected === null) return;

                        return (
                            <div
                                key={idx}
                                className={cn(`
                                    bg-white p-4 rounded-lg flex border hover:border-blue-600 cursor-pointer 
                                    transition-all duration-300 ease-in-out
                                `, {
                                    "border-blue-600": isSelected
                                })}
                                onClick={() => onSelect(item)}
                            >

                                <ProfileSection customer={item.customerDetails} />

                                <TestimonialSection response={item} />

                            </div>
                        );
                    })}
                </Fragment> : null
            }

        </div>
    );
}