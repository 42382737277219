import { Input } from "@components/input";
import { useInvitesStore, useUserStore } from "@store";
import { useEffect } from "react";


export function Advance() {

    const { email, name } = useUserStore((store) => ({
        email: store.email,
        name: store.name
    }));

    const { fromName, replayEmail } = useInvitesStore((store) => ({
        replayEmail: store.template.advance.replyEmail,
        fromName: store.template.advance.fromName
    }));

    useEffect(() => {

        if (!fromName && name) useInvitesStore.getState().setAdvance({ fromName: name });

        if (!replayEmail && email) useInvitesStore.getState().setAdvance({ replyEmail: email });

    }, []);

    return (
        <div className="space-y-2">

            <Input
                type="email"
                label="Replay Email"
                value={replayEmail ? replayEmail : email}
                onChange={(e) => useInvitesStore.getState().setAdvance({ replyEmail: e.target.value })}
            />

            <Input
                type="text"
                label="From Name"
                value={fromName ? fromName : name}
                onChange={(e) => useInvitesStore.getState().setAdvance({ fromName: e.target.value })}
            />

        </div>
    );
}