import { useInvitesStore, } from '@store';
import { Config } from '@config';
import { cn } from '@utils/cn';
import { useActiveSpace } from '@hooks';
import { Button } from '@components/button';


export function EmailTemplate() {

    const { formId } = useActiveSpace();

    const { message, design, removeBranding } = useInvitesStore((store) => ({
        message: store.template.message,
        design: store.template.design,
        removeBranding: store.template.design.removeBranding
    }));

    const url = `${Config.RenderEngineUrl()}/${formId}`;

    return (
        <div className={cn(`
            border shadow-md rounded-lg w-[75%] m-10 bg-white
            flex flex-col items-center justify-center p-6
        `)}>

            {design.logo ?
                <img
                    src={design.logo}
                    className="w-[60px] h-[60px]"
                /> : null
            }

            <p
                className="text-base font-normal mt-3"
                dangerouslySetInnerHTML={{ __html: message.content }}
            />

            <Button
                disabled={true}
                className="w-full text-sm font-medium tracking-wide mt-2"
                style={{
                    backgroundColor: design.buttonColor,
                }}
            >
                {design.buttonText}
            </Button>


            <span className="w-full text-xs font-normal text-gray-600 mt-2 px-1">
                Button not working? Paste this URL in the browser <br />
                <a
                    href={url}
                    className="text-blue-400 hover:underline"
                >
                    {url}
                </a>
            </span>

            {removeBranding === false ?
                <p className='text-base font-medium mt-4'>
                    Send with GoZen Testimonials
                </p> : null
            }

        </div>
    );
}