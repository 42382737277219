import { MdDelete } from "react-icons/md";
import { cn } from "@utils/cn";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSocialProofStore } from "@store";
import { QueryKeys, useSocialProofApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment } from "react";
import { EmptyIcon } from "@components/icons";
import { Button } from "@components/button";

type Props = {
    templates: Api.SocialProof.getAll["templates"];
};

export function SavedTemplates({ templates }: Props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { deleteQuery } = useSocialProofApiClient();

    const onClick = (id: string) => {

        const template = templates.filter(item => item.id === id)[0];

        if (!template) return;

        useSocialProofStore.getState().setImageTemplate({
            background: template.background,
            branding: template.branding,
            content: {
                company: template.placeHolder.customerDetails["company"].answer as string,
                customerName: template.placeHolder.customerDetails["name"].answer as string,
                profileUrl: template.placeHolder.customerDetails["profile"].answer as string,
                // rating: (template.ra ?? 0),
                role: template.placeHolder.customerDetails["role"].answer as string,
                // text: template.placeHolder.textTestimonialContent as string
                text: "",
                rating: 0
            },
            settings: {
                showProfile: template.showProfilePicture,
                showRating: template.showRating
            }
        });

        let redirect = `edit/image/${id}`;

        navigate(redirect);

    };

    const onDelete = async (id: string) => {
        try {

            await deleteQuery.mutateAsync(id);

            const getAllTemplatesQuery = queryClient.getQueryData<Api.SocialProof.getAll>([QueryKeys.socialProof.getTemplates]);

            if (!getAllTemplatesQuery?.templates.length) return;

            const updateTemplates = getAllTemplatesQuery.templates.filter(item => item.id !== id);

            queryClient.setQueryData(
                [QueryKeys.socialProof.getTemplates],
                { templates: updateTemplates } as Api.SocialProof.getAll
            );

        } catch (err) {
            console.log(err);
            toast.error("Something went wrong, Please contact support.");
        }
    };

    const onHandleDelete = (id: string) => {
        toast.promise(
            onDelete(id),
            {
                error: "Please contact support",
                loading: "loading...",
                success: () => "Templated deleted."
            }
        );
    };

    return (
        <div className="my-4 flex items-center gap-4 flex-wrap">

            {templates.length ?
                <Fragment>

                    {templates.map((item, idx) => (
                        <div
                            key={idx}
                            className={cn(`
                                border border-gray-300 bg-gray-100 rounded-lg 
                                w-[200px] h-[200px] relative
                                cursor-pointer group shadow-lg overflow-hidden
                                group 
                            `)}
                            onClick={() => onClick(item.templateId)}
                        >

                            <img
                                src={item.previewImage}
                                className={cn(`
                                    group-hover:scale-[2.1] scale-[1.9] w-full rounded-lg
                                    transition-all duration-300 ease-in-out h-full
                                `)}
                                alt="Template Preview Image"
                            />

                            <div className="invisible group-hover:visible z-40 absolute bottom-5 right-5">

                                <div
                                    className={cn(`
                                        p-2 bg-gray-200 rounded-md hover:bg-red-200 text-red-600
                                        transition-all duration-300 ease-in-out    
                                    `)}
                                    onClick={() => onHandleDelete(item.id)}
                                >

                                    <MdDelete className="w-5 h-5" />

                                </div>


                            </div>

                        </div>
                    ))}

                </Fragment> :
                <div className="w-full h-full flex flex-col items-center justify-center space-y-4 my-10">

                    <EmptyIcon />

                    <p>
                        You don't have any widgets. Let's create one now
                    </p>

                    <Button
                        className="w-auto tracking-wider"
                        onClick={() => {
                            searchParams.set("tab", "images");
                            setSearchParams(searchParams);
                        }}
                    >
                        Create Template
                    </Button>

                </div>
            }

        </div>
    );
}