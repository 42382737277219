import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget1_Settings() {

    const settings = useWidgetsStore((store) => store.widget1);

    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-2">

            <Input
                value={settings.width}
                label="Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("width", value, setErrors);
                    useWidgetsStore.getState().setWidget1Settings({ width: value });
                }}
                error={errors?.width}
            />

            <Input
                value={settings.height}
                label="Height"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("height", value, setErrors);
                    useWidgetsStore.getState().setWidget1Settings({ height: value });
                }}
                error={errors?.height}
            />

            <ColorPicker
                defaultColor={settings.borderColor}
                label="Border Color"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ borderColor: e })}
            />

            <Input
                value={settings.borderWidth}
                label="Border Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("borderWidth", value, setErrors);
                    useWidgetsStore.getState().setWidget1Settings({ borderWidth: value });
                }}
                error={errors?.borderWidth}
            />



            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ backgroundColor: e })}
            />


            <ColorPicker
                defaultColor={settings.ratingCounterBgColor}
                label="Rating Counter Bg Color"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ ratingCounterBgColor: e })}
            />


            <ColorPicker
                defaultColor={settings.ratingCounterTextColor}
                label="Rating Counter Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ ratingCounterTextColor: e })}
            />



            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ customCSS: e.target.value })}
            />





            <ColorPicker
                defaultColor={settings.textColor}
                label="Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget1Settings({ textColor: e })}
            />

            <Input
                value={settings.fontSize}
                label="Font Size"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("fontSize", value, setErrors);
                    useWidgetsStore.getState().setWidget1Settings({ fontSize: value });
                }}
                error={errors?.fontSize}
            />

        </div>
    );
}