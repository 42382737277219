import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { QueryKeys, useEmailInvitesApiClient } from "@hooks";
import { useCommonStore, useInvitesStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";


export function DeleteInvites() {

    const showPopup = useCommonStore((store) => store.activePopup === "delete-invites");
    const selectedRows = useInvitesStore((store) => store.selectedRowsToDelete);

    const { deleteInvites } = useEmailInvitesApiClient({});

    const queryClient = useQueryClient();

    const query = queryClient.getQueryData<Api.EmailInvites.GetAll>([QueryKeys.emailInvites.getAll]);

    const onDelete = async () => {
        try {

            await deleteInvites.mutateAsync();

            if (!query) return;

            const invites = query.invites.filter(item => !selectedRows.includes(item.id));

            queryClient.setQueryData([QueryKeys.emailInvites.getAll], { ...query, invites });

            toast.success("Invites deleted successfully.");

        } catch (err) {
            console.log(err);
            toast.error("Something went wrong.");
        }
    };

    if (selectedRows.length === 0) return null;

    return (
        <Fragment>

            <Button
                variant="danger"
                className="w-auto text-sm font-medium mt-0"
                onClick={() => useCommonStore.getState().setActivePopup("delete-invites")}
            >

                <MdDelete className="w-4 h-4 text-white" />

                <p className="text-white font-medium ml-0.5 tracking-wider">
                    <span className="hidden md:inline"> Delete </span>
                </p>

            </Button>

            {showPopup ?
                <Popup
                    width="30%"
                    name="Delete Invites"
                    onClose={() => useCommonStore.getState().setActivePopup("")}
                >

                    <div className="my-3 space-y-4">

                        <p className="text-sm font-normal">
                            You're about to delete. It'll be gone forever and we won't be able to recover it.
                        </p>

                        <p className="text-sm font-normal">
                            Are you sure? you want to delete?
                        </p>

                    </div>

                    <div className="flex items-center justify-end space-x-3 mt-0 pt-2">

                        <SecondaryButton
                            disabled={deleteInvites.isPending}
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => useCommonStore.getState().setActivePopup("")}
                        >
                            Cancel
                        </SecondaryButton>

                        <Button
                            disabled={deleteInvites.isPending}
                            loading={deleteInvites.isPending}
                            variant="danger"
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => onDelete()}
                        >
                            Delete
                        </Button>

                    </div>

                </Popup> : null
            }


        </Fragment>
    );
}