import { QueryKeys } from "@hooks";
import { useWidgetsStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { WidgetsHeader } from "./header";
import { SelectTestimonials } from "@components/selectTestimonials";

export function WidgetTemplatePreview() {

    const { saving, selectTestimonials } = useWidgetsStore((store) => ({
        saving: store.saving,
        selectTestimonials: store.selectTestimonials
    }));

    const { id } = useParams();

    const queryClient = useQueryClient();

    const getAllQuery = queryClient.getQueryData<Api.Widget.getAll>([QueryKeys.widget.getAll]);

    const ref = useRef<any>(null);

    useEffect(() => {

        const query = queryClient.getQueryData<Api.Widget.getAll>([QueryKeys.widget.getAll]);

        if (ref.current && !ref.current.src) {

            ref.current.src = query?.widgets.filter(item => item.widgetId === id as string)[0].src;

        }

        if (getAllQuery && getAllQuery.widgets && ref.current && ref.current.src) {

            const src = query?.widgets.filter(item => item.widgetId === id as string)[0].src;

            ref.current.src = "";

            setTimeout(() => ref.current.src = src, 100);

        }


    }, [saving]);


    return (
        <div className="h-full w-full max-h-full max-w-full">

            {selectTestimonials ?
                <SelectTestimonials
                    onHandleApply={() => { }}
                    onHandleClear={() => { }}
                /> :
                <Fragment>

                    <WidgetsHeader />

                    <div
                        id="widget-preview"
                        className="w-full h-full flex items-center justify-center dotted-bg"
                    >

                        <iframe
                            ref={ref}
                            className="w-full h-[70vh]"
                        />

                    </div>

                </Fragment>
            }

        </div>
    );
}