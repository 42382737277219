import { SocialProof } from "./socialProof.type";

export const SocialProofState: SocialProof.State = {
    imageTemplate: {
        content: {
            text: "",
            customerName: "",
            role: "",
            company: "",
            rating: 0,
            profileUrl: "",
        },
        settings: {
            showProfile: true,
            showRating: true,
        },
        background: {
            color: "",
            image: "",
            transparent: false,
            gradient: ""
        },
        branding: {
            logo: "",
            highLightColor: "",
            remove: false,
        },
    },
    videoTemplate: {
        templateId: "",
        background: {
            color: "",
            image: "",
        },
        showBranding: true,
        videoUrl: ""
    }
};