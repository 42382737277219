
export function checkSize(name: string, value: string, setErrors: any) {
    if (value === "" || name === "" || setErrors === null) return;
    value.toLowerCase();

    // Improved regex to allow floating point numbers like 2.1rem or .5rem
    const isValid = /^(\d+(\.\d*)?|\.\d+)(px|%|rem)$/.test(value) || value === "auto" || value === "fit-content" || value === "max-content" || value === "min-content" || value === "inherit" || value === "-webkit-fill-available" || value === "unset" || value === "medium" || value === "thick" || value === "thin" || value === "revert" || value === "revert-layer" || value === "large" || value === "larger" || value === "medium" || value === "small" || value === "smaller" || value === "x-large" || value === "x-small" || value === "-webkit-xxx-large" || value === "xx-small" || value === "xx-large" || value === "xxx-large";



    setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: isValid ? "" : "Enter a valid size (e.g., '100px', '50%', '1rem')",
    }));

}