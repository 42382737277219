import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useImportTestimonialStore } from "@store";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";


export function VideoImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const navigate = useNavigate();

    useEffect(() => { useImportTestimonialStore.getState().setType("video"); }, []);

    const onImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const file = testimonial.testimonial.video.file;

            if (!file) {
                toast.error("No video file selected.");
                return;
            }

            const fileSizeExceed = ((1024 * 1024) * 100) < file.size; // 100mb

            if (fileSizeExceed) {
                toast.error("video file exceeds max file limit of 100mb.");
                return;
            }

            await manualImport.mutateAsync({
                testimonial,
                formId: activeSpace.formId,
                source: "gozenTestimonial"
            });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

            navigate("/testimonials");

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };


    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import a Video Testimonial"
            onImport={() => onImport()}
        >

            <ManuallyImport
                fields={{
                    email: null,
                    tagline: {},
                    avatar: {},
                    testimonial: {
                        text: { content: false, imageAttachments: false },
                        audio: { transcribedText: false, url: false },
                        video: { url: true, transcribedText: true }
                    },
                    company: false,
                    role: false,
                    companyLogo: false,
                    sourceUrl: null,
                }}
            />

        </ImportCard>
    );
}

