import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { useCommonStore } from "@store";
import { Config } from "@config";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import toast from "react-hot-toast";

export function SharePopup() {

    const { id } = useParams();

    const ref = useRef<HTMLDivElement>(null);

    const onClose = () => useCommonStore.getState().setActivePopup("");

    return (
        <Popup
            width="35%"
            name="Widget Embed Code"
            onClose={() => onClose()}
        >

            <div className="">

                <p className="text-sm font-normal mt-3 mb-3">
                    Paste this code in your html, for this widget to appear in your website.
                </p>

                <div
                    ref={ref}
                    className="bg-black rounded-md max-w-full overflow-hidden p-4 text-white text-sm font-normal"
                >

                    {`<script src="${Config.WidgetEmbedScript()}" type="text/javascript" async></script>
                        <div class="gz-testimonial-widget-embed" data-id="${id}"></div>`}

                </div>

                <div className="mt-5 flex items-center justify-end space-x-2">

                    <SecondaryButton
                        className="w-auto text-sm font-medium"
                        onClick={() => onClose()}
                        text="Cancel"
                    />

                    <Button
                        className="w-auto text-sm font-medium"
                        text={"Copy code"}
                        onClick={() => {

                            if (ref.current) {
                                window.navigator.clipboard.writeText(ref.current.innerText);
                                toast.success("Copied!...");
                                onClose();
                            }

                        }}
                    />

                </div>

            </div>

        </Popup>
    );
}