import { useRef, } from "react";
import toast from "react-hot-toast";
import { GenerateProfilePic } from "../../../utils/generateProfilePic";
import { useUserStore } from "@store";
import { useUsersApiClient } from "@hooks";
import { cn } from "@utils/cn";
import { v4 as uuid } from "uuid";
import { AxiosError } from "axios";

type Props = {};

export const ProfilePicture: React.FC<Props> = ({ }) => {

    const ref = useRef<any>(null);

    const { name, avatarUrl } = useUserStore((store) => ({
        name: store.name,
        avatarUrl: store.avatarUrl
    }));

    const { uploadAsserts } = useUsersApiClient({});

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const file = (e.target.files as FileList)[0];

            const format = file.type.split("/")[1].toLowerCase();

            const isValidImageType = format === "png" || format === "jpg" || format === "jpeg";

            if (!isValidImageType) {
                toast.error("invalid image type");
                e.target.value = "";
                return;
            }

            const sizeInMb = Math.floor((file.size / 1024) / 1024);

            if (sizeInMb > 3) {
                toast.error("image too large. image should less 3mb.");
                e.target.value = "";
                return;
            }

            const data = await uploadAsserts.mutateAsync({
                file,
                type: "profile",
                id: uuid()
            });

            useUserStore.getState().setAvatarUrl(data?.url ?? "");

        } catch (err) {
            console.log(err);

            if (err instanceof AxiosError) {
                toast.error(err.message);
            }

        }
    };

    return (
        <div className="w-[40%] bg-gray-50 h-full flex items-center justify-center py-6 border rounded-md mr-3 p-2">

            <div className="flex flex-col items-center">
                <div
                    className="p-sm rounded-full overflow-hidden"
                    style={{
                        width: "136px",
                        height: "136px"
                    }}
                >
                    <img
                        ref={ref}
                        alt="User"
                        src={avatarUrl}
                        onError={() => { ref.current.src = GenerateProfilePic(name[0] ?? "A"); }}
                        className={cn(`rounded-full flex-shrink-0 object-cover object-center border`)}
                        style={{
                            width: "128px",
                            height: "128px",
                        }}
                    />
                </div>

                <p className="break-words mt-3 text-center text-xs font-normal text-gray-500 tracking-wide">
                    Allowed *.jpeg, *jpg, *png max size of 3 MB
                </p>

                <div>
                    <div
                        title="Change Picture"
                        className={cn(`
                            inline-flex items-center cursor-pointer p-2 m-7 justify-center rounded-lg bg-opacity-10 bg-primary w-full
                            hover:bg-blue-200 transition-all duration-300 ease-in-out
                        `)}
                        style={{ width: "166px" }}
                    >

                        <label
                            className="text-primary cursor-pointer font-normal"
                            htmlFor={!uploadAsserts.isPending ? "user-profile-upload" : ""}
                        >
                            {uploadAsserts.isPending ? "uploading..." : "choose your file"}
                        </label>

                        <input
                            id="user-profile-upload"
                            type="file"
                            onChange={(e) => onFileChange(e)}
                            accept="image/png, image/jpg, image/jpeg"
                            style={{ display: 'none' }}
                            multiple
                        />

                    </div>
                </div>
            </div>

        </div>
    );
};