import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/login/login.page";
import { SignUpPage } from "./pages/signup/signup";
import { LoginSuccess } from "./pages/loginSuccess/loginSuccess.main";
import { DashboardLayout } from '@components/layouts/dashboard';
import { AuthLayout } from "@components/layouts/auth";
import { Logout } from "./pages/logout/logout.page";
import { SettingsPage } from "./pages/settings/settings.page";
import { OnBoardingPage } from "./pages/onboarding/onboarding.page";
import { CreateForm } from "./pages/dashboard/form/create/form.page";
import { TestimonialsPage } from "./pages/dashboard/testimonials/testimonials.page";
import { ImportTestimonialsPage } from "./pages/dashboard/importTestimonials/import.page";
import { DashboardPage } from "./pages/dashboard/overall/overall.page";
import { InvitePage } from "./pages/dashboard/invite/main/page.invite";
import { TeamsPage } from "./pages/dashboard/teams/main.teams";
import { FormLayout } from "@components/layouts/form";
import { ShareForm } from "./pages/dashboard/form/share/share.page";
import { InviteTemplatePage } from "./pages/dashboard/invite/template/page.template";
import { CheckOutPage } from "./pages/settings/checkout/checkout.page";
import { CheckOutStatusPage } from "./pages/settings/checkout/status.page";
import { VerifyEmailPage } from "./pages/verifyEmail/verifyEmail";
import { CaseStudyPage } from "./pages/dashboard/testimonials/caseStudy/main.page";
import { SocialProofPage } from "./pages/dashboard/socialProof/main.page";
import { SocialProofTemplateEditor } from "./pages/dashboard/socialProof/editor/main.page";
import { IntegrationsPage } from "./pages/integrations/main.page";
import { OAuthPage } from "./pages/oauth/main.page";
import { JoinTeamPage } from "./pages/joinTeam/main.join";
import { ChromeExtensionAuthPage } from "./pages/extension/auth/main.auth";
import { ChromeExtensionImportTestimonialsPage } from "./pages/extension/import/main.import";
import { ForgotPasswordPage } from "./pages/forgotPassword/forgot.page";
import { ResetForgotPasswordPage } from "./pages/forgotPassword/reset.page";
import { AppSumoSignupPage } from "./pages/signup/appsumoSignup.page";
import { WidgetPage } from "./pages/dashboard/widgets/main.page";
import { EditWidgetPage } from "./pages/dashboard/widgets/edit.page";
import { WallOfLovePage } from "./pages/dashboard/wallOfLove/main/main.page";
import { EditWallOfLovePage } from "./pages/dashboard/wallOfLove/edit/edit.page";


export function App() {
    return (
        <Routes>

            <Route path="/login" element={<LoginPage />} />

            <Route element={<AuthLayout />}>

                <Route path="/signup">

                    <Route index element={<SignUpPage />} />

                    <Route path="appsumo" element={<AppSumoSignupPage />} />

                </Route>

                <Route path="/login/success" element={<LoginSuccess />} />

                <Route path="/logout" element={<Logout />} />

                <Route path="/verify-email" element={<VerifyEmailPage />} />

                <Route path="/forgot-password">

                    <Route index element={<ForgotPasswordPage />} />

                    <Route path="reset" element={<ResetForgotPasswordPage />} />

                </Route>

            </Route>

            <Route path="/team/join" element={<JoinTeamPage />} />

            <Route path="/" element={<DashboardLayout />}>

                <Route index element={<DashboardPage />} />

                <Route path="form" element={<FormLayout />}>

                    <Route index element={<CreateForm />} />

                    <Route path="share" element={<ShareForm />} />

                </Route>

                <Route path="testimonials">

                    <Route index element={<TestimonialsPage />} />

                    {/* <Route path=":id" element={<TestimonialDetailsPage />} /> */}

                    <Route path="case-study/:id" element={<CaseStudyPage />} />

                </Route>

                {/* wall of love  */}
                <Route path="wall-of-love">

                    <Route index element={<WallOfLovePage />} />

                    <Route path="edit/:id" element={<EditWallOfLovePage />} />

                </Route>

                {/* social proof page to manage image and video templates */}
                <Route path="social-proof">

                    <Route index element={<SocialProofPage />} />

                    <Route path="edit/:type/:id" element={<SocialProofTemplateEditor />} />

                </Route>

                <Route path="import-testimonials" element={<ImportTestimonialsPage />} />

                <Route path="onboarding" element={<OnBoardingPage />} />

                <Route path="settings" element={<SettingsPage />} />

                <Route path="integrations" element={<IntegrationsPage />} />

                <Route path="invites">

                    <Route index element={<InvitePage />} />

                    <Route path="template" element={<InviteTemplatePage />} />

                </Route>

                {/* widgets */}
                <Route path="widgets">

                    <Route index element={<WidgetPage />} />

                    <Route path="edit/:id" element={<EditWidgetPage />} />

                </Route>

                <Route path="checkout">

                    <Route index element={<CheckOutPage />} />

                    <Route path="status" element={<CheckOutStatusPage />} />

                </Route>

                <Route path="teams" element={<TeamsPage />} />

            </Route>

            <Route path="/oauth/callback/:app" element={<OAuthPage />} />

            {/* chrome extension pages */}
            <Route path="/extension">

                <Route index element={<ChromeExtensionAuthPage />} />

                <Route path="import" element={<ChromeExtensionImportTestimonialsPage />} />

            </Route>

            <Route path="/*" element={<Navigate to={"/"} replace={true} />} />

        </Routes>
    );
}