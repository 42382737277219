import { UndefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { CustomQueryParams, useCustomQuery } from "../useCustomQuery";
import { ParseAxiosError } from "@lib/axios";

type Params = {
    verifyTokenParams?: {
        params: Partial<UndefinedInitialDataOptions>;
    },
    logoutParams?: {
        params?: Partial<UseMutationOptions>;
    };
};

export function useAuthApiClient({ verifyTokenParams, logoutParams }: Params) {

    const login = useMutation({
        mutationKey: [QueryKeys.auth.login],
        mutationFn: async (token: string) => {
            try {

                const lastActiveSpaceId = (localStorage.getItem("lastActiveSpaceId") as string) ?? null;

                return (await axios.post<Api.Auth.Login>("/api/v1/auth/login", {
                    token,
                    spaceId: lastActiveSpaceId
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const signUp = useMutation({
        mutationKey: [QueryKeys.auth.signUp],
        mutationFn: async (body: Api.Auth.signupParams) => {
            try {
                return (await axios.post("/api/v1/auth/signup", body)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const resendVerificationMail = useMutation({
        mutationKey: [QueryKeys.auth.resendVerificationMail],
        mutationFn: async (email: string) => {
            try {
                return (await axios.post("/api/v1/auth/email/verify/resend", { email })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
    });

    const verifyToken = useQuery({
        enabled: false,
        queryKey: [QueryKeys.auth.verifyToken],
        queryFn: async () => {
            try {

                return await axios.get<Api.Auth.verifyToken>("/api/v1/auth");

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...verifyTokenParams?.params
    });

    const verifyEmail = useMutation({
        mutationKey: [QueryKeys.auth.verifyEmail],
        mutationFn: async (token: string) => {
            try {
                return (await axios.post<{ redirect: boolean; }>("/api/v1/auth/email/verify", { token })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const logout = useMutation({
        mutationKey: [QueryKeys.auth.logout],
        mutationFn: async () => {
            try {
                return (await axios.post("/api/v1/auth/logout")).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...logoutParams?.params
    });

    /* change email */

    const changeEmail = useMutation({
        mutationKey: [QueryKeys.auth.changeEmail],
        mutationFn: async (email: string) => {
            try {
                return (await axios.post("/api/v1/auth/email/change", { email })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const changeEmailVerify = useMutation({
        mutationKey: [QueryKeys.auth.changeEmailVerify],
        mutationFn: async (token: string) => {
            try {

                return await axios.post("/api/v1/auth/email/change/verify", { token });

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    /* change password  */

    const changePassword = useMutation({
        mutationKey: [QueryKeys.auth.changePassword],
        mutationFn: async (password: string) => {
            try {
                return (await axios.post("/api/v1/auth/password/change", { password })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const sendForgotPasswordVerifyToken = useMutation({
        mutationKey: [QueryKeys.auth.resetForgotPassword],
        mutationFn: async (email: string) => {
            try {

                return (await axios.post("/api/v1/auth/password/forgot/verify", { email })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    /* reset password */

    const resetForgotPassword = useMutation({
        mutationKey: [QueryKeys.auth.resetForgotPassword],
        mutationFn: async (params: { token: string, password: string; }) => {
            try {

                return (await axios.post("/api/v1/auth/password/forgot/reset", {
                    ...params
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        login,
        signUp,
        resendVerificationMail,
        verifyToken,
        logout,
        changeEmail,
        changeEmailVerify,
        changePassword,
        verifyEmail,
        sendForgotPasswordVerifyToken,
        resetForgotPassword
    };

}