import { Button, SecondaryButton } from "@components/button";
import { QueryKeys, useActiveSpace, useWidgetsApiClient } from "@hooks";
import { useCommonStore, useWidgetsStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { SharePopup } from "./share.popup";

export function WidgetsHeader() {

    const { id } = useParams();

    const [searchParams] = useSearchParams();

    const templateId = searchParams.get("templateId") ?? "";

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const activePopup = useCommonStore((store) => store.activePopup);

    const { save } = useWidgetsApiClient();

    const activeSpace = useActiveSpace();

    const getSettings = () => {

        if (templateId === "w1") return useWidgetsStore.getState().widget1;
        else if (templateId === "w2") return useWidgetsStore.getState().widget2;
        else if (templateId === "w3") return useWidgetsStore.getState().widget3;
        else if (templateId === "w4") return useWidgetsStore.getState().widget4;
        else if (templateId === "w5") return useWidgetsStore.getState().widget5;
        else if (templateId === "w7") return useWidgetsStore.getState().widget7;
        else if (templateId === "w8") return useWidgetsStore.getState().widget8;
        else return;

    };

    const onSave = async () => {
        try {

            useWidgetsStore.getState().setSaving(true);

            const settings = getSettings();

            if (!settings) return;

            const isDynamic = templateId === "w1" || templateId === "w5" || templateId === "w7";

            const query = queryClient.getQueryData<Api.Widget.getAll>([QueryKeys.widget.getAll]);

            if (!query) return;

            const name = query.widgets.filter((item) => item.templateId === templateId)[0].name;

            await save.mutateAsync({
                settings,
                create: false,
                name,
                templateId: templateId as string,
                type: isDynamic ? "dynamic" : "static",
                widgetId: id as string,
                formId: activeSpace.formId,
            });

            toast.success("Successfully, saved your widget.");

            useWidgetsStore.getState().setSaving(false);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="border-b flex items-center justify-between w-full space-x-4 py-3 px-4 bg-white">

            <SecondaryButton
                disabled={save.isPending}
                className="text-sm font-medium w-auto px-3"
                onClick={() => navigate("/widgets")}
            >

                <MdArrowBackIos className="w-4 h-4 mr-1" />

                <p> Back </p>

            </SecondaryButton>

            <div className="flex items-center space-x-4">

                <SecondaryButton
                    disabled={save.isPending}
                    loading={save.isPending}
                    className="text-sm"
                    onClick={() => onSave()}
                >
                    Save Changes
                </SecondaryButton>

                <Button
                    disabled={save.isPending}
                    className="mt-0 w-auto text-sm font-medium"
                    onClick={() => {
                        useCommonStore.getState().setActivePopup("get-widget-embed-code");
                    }}
                >
                    Share
                </Button>

            </div>

            {activePopup === "get-widget-embed-code" ? <SharePopup /> : null}

        </div>
    );
}