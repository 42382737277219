import { Button, SecondaryButton } from "@components/button";
import { QueryKeys, useEmailInvitesApiClient, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";
import { motion } from "framer-motion";
import { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import "./style.css";
import { z } from "zod";
import { useCommonStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

type EmailInvite = {
    name: string,
    email: string;
};

export function InputEmails() {

    const navigate = useNavigate();

    const { sendInvite } = useEmailInvitesApiClient({});

    const queryClient = useQueryClient();

    const invitesQuery = useGetQueryData<Api.EmailInvites.GetAll>([QueryKeys.emailInvites.getAll]);

    const [invites, setInvites] = useState<Array<string>>([]);
    const [emails, setEmails] = useState<Array<EmailInvite>>([]);
    const [limitReached, setLimitReached] = useState(false);

    const onChange = (e: string[]) => {

        setLimitReached(((e.length + emails.length) > 100) ? true : false);

        const validEmails = e.filter(email => z.string().email().safeParse(email).success ? email : null);

        setEmails([...validEmails.map(invite => {

            let name = invite.split('@')[0];

            return { email: invite, name };

        })]);

        setInvites([...validEmails]);

    };

    const onSendInvites = async () => {

        if (emails.length === 0) {
            toast.error("Enter a email to send invites.");
            return;
        }

        const newInvites = await sendInvite.mutateAsync(emails);

        useCommonStore.getState().setActivePopup("");

        const updatedInvites: Api.EmailInvites.GetAll = {
            template: newInvites.templates,
            invites: [...invitesQuery.invites, ...newInvites.invites]
        };

        queryClient.setQueryData([QueryKeys.emailInvites.getAll], updatedInvites);

        navigate("/invites/template");

    };

    return (
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
            className={cn(`
                h-auto w-full rounded-lg flex flex-col items-center justify-center mt-4
            `)}
        >

            <div className="w-full h-full relative">

                <TagsInput
                    name="emails"
                    placeHolder="Enter email here..."
                    value={invites}
                    disabled={limitReached}
                    onChange={(e) => onChange(e)}
                />

                <span className={cn("text-xs font-medium absolute top-2 right-2 bottom-2", {
                    "text-red-600": limitReached
                })}>
                    {emails.length + 1} / 100
                </span>

            </div>

            <div className="mt-4 flex items-center justify-end space-x-4 w-full">

                <SecondaryButton
                    disabled={sendInvite.isPending}
                    className="text-sm font-medium tracking-wide w-auto"
                    onClick={() => onChange([])}
                >
                    Clear
                </SecondaryButton>

                <Button
                    loading={sendInvite.isPending}
                    disabled={sendInvite.isPending}
                    className="text-sm font-medium tracking-wide w-auto"
                    onClick={() => onSendInvites()}
                >
                    Send Invite
                </Button>

            </div>

        </motion.div>
    );
}