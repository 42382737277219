import { useParams } from "react-router-dom";
import { Template1 } from "./template1/template1";
import { Template2 } from "./template2/template2";
import { Template3 } from "./template3/template3";
import { Template4 } from "./template4/template4";
import { Template5 } from "./template5/template5";
import { Template6 } from "./template6/template6";
import { Template7 } from "./template7/template7";
import { Template8 } from "./template8/template8";
import { Template9 } from "./template9/template9";
import { SocialProofImageTemplateHeader } from "./header";

export function ImageTemplates() {

    const { id } = useParams<EditWidgetPageParams>();

    return (
        <div className="w-full h-full max-h-full overflow-hidden relative">

            <SocialProofImageTemplateHeader />

            <div className="h-full max-h-full flex items-center justify-center dotted-bg">

                {id === "i1" ? <Template1 /> : null}

                {id === "i2" ? <Template2 /> : null}

                {id === "i3" ? <Template3 /> : null}

                {id === "i4" ? <Template4 /> : null}

                {id === "i5" ? <Template5 /> : null}

                {id === "i6" ? <Template6 /> : null}

                {id === "i7" ? <Template7 /> : null}

                {id === "i8" ? <Template8 /> : null}

                {id === "i9" ? <Template9 /> : null}

            </div>

        </div>
    );
}