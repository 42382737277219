import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { ImportCard } from "../manuallyImport/main.layout";
import { Fragment, useState } from "react";
import { ManuallyImport } from "../manuallyImport/main.import";
import { Input } from "@components/input";
import { z } from "zod";

const schema = z.object({
    url: z.string().url().refine((val) => {
        try {
            return new URL(val).host === "www.google.com";
        } catch (err) {
            return false;
        }
    }, "Enter a valid post url.")
});

export function GoogleReviewImport() {

    const { formId } = useActiveSpace();

    const { manualImport, instagramImporter } = useImportTestimonialApiClient();

    const [showManuallyImport, setShowManuallyImport] = useState(false);

    const [reviewUrl, setReviewUrl] = useState("");
    const [error, setError] = useState("");

    const onChange = (val: string) => {

        setReviewUrl(val);

        if (!val && error) setError("");

        const result = schema.safeParse({ url: val });

        if (result.success === false) setError(result.error.issues[0].message);
        else setError("");

    };

    const onImport = async () => {
        try {

            // const doc = document.querySelectorAll("iframe");

            // const src = (doc[0] as any).src as string;

            // if (!src) return;

            // const result = schema.safeParse({ url: src });

            // console.log(src);

            // if (result.success === false) {
            //     toast.error(result.error.message);
            //     return;
            // }

            // await instagramImporter.mutateAsync({
            //     formId, url: src
            // });

            // setPostUrl("");

            // toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    const onManualImport = async () => {
        try {

            // const testimonial = useImportTestimonialStore.getState().testimonial;

            // const result = schema.safeParse({
            //     name: testimonial.name,
            //     content: testimonial.testimonial.text.content
            // });

            // if (result.success === false) {

            //     result.error.errors.forEach(item => {

            //         let key = item.path[0];

            //         useImportTestimonialStore.getState().setError({ [key]: item.message });

            //     });

            // }

            // await manualImport.mutateAsync({
            //     testimonial,
            //     formId,
            //     source: "instagram"
            // });

            // useImportTestimonialStore.getState().setClear();

            // toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending || instagramImporter.isPending}
            title="Import from Google Reviews"
            onImport={() => showManuallyImport ? onManualImport() : onImport()}
        >

            {showManuallyImport ?

                <ManuallyImport
                    fields={{
                        email: null,
                        company: false,
                        companyLogo: false,
                        testimonial: {
                            text: { content: true, imageAttachments: true },
                            audio: { url: false, transcribedText: false },
                            video: { url: false, transcribedText: false }
                        },
                        role: false,
                        sourceUrl: {
                            label: "Google Review URL",
                            placeholder: "https://www.google.com/search?q=gozen"
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="Google Review URL"
                        placeholder="https://www.google.com/search?q=gozen"
                        required={true}
                        value={reviewUrl}
                        onChange={(e) => onChange(e.target.value)}
                    />

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                        onClick={() => setShowManuallyImport(true)}
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>
            }


        </ImportCard>
    );
}