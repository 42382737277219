import { Select } from "@components/select";
import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore, useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { InlineCTA } from "./inlineCta";


export function WallOfLove_Content() {

    const content = useWallOfLoveStore((store) => store.settings.content);

    return (
        <div className="space-y-4 h-full pb-16">

            <TextToggle
                isActive={content.showDates}
                name="Show Dates"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateContent({
                    showDates: !content.showDates
                })}
            />

            <TextToggle
                isActive={content.showRatings}
                name="Show Rating"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateContent({
                    showRatings: !content.showRatings
                })}
            />

            <TextToggle
                isActive={content.showCustomerPhoto}
                name="Show Customer Photo"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateContent({
                    showCustomerPhoto: !content.showCustomerPhoto
                })}
            />

            <TextToggle
                isActive={content.showGravatarPhotoIfNotAvailable}
                name="Use Gravatar if available"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateContent({
                    showGravatarPhotoIfNotAvailable: !content.showGravatarPhotoIfNotAvailable
                })}
            >

                <FiInfo
                    className={cn(`text-gray-600`)}
                    id={`allow-sharing-tooltip`}
                />

                <Tooltip
                    anchorSelect={`#allow-sharing-tooltip`}
                    place="top"
                    style={{
                        width: 340,
                        textAlign: "left"
                    }}
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, atque.
                </Tooltip>

            </TextToggle>

            <InlineCTA />

            <div className="w-full ">

                <Text
                    variant="label"
                    text={"Column count"}
                />

                <Select
                    isMulti={false}
                    className="w-full text-sm mt-0 z-40"
                    defaultValue={content.columnCount}
                    options={[
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                    ]}
                    onChange={(e: any) => {
                        useWallOfLoveStore.getState().setWolTemplateContent({
                            columnCount: parseInt(e.value)
                        });
                    }}
                />

            </div>



        </div>
    );
} [];