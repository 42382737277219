import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget5_Settings() {

    const settings = useWidgetsStore((store) => store.widget5);
    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-2">

            <Input
                value={settings.width}
                label="Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("width", value, setErrors);
                    useWidgetsStore.getState().setWidget5Settings({ width: value });
                }}
                error={errors?.width}
            />

            <Input
                value={settings.height}
                label="Height"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("height", value, setErrors);
                    useWidgetsStore.getState().setWidget5Settings({ height: value });
                }}
                error={errors?.height}
            />

            <ColorPicker
                defaultColor={settings.borderColor}
                label="Border Color"
                onChange={(e) => useWidgetsStore.getState().setWidget5Settings({ borderColor: e })}
            />

            <Input
                value={settings.borderWidth}
                label="Border Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("borderWidth", value, setErrors);
                    useWidgetsStore.getState().setWidget5Settings({ borderWidth: value });
                }}
                error={errors?.borderWidth}
            />

            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget5Settings({ backgroundColor: e })}
            />

            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget5Settings({ customCSS: e.target.value })}
            />

        </div>
    );
}