import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget3_Settings() {

    const settings = useWidgetsStore((store) => store.widget3);
    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-2">

            <ColorPicker
                defaultColor={settings.borderColor}
                label="Border Color"
                onChange={(e) => useWidgetsStore.getState().setWidget3Settings({ borderColor: e })}

            />

            <Input
                value={settings.borderWidth}
                label="Border Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("borderWidth", value, setErrors);
                    useWidgetsStore.getState().setWidget3Settings({ borderWidth: value });
                }}
                error={errors?.borderWidth}
            />

            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget3Settings({ backgroundColor: e })}
            />

            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget3Settings({ customCSS: e.target.value })}
            />

            <ColorPicker
                defaultColor={settings.textColor}
                label="Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget3Settings({ textColor: e })}
            />

            <Input
                value={settings.fontSize}
                label="Font Size"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("fontSize", value, setErrors);
                    useWidgetsStore.getState().setWidget3Settings({ fontSize: value });
                }}
                error={errors?.fontSize}
            />

        </div>
    );
}