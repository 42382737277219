import { Rating } from "@components/rating";
import { useState } from "react";
import { Content } from "./content";
import { ImageAttachments } from "./imageAttachments";
import { useSearchParams } from "react-router-dom";
import { QueryKeys, useGetQueryData } from "@hooks";

export function TextTestimonial() {

    const [searchParams] = useSearchParams();

    const testimonialId = searchParams.get("id");

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [edit, setEdit] = useState(false);

    const item = testimonialsReq.testimonials.filter(item => item.id === testimonialId)[0];

    const rated = item && item.rating && item.rating !== null;
    const rating = item && item.rating || 0;
    const text = item && item.textTestimonialContent;

    return (
        <div className="space-y-4">

            <div className="flex items-center justify-between">

                {rated && rating !== 0 ?
                    <Rating
                        rated={rating}
                        readonly={true}
                    /> : null
                }

                {/* <SecondaryButton
                    className="space-x-2 w-auto text-sm font-medium px-2"
                    onClick={() => setEdit(!edit)}
                >

                    <FiEdit3 className="w-4 h-4" />

                </SecondaryButton> */}

            </div>

            <Content
                edit={edit}
                text={item?.textTestimonialContent ?? ""}
                onSaved={() => setEdit(false)}
            />

            {item && item.imageAttachments ? <ImageAttachments /> : null}

        </div>
    );
}