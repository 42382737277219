import { useParams } from "react-router-dom";
import { ImageTemplatePreview } from "./image/preview/main.preview";
import { VideoTemplatePreview } from "./video/preview/main.preview";

export function TemplatePreview() {

    const { type } = useParams();

    return (
        <div className="w-[70%] h-full">

            {type === "image" ? <ImageTemplatePreview /> : null}

            {/* {type === "wall-of-love" ? <WallOfLoveTemplatePreview /> : null} */}

            {type === "video" ? <VideoTemplatePreview /> : null}

            {/* {type === "widget" ? <WidgetTemplatePreview /> : null} */}

        </div>
    );
}