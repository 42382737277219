import { WidgetTemplatePreview } from "./preview/main.preview";
import { WidgetSettings } from "./settings/main.settings";


export function EditWidgetPage() {
    return (
        <div className="w-full h-full max-h-full max-w-full overflow-y-hidden flex items-center">

            <WidgetSettings />

            <WidgetTemplatePreview />

        </div>
    );
}