import { SecondaryButton } from "@components/button";
import { DatePicker } from "@components/datePicker";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { useSelectTestimonialsStore, useTestimonialStore } from "@store";
import { useClickAway } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { useState } from "react";
import { MdKeyboardArrowDown, MdOutlineDateRange } from "react-icons/md";


type Props = {
    refresh: () => void;
};

export function DateRangeFilter({ refresh }: Props) {

    const range = useSelectTestimonialsStore((store) => store.filter.range);

    const clickOutSideRef = useClickAway<any>(() => setShow(!show));

    const [show, setShow] = useState(false);

    return (
        <Popover open={show}>

            <PopoverTrigger>

                <SecondaryButton
                    className={cn(`py-2 px-3 w-full hover:bg-gray-200/80 bg-white hover:bg-white`)}
                    onClick={() => setShow(!show)}
                >

                    <div className="space-x-2 flex items-center justify-center mr-2">

                        <MdOutlineDateRange className="w-5 h-5" />

                        <p className="text-sm font-medium"> All time </p>

                    </div>

                    <MdKeyboardArrowDown className="w-6 h-6" />

                </SecondaryButton>

            </PopoverTrigger>

            <PopoverContent
                ref={clickOutSideRef}
                side="bottom"
                align="start"
                className="w-full h-full relative p-2 m-0 bg-white mt-2"
            >

                <DatePicker
                    mode="range"
                    numberOfMonths={2}
                    selected={{
                        from: ((range.from as any) ?? new Date().toISOString()),
                        to: ((range.to as any) ?? new Date().toISOString())
                    }}
                    onSelect={(e) => {
                        console.log({ e });
                        useTestimonialStore.getState().setFilter({
                            range: {
                                from: e?.from as any,
                                to: e?.to as any
                            }
                        });
                        setShow(false);
                        refresh();
                    }}
                />

            </PopoverContent>

        </Popover>
    );
}