import { DateTime } from "luxon";
import { TextTestimonial } from "./text";
import { VideoTestimonial } from "./video";
import { AudioTestimonial } from "./audio";

type Props = {
    response: Api.Testimonials.getAll["testimonials"][0];
};

export function TestimonialSection({ response }: Props) {

    const type = response.type;

    const submittedAt = response.submittedAt;
    const dateTime = DateTime.fromISO(submittedAt);

    const relativeTime = dateTime.toRelative();

    return (
        <div className="w-[70%]">

            {type === "text" && response.textTestimonialContent ?
                <TextTestimonial
                    content={response.textTestimonialContent}
                    rating={response.rating}
                    relativeTime={relativeTime as string}
                /> : null
            }

            {type === "video" && response.videoTestimonialThumbnail ?
                <VideoTestimonial
                    rating={response.rating}
                    thumbnailKey={response.videoTestimonialThumbnail}
                    relativeTime={relativeTime as string}
                /> : null
            }

            {type === "audio" && response.url ?
                <AudioTestimonial
                    rating={response.rating}
                    relativeTime={relativeTime as string}
                    submittedAt={submittedAt}
                /> : null
            }

        </div>
    );

}