import { Button } from "@components/button";
import { EmptyIcon } from "@components/icons";
import { QueryKeys, useWidgetsApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { Fragment, } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WidgetTemplates } from "./list";
import { useWidgetsStore } from "@store";


export function SavedWidgets() {

    const [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { deleteQuery } = useWidgetsApiClient();

    const query = queryClient.getQueryData<Api.Widget.getAll>([QueryKeys.widget.getAll]);

    const onDelete = async (id: string) => {
        try {

            await deleteQuery.mutateAsync(id);

            if (!query) return;

            const updatedWidgets = query?.widgets.filter(item => item.widgetId !== id);

            const updated: Api.Widget.getAll = { widgets: [...updatedWidgets] };

            queryClient.setQueryData([QueryKeys.widget.getAll], updated);

        } catch (err) {
            console.log(err);
        }
    };

    const onHandleDelete = (name: string, id: string) => {
        toast.promise(onDelete(id), {
            error: "Something went wrong, Please contact support.",
            loading: `Delete ${name} widget.`,
            success: `Deleted ${name} widget`
        });
    };

    const onLoad = (item: Api.Widget.getAll["widgets"][0]) => {

        if (item.templateId === "w1") useWidgetsStore.getState().setWidget1Settings(item.settings);
        else if (item.templateId === "w2") useWidgetsStore.getState().setWidget2Settings(item.settings);
        else if (item.templateId === "w3") useWidgetsStore.getState().setWidget3Settings(item.settings);
        else if (item.templateId === "w4") useWidgetsStore.getState().setWidget4Settings(item.settings);
        else if (item.templateId === "w5") useWidgetsStore.getState().setWidget5Settings(item.settings);
        else if (item.templateId === "w7") useWidgetsStore.getState().setWidget7Settings(item.settings);
        else if (item.templateId === "w8") useWidgetsStore.getState().setWidget8Settings(item.settings);
        else throw new Error();

        navigate(`edit/${item.widgetId}?templateId=${item.templateId}`);

    };

    return (
        <div
            className={cn(`
                w-full h-[80%] max-h-[80%] overflow-y-scroll py-6 px-0 
                flex gap-6 flex-wrap
            `)}
        >

            {query && query.widgets && query.widgets.length ?
                <Fragment>

                    {query.widgets?.map((item, idx) => {

                        // const previewImage = item.previewImage ?
                        //     item.previewImage :
                        //     WidgetTemplates.filter(wig => wig.templateId === item.templateId)[0].previewImage;

                        return (
                            <div
                                key={idx}
                                className={cn(`
                                    border border-gray-300 bg-white rounded-lg 
                                    w-[220px] h-[210px] relative group hover:scale-[0.98]
                                    cursor-pointer group shadow-lg overflow-hidden
                                    transition-all duration-300 ease-in-out
                                `)}
                                onClick={() => onLoad(item)}
                            >

                                <div className="border-b w-full h-[80%]">

                                    {/* <img
                                        src={previewImage}
                                        className={cn(`
                                            group-hover:scale-[1.0] scale-[0.9] w-full rounded-lg
                                            transition-all duration-300 ease-in-out
                                        `)}
                                        alt="Template Preview Image"
                                    /> */}

                                    <iframe
                                        src={item.src}
                                        className={cn(`
                                            scale-[0.3] w-auto rounded-lg
                                            transition-all duration-300 ease-in-out
                                        `)}
                                    />

                                </div>


                                <div
                                    className="px-4 h-[20%] flex items-center justify-start"
                                    onClick={(e) => e.stopPropagation()}
                                >

                                    <p className="text-sm font-medium">
                                        {item.name}
                                    </p>

                                </div>

                                {deleteQuery.isPending === false ?
                                    <div className="invisible group-hover:visible z-40 absolute bottom-1 right-2">

                                        <div
                                            className={cn(`
                                                p-2 bg-gray-200 rounded-md hover:bg-red-200 text-red-600
                                                transition-all duration-300 ease-in-out    
                                            `)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHandleDelete(item.name, item.widgetId);
                                            }}
                                        >

                                            <MdDelete className="w-4 h-4" />

                                        </div>


                                    </div> : null
                                }

                            </div>
                        );
                    })}

                </Fragment> : null
            }

            {query && query.widgets && query.widgets.length === 0 ?
                <div className="w-full h-full flex flex-col items-center justify-center space-y-4">

                    <EmptyIcon />

                    <p>
                        You don't have any widgets. Let's create one now
                    </p>

                    <Button
                        className="w-auto tracking-wider"
                        onClick={() => {
                            searchParams.set("tab", "widgets");
                            setSearchParams(searchParams);
                        }}
                    >
                        Create Widget
                    </Button>

                </div> : null
            }

        </div>
    );
}