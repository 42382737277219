import { Config } from "@config";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { AiOutlineTeam } from "react-icons/ai";
import { BsBookmarkHeart } from "react-icons/bs";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { FaRegHeart } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdArrowBackIosNew, MdHelp, MdOutlineAssignment, MdOutlineCampaign, MdOutlineDashboard, MdOutlineFileDownload } from "react-icons/md";
import { TbApps, TbPlugConnected } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { NavItem } from "./item";
import { CurrentSpace } from "./spaces";


type Props = {
    isLoading?: boolean;
};

export function NavBar({ }: Props) {

    const { showFullNavbar, setShowFullNavBar } = useCommonStore();

    const { pathname } = useLocation();

    const activeTab = pathname.split("/")[1] ?? "";

    const navigate = useNavigate();

    return (
        <nav className={cn(`
            relative h-full w-auto flex flex-col justify-between border-r bg-white shadow-md  
            transition-all duration-500 ease-in-out  
        `, {
            "w-[4.5%] xl:w-[3%]": !showFullNavbar,
            "w-[18%] xl:w-[15%]": showFullNavbar
        })}>

            <div className="space-y-2 p-2">

                <CurrentSpace />

                <div className="space-y-1">

                    <NavItem
                        text="Overview"
                        isActive={activeTab === ""}
                        icon={<MdOutlineDashboard className="w-5 h-5" />}
                        onClick={() => navigate("/")}
                    />

                    <NavItem
                        text="Form"
                        isActive={activeTab === "form"}
                        icon={<MdOutlineAssignment className="w-5 h-5" />}
                        onClick={() => navigate("/form")}
                    />

                    <NavItem
                        text="Testimonials"
                        isActive={activeTab === "testimonials"}
                        icon={<BsBookmarkHeart className="w-5 h-5" />}
                        onClick={() => navigate("/testimonials")}
                    />

                    <NavItem
                        text="Wall Of Love"
                        isActive={activeTab === "wall-of-love"}
                        icon={<FaRegHeart className="w-5 h-5" />}
                        onClick={() => navigate("/wall-of-love")}
                    />


                    <NavItem
                        text="Social Proof"
                        isActive={activeTab === "social-proof"}
                        icon={<IoShareSocialOutline className="w-5 h-5" />}
                        onClick={() => navigate("/social-proof")}
                    />

                    <NavItem
                        text="Import Testimonials"
                        isActive={activeTab === "import-testimonials"}
                        icon={<MdOutlineFileDownload className="w-5 h-5" />}
                        onClick={() => navigate("/import-testimonials")}
                    />

                    <NavItem
                        text="Teams"
                        isActive={activeTab === "teams"}
                        icon={<AiOutlineTeam className="w-5 h-5" />}
                        onClick={() => navigate("/teams")}
                    />

                    <NavItem
                        text="Email Invites"
                        isActive={activeTab === "invites"}
                        icon={<MdOutlineCampaign className="w-5 h-5" />}
                        onClick={() => navigate("/invites")}
                    />

                    <NavItem
                        text="Integrations"
                        isActive={activeTab === "integrations"}
                        icon={<TbPlugConnected className="w-5 h-5" />}
                        onClick={() => navigate("/integrations")}
                    />

                    <NavItem
                        text="Widgets"
                        isActive={activeTab === "widgets"}
                        icon={<TbApps className="w-5 h-5" />}
                        onClick={() => navigate("/widgets")}
                    />

                </div>

            </div>

            <div className="border-t p-2">

                <NavItem
                    text="Help"
                    isActive={false}
                    icon={<MdHelp className="w-5 h-5" />}
                    onClick={() => window.open(Config.DocsLink, "blank")}
                />

            </div>

            <div
                className={cn(`
                    rounded-full absolute right-0 translate-x-1/2 bottom-16 origin-center bg-[#2563EB] 
                    delay-50 duration-500 cursor-pointer flex items-center justify-center p-1.5
                `, {
                    "rotate-0": showFullNavbar,
                    "rotate-180": !showFullNavbar
                })}
                style={{
                    zIndex: 100
                }}
                onClick={() => setShowFullNavBar(!showFullNavbar)}
            >
                <MdArrowBackIosNew className="text-white w-4 h-4" />
            </div>

        </nav>
    );
}
