import { ColorPicker } from "@components/colorPicker";
import { useSocialProofStore } from "@store";


export function ImageTemplateBackground() {

    const { color, } = useSocialProofStore((store) => store.imageTemplate.background);

    return (
        <div className="space-y-4 p-2">

            <ColorPicker
                defaultColor={color ?? "#fff"}
                label="Background Color"
                onChange={(val) => useSocialProofStore.getState().setImageTemplateBackground({
                    color: val
                })}
            />

            {/* <TextToggle
                isActive={transparent}
                name="Transparent Background"
                onClick={() => useSocialProofStore.getState().setImageTemplateBackground({ transparent: !transparent })}
            /> */}



        </div>
    );
}