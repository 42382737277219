import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { useActiveSpace, useWallOfLoveApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

export function CreateWallOfLoveTemplate() {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const onClose = () => useCommonStore.getState().setActivePopup("");

    const [name, setName] = useState("");

    const { create } = useWallOfLoveApiClient();

    const { formId } = useActiveSpace();

    const onCreate = async () => {
        try {

            const templateId = searchParams.get("templateId") ?? "";

            if (!templateId) throw new Error("Template ID is required");

            const data = await create.mutateAsync({ name, templateId, formId });

            toast.success("Wall of Love template created successfully");

            onClose();

            navigate(`edit/${data.id}`);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Popup
            width="30%"
            name="Create Wall Of Love Template"
            onClose={onClose}
        >

            <p className="text-sm font-normal text-gray-400">
                Name this widget so you can find it later in Saved
            </p>

            <div className="mt-3">

                <Input
                    type="text"
                    value={name}
                    placeholder="Widget widget"
                    onChange={(e) => setName(e.target.value)}
                />

                <div className="flex items-center justify-end space-x-4 mt-4">

                    <SecondaryButton
                        disabled={create.isPending}
                        className="w-auto text-sm"
                        onClick={() => onClose()}
                    >
                        Close
                    </SecondaryButton>

                    <Button
                        disabled={create.isPending}
                        loading={create.isPending}
                        className="w-auto text-sm"
                        onClick={() => onCreate()}
                    >
                        Create
                    </Button>

                </div>

            </div>

        </Popup>
    );

}