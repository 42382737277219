import { useMutation } from '@tanstack/react-query';
import { QueryKeys } from './keys';
import { ParseAxiosError } from '@lib/axios';
import axios from 'axios';

export function useMigrateApiClient() {

    const senjaImporter = useMutation({
        mutationKey: [QueryKeys.migrate.senja],
        mutationFn: async ({ formId, url }: Api.Migrate.params) => {
            try {

                return (await axios.get("/api/v1/migrate/senja", {
                    params: {
                        url,
                        formId,
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const testimonialToImporter = useMutation({
        mutationKey: [QueryKeys.migrate.testimonial],
        mutationFn: async ({ formId, url }: Api.Migrate.params) => {
            try {

                return (await axios.get("/api/v1/migrate/testimonial-to", {
                    params: {
                        url,
                        formId,
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        senjaImporter,
        testimonialToImporter
    };

}