import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";
import axios from "axios";

export function useSocialProofApiClient() {

    const saveTemplateQuery = useMutation({
        mutationKey: [QueryKeys.socialProof.saveImageTemplate],
        mutationFn: async (params: Api.SocialProof.saveImageTemplateParams) => {
            try {

                return await axios.post<Api.SocialProof.saveImageTemplate>("/api/v1/social-proof/image", params);

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getAll = useQuery({
        enabled: false,
        queryKey: [QueryKeys.socialProof.getTemplates],
        queryFn: async () => {
            try {

                return (await axios.get<Api.SocialProof.getAll>("/api/v1/social-proof/image")).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.socialProof.deleteTemplate],
        mutationFn: async (id: string) => {
            try {

                return await axios.delete("/api/v1/social-proof/image/", { params: { id } });

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        saveImageTemplate: saveTemplateQuery,
        getAll,
        deleteQuery
    };

}