import { PoweredBy } from "@components/branding";
import { Rating } from "@components/rating";
import { useSocialProofStore } from "@store";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { PurifyHTML } from "@utils/purifyHTML";
import TemplateBg from "./t5.png";


export function Template5() {

    const {
        name, role, company, rating, text, profileUrl,
        showProfile, showRating, showBranding
    } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text,
        profileUrl: store.imageTemplate.content.profileUrl,
        showProfile: store.imageTemplate.settings.showProfile,
        showRating: store.imageTemplate.settings.showRating,
        showBranding: !store.imageTemplate.branding.remove
    }));

    return (
        <div
            id="preview-template"
            className="border border-gray-600 rounded-lg bg-gray-300 relative h-[1080px] w-[1080px] scale-[0.4] shadow-lg"
        >

            <img
                src={TemplateBg}
                className="w-full h-full rounded-md"
            />

            <div className="absolute top-[70px] left-[70px] w-[84%] h-auto p-4">

                <div className="h-[90%] py-2 flex flex-col items-start justify-center">

                    <div className="flex items-center justify-start space-x-6 w-full h-auto">

                        {showProfile && (profileUrl.length || name.length) ?
                            <img
                                src={profileUrl ? profileUrl : GenerateProfilePic(name[0])}
                                className="w-[8rem] h-[8rem] rounded-full border border-gray-400 shadow-md"
                            /> : null
                        }

                        <div className="space-y-4">

                            <p className="text-4xl font-semibold tracking-wider text-black">
                                {name}
                            </p>

                            {role && company ?
                                <p className="text-3xl font-normal text-gray-100">

                                    {role && company ? <> {role} of {company} </> : null}

                                    {role ? role : null}

                                    {company ? company : null}

                                </p> : null
                            }

                        </div>

                    </div>

                    {showRating && rating ?
                        <Rating
                            rated={rating}
                            className="mt-6 w-[60px] h-[60px] mx-2"
                        /> : null
                    }

                    < p
                        className="text-black text-4xl leading-normal font-medium tracking-wide break-words mt-2 p-4"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                    />

                </div>

            </div>

            {showBranding ?
                <div className="absolute bottom-4 right-4">

                    <PoweredBy />

                </div> : null
            }


        </div >
    );

}
