import wol3 from "@assets/socialProofPreview/wol/w3.png";
import { Config } from "@config";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { useSearchParams } from "react-router-dom";
import { CreateWallOfLoveTemplate } from "./create.popup";

const data: WidgetType[] = [
    {
        type: "wall-of-love",
        templateId: "wol3",
        name: "wol3",
        previewImage: wol3,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "wol3",
    },
];

export function WallOfLoveTemplates() {

    const [searchParams, setSearchParams] = useSearchParams();

    const activePopup = useCommonStore((store) => store.activePopup);

    return (
        <div className="my-4 w-full overflow-hidden">

            <div className="flex items-center gap-6 flex-wrap w-full">

                {data.map((item) => (
                    <div
                        key={item.templateId}
                        className={cn(`
                            border border-gray-300 bg-gray-100 rounded-md 
                            w-[260px] h-[240px] relative
                            flex items-center justify-center
                            cursor-pointer group shadow-lg overflow-hidden
                        `)}
                        onClick={() => {
                            searchParams.set("templateId", item.templateId);
                            setSearchParams(searchParams);
                            useCommonStore.getState().setActivePopup("create-wall-of-love-template");
                        }}
                    >

                        <img
                            src={item.previewImage as string}
                            className={cn(`
                                group-hover:scale-[1.03] w-full rounded-lg
                                transition-all duration-300 ease-in-out 
                            `)}
                        />

                    </div>
                ))}

            </div>

            {activePopup === "create-wall-of-love-template" ? <CreateWallOfLoveTemplate /> : null}

        </div>
    );
}