import { Button } from "@components/button";
import { Popup } from "@components/popup";
import { useCommonStore } from "@store";
import { useNavigate } from "react-router-dom";


export function UpgradePopup() {

    const navigate = useNavigate();

    const onClose = () => {
        useCommonStore.getState().setActivePopup("");
    };

    const onUpgrade = () => {

        navigate("/settings?tab=billings&b_tab=summary");

        onClose();

    };

    return (
        <Popup
            width="30%"
            name="Upgrade Your Plan"
            onClose={onClose}
        >

            <p className="mt-4 text-sm font-normal tracking-wide">
                Please upgrade to higher plan or contact support for help,
                You have reached your plan limit.
            </p>

            <div className="flex items-center justify-end mt-5">

                <button
                    className={"bg-gray-200 h-full py-2.5 px-3 mx-4 text-sm font-normal rounded-md hover:bg-gray-300"}
                    onClick={() => onClose()}
                >
                    cancel
                </button>

                <Button
                    text={"Upgrade"}
                    className="w-auto text-sm font-medium"
                    onClick={() => onUpgrade()}
                />

            </div>


        </Popup>
    );
}