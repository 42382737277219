import { QueryKeys } from "@hooks";
import { useMutationState } from "@tanstack/react-query";
import DeepBot from "deepbot-react";


export const Support: React.FC = () => {

    const query = useMutationState({
        filters: {
            mutationKey: [QueryKeys.user.getProfile],
            status: "success"
        },
        select: (mutation) => (mutation.state.data as any)
    }) ?? null;

    const user = query[0] as Api.UserApi.getUser ?? null;

    const userData = {
        "userId": user && user.id ? user.id : "",
        "email": user && user.email ? user.email : "",
        "name": user && user.name ? user.name : "",
        "formsPlan": user && user.billing.plan ? user.billing.plan : "",
        "from": "Testimonials"
    };

    if (!import.meta.env.PROD) return null;

    return (
        <DeepBot
            chatbotId="66f107058a60e75f11f93f9a"
            {...userData}
        />
    );

};