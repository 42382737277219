import { PoweredBy } from "@components/branding";
import { Rating } from "@components/rating";
import { useSocialProofStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { Fragment } from "react";
import Avatar from "react-avatar";
import Background from "./t2.png";

export function Template2() {

    const {
        name, role, company, rating, text, profileUrl,
        showProfile, showRating, showBranding
    } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text,
        profileUrl: store.imageTemplate.content.profileUrl,
        showProfile: store.imageTemplate.settings.showProfile,
        showRating: store.imageTemplate.settings.showRating,
        showBranding: !store.imageTemplate.branding.remove
    }));

    return (
        <div
            id="preview-template"
            className="rounded-lg bg-gray-300 relative h-[1080px] w-[1080px] scale-[0.4]"
        >

            <img
                src={Background}
                className="w-full h-full rounded-md blur-sm"
            />

            <div className="absolute top-0 flex flex-col items-start p-10 space-y-4">

                <div className="flex items-center justify-start space-x-6 w-full h-full">

                    {showProfile && (profileUrl.length || name.length) ?
                        <Fragment>

                            {profileUrl.length ?
                                <img
                                    src={profileUrl}
                                    crossOrigin="anonymous"
                                    className="w-[6rem] h-[6rem] rounded-full"
                                /> : null
                            }

                            {!profileUrl && name.length ?
                                <Avatar
                                    name={name}
                                    size="90px"
                                    round
                                    textSizeRatio={1}
                                /> : null
                            }

                        </Fragment> : null
                    }

                    <div className="space-y-3">

                        <p className="text-5xl font-medium text-white">
                            {name}
                        </p>

                        {role && company ?
                            <p className="text-3xl font-normal text-gray-100">

                                {role && company ? <> {role} of {company} </> : null}

                                {role ? role : null}

                                {company ? company : null}

                            </p> : null
                        }

                    </div>

                </div>

                {showRating && rating ?
                    <Rating
                        rated={rating}
                        className="mt-4 w-[70px] h-[70px] mx-2"
                        iconProps={{
                            stroke: "black",
                            strokeWidth: 8,
                            className: "mt-4 w-[60px] h-[60px] mx-2"
                        }}
                    /> : null
                }

                <p
                    className="text-white text-[40px] font-medium tracking-wide break-words mt-3 p-6"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                />

            </div>

            {showBranding ?
                <div className="absolute bottom-8 right-8">

                    <PoweredBy />

                </div> : null
            }

        </div>
    );
}