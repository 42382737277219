import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";

type Props = {
    placeholder?: string,
    label?: string,
};

export function EmailField({ placeholder, label }: Props) {

    const { email, error } = useImportTestimonialStore((store) => ({
        email: store.testimonial.email,
        error: store.error
    }));

    return (
        <Input
            type="email"
            placeholder={placeholder ?? "richy@pp.com"}
            label={label ?? "Email"}
            required={false}
            mainClassName="w-full"
            value={email}
            error={error["email"]}
            onChange={(e) => useImportTestimonialStore.getState().setEmail(e.target.value)}
        />
    );
}