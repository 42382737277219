import { Button } from "@components/button";
import { Text } from "@components/text";
import { MdOutlineFileDownload } from "react-icons/md";
import toast from "react-hot-toast";
import { ParseAxiosError } from "@lib/axios";
import { useActiveSpace, useTestimonialsApiClient } from "@hooks";

export function TestimonialsHeader() {

    const { formId } = useActiveSpace();

    const { exportQuery } = useTestimonialsApiClient({
        exportParams: {
            apiParams: {
                id: formId
            },
            params: {
                onError: (err: any) => { toast.error(ParseAxiosError(err).message); },
                onSuccess: (data) => {

                    const file = new Blob([data.data], { type: "text/csv" });

                    const a = document.createElement("a");

                    a.href = URL.createObjectURL(file);

                    a.download = "testimonials.csv";

                    a.click();

                    toast.success("Testimonials exported successfully.");

                }
            }
        }
    });

    const isPending = exportQuery.isLoading || exportQuery.isFetching;

    return (
        <div className="flex items-center justify-between px-2 pt-2">

            <Text
                variant="primary"
                className="text-xl font-semibold tracking-wide"
            >
                Your Testimonials
            </Text>

            <Button
                disabled={isPending}
                loading={isPending}
                className="w-auto font-medium text-sm mt-0 px-4 tracking-wide"
                onClick={() => exportQuery.refetch()}
            >

                <MdOutlineFileDownload className="w-5 h-5" />

                <p className="mx-2">
                    Export
                </p>

            </Button>

        </div>
    );
}