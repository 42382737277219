import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";

const State: Invites.State = {
    selectedFile: null,
    activeUploadTab: "upload",
    activeTab: "upload",
    selectedColumns: {},
    selectedRowsToDelete: [],
    parsedFile: {
        columns: [],
        rows: []
    },
    template: {
        design: {
            logo: "https://asserts.dev.testimonial.gozen.io/misc/logo.png",
            buttonColor: "#2563EB",
            fontFamily: "Roboto",
            buttonText: "Leave a Testimonial",
            removeBranding: false
        },
        advance: {
            fromName: "",
            replyEmail: "",
        },
        message: {
            content: "",
            subject: "",
        },
        templateId: "",
        version: 0
    }
};

export const useInvitesStore = create<Invites.Type>()(devtools(immer(set => ({
    ...State,
    setActiveTab: (tab) => set((state) => { state.activeTab = tab; },
        false,
        "invites/setActiveTab"
    ),
    setActiveUploadTab: (tab) => set((state) => { state.activeUploadTab = tab; },
        false,
        "invites/setActiveUploadTab"
    ),
    setSelectedFile: (file) => set((state) => { state.selectedFile = file; },
        false,
        "invites/setSelectedFile"
    ),
    setSelectedColumn: (col, val) => set((state) => { state.selectedColumns[col] = val; },
        false,
        "invites/setAction"
    ),
    setParsedFile: (file) => set((state) => {

        state.parsedFile.rows = file.rows;
        state.parsedFile.columns = file.columns;

    },
        false,
        "invites/setParsedFile"
    ),
    setSelectedRowsToDelete: (ids: string[]) => set((state) => { state.selectedRowsToDelete = ids; },
        false,
        "invites/setSelectedRows"
    ),
    /* template */
    setTemplate: (template) => set((store) => {

        store.template = template;

    },
        false,
        "invites/setTemplate"
    ),
    setAdvance: (advance) => set((state) => {

        state.template.advance = { ...state.template.advance, ...advance };

    },
        false,
        "invites/setAdvance"
    ),
    setDesign: (design) => set((state) => {

        state.template.design = { ...state.template.design, ...design };

    },
        false,
        "invites/setDesign"
    ),
    setMessage: (message) => set((store) => {

        store.template.message.content = message.content ?? store.template.message.content;
        store.template.message.subject = message.subject ?? store.template.message.subject;

    },
        false,
        "invites/setMessages"
    ),
    clear: () => set((state) => {

        state.activeTab = "upload";
        state.parsedFile = { columns: [], rows: [] };
        state.selectedColumns = {};
        state.selectedFile = null;

    },
        false,
        "invites/clear"
    )
})), {
    name: StoreKeys.invites,
    enabled: import.meta.env.DEV
}));