import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget4_Settings() {

    const settings = useWidgetsStore((store) => store.widget4);
    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-2">

            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget4Settings({ backgroundColor: e })}
            />

            <ColorPicker
                defaultColor={settings.quotationBgColor}
                label="Quotation Bg Color"
                onChange={(e) => useWidgetsStore.getState().setWidget4Settings({ quotationBgColor: e })}
            />

            <ColorPicker
                defaultColor={settings.quotationInnerColor}
                label="Quotation Inner Color"
                onChange={(e) => useWidgetsStore.getState().setWidget4Settings({ quotationInnerColor: e })}
            />

            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget4Settings({ customCSS: e.target.value })}
            />

            <ColorPicker
                defaultColor={settings.textColor}
                label="Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget4Settings({ textColor: e })}
            />

            <Input
                value={settings.fontSize}
                label="Font Size"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("fontSize", value, setErrors);
                    useWidgetsStore.getState().setWidget4Settings({ fontSize: value });
                }}
                error={errors?.fontSize}
            />

        </div>
    );
}