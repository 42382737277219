import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";
import axios from "axios";


export function useWidgetsApiClient() {

    const save = useMutation({
        mutationKey: [QueryKeys.widget.save],
        mutationFn: async (params: Api.Widget.SaveParams) => {
            try {

                return (await axios.post<{ id: string, url: string; }>("/api/v1/widgets", params)).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.widget.delete],
        mutationFn: async (id: string) => {
            try {

                return (await axios.delete(`/api/v1/widgets`, { data: { id } })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getAll = useQuery({
        enabled: false,
        retry: 1,
        queryKey: [QueryKeys.widget.getAll],
        queryFn: async () => {
            try {

                return (await axios.get<Api.Widget.getAll>("/api/v1/widgets")).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        save,
        deleteQuery,
        getAll,
    };

}