import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { Text } from "@components/text";
import { UploadPicture } from "@components/upload";
import { useInvitesStore } from "@store";
import DummyLogo from "@assets/image-placeholder.png";
import { useActiveSpace, useUsersApiClient } from "@hooks";
import { TextToggle } from "@components/toggle";

export function Design() {

    const { formId } = useActiveSpace();

    const { btnColor, btnText, logo, removeBranding } = useInvitesStore((store) => ({
        btnColor: store.template.design.buttonColor,
        logo: store.template.design.logo,
        btnText: store.template.design.buttonText,
        removeBranding: store.template.design.removeBranding
    }));

    const { uploadAsserts } = useUsersApiClient({});

    const onChangeLogo = async (file: File) => {
        try {

            const id = `email_invite_logo_${formId}`;

            const data = await uploadAsserts.mutateAsync({ file, id, type: "formLogo" });

            useInvitesStore.getState().setDesign({ logo: data.url });

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="space-y-2 ">

            <Input
                type="text"
                label="Button Text"
                value={btnText}
                onChange={(e) => useInvitesStore.getState().setDesign({ buttonText: e.target.value })}
            />

            <ColorPicker
                type="chrome"
                label="Button Color"
                className="top-[-170px] left-20"
                defaultColor={btnColor}
                onChange={(val) => useInvitesStore.getState().setDesign({ buttonColor: val })}
            />

            <div>

                <Text variant="label">
                    Logo
                </Text>

                <UploadPicture
                    id="email-invite-logo"
                    file={null}
                    defaultImage={logo ?? DummyLogo}
                    containerClassName="space-y-1"
                    imgClassName="w-[80px] h-[80px]"
                    onChange={(file) => onChangeLogo(file)}
                />

            </div>

            <TextToggle
                isActive={removeBranding}
                name="Remove Branding"
                onClick={() => useInvitesStore.getState().setDesign({ removeBranding: !removeBranding })}
            />

        </div>
    );
}