import { Rating } from "@components/rating";
import TemplateBg from "./t4.png";
import { useSocialProofStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { PoweredBy } from "@components/branding";


export function Template4() {

    const {
        name, role, company, rating, text,
        showBranding, showRating
    } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text,
        showRating: store.imageTemplate.settings.showRating,
        showBranding: !store.imageTemplate.branding.remove
    }));

    return (
        <div
            id="preview-template"
            className="border border-gray-600 rounded-lg bg-gray-300 relative h-[1080px] w-[1080px] scale-[0.4]"
        >

            <img
                src={TemplateBg}
                className="w-full h-full rounded-md"
            />

            <div className="absolute top-[0px] left-[70px] h-full p-4 flex items-center w-[85%]">

                <div className="h-[90%] py-2 flex flex-col items-center justify-between w-full">

                    {showRating && rating ?
                        <Rating
                            rated={rating}
                            className="mt-4 w-[70px] h-[70px] mx-2"
                            iconProps={{
                                stroke: "black",
                                strokeWidth: 8,
                                className: "mt-4 w-[60px] h-[60px] mx-2"
                            }}
                        /> : null
                    }

                    <p
                        className="text-black text-4xl leading-normal font-medium tracking-wide break-words mt-2 p-4 text-center"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                    />

                    <div className="space-x-6 w-full h-full mt-4">

                        <p className="text-4xl font-semibold tracking-wider text-black">
                            {name}
                        </p>

                        {role && company ?
                            <p className="text-3xl font-normal text-gray-100">

                                {role && company ? <> {role} of {company} </> : null}

                                {role ? role : null}

                                {company ? company : null}

                            </p> : null
                        }

                    </div>

                </div>

            </div>


            {showBranding ?
                <div className="absolute bottom-4 right-4">

                    <PoweredBy />

                </div> : null
            }


        </div>
    );

}
