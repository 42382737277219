import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useImportTestimonialStore } from "@store";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    content: z.string().min(1, "Testimonial cannot be empty.")
});

export function SlackImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const navigate = useNavigate();

    const onImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId: activeSpace.formId,
                source: "slack"
            });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

            navigate("/testimonials");

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import from Slack"
            onImport={() => onImport()}
        >

            <ManuallyImport
                fields={{
                    rating: false,
                    email: null,
                    testimonial: {
                        text: { content: true, imageAttachments: true },
                        audio: { url: false, transcribedText: false },
                        video: { url: false, transcribedText: false }
                    },
                    company: false,
                    sourceUrl: null,
                    role: false,
                    companyLogo: false,
                }}
            />

        </ImportCard>
    );
}