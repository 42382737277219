import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useNavigate, useParams, } from "react-router-dom";
import Template1 from "@assets/template/preview/t1.png";
import Template2 from "@assets/template/preview/t2.png";
import Template3 from "@assets/template/preview/t3.png";
import Template4 from "@assets/template/preview/t4.png";
import Template5 from "@assets/template/preview/t5.png";
import Template6 from "@assets/template/preview/t6.png";
import Template7 from "@assets/template/preview/t7.png";
import Template8 from "@assets/template/preview/t8.png";
import Template9 from "@assets/template/preview/t9.png";
import { QueryKeys, useGetQueryData } from "@hooks";
import { useSocialProofStore } from "@store";

const templates = [
    { img: Template1, id: "i1" },
    { img: Template2, id: "i2" },
    { img: Template3, id: "i3" },
    { img: Template4, id: "i4" },
    { img: Template5, id: "i5" },
    { img: Template6, id: "i6" },
    { img: Template7, id: "i7" },
    { img: Template8, id: "i8" },
    { img: Template9, id: "i9" }
];


export function ImageTemplates() {

    const params = useParams();

    const navigate = useNavigate();

    const testimonialsQuery = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const onClick = (id: string) => {

        navigate(`/social-proof/edit/image/${id}`);

        const currTestimonial = testimonialsQuery.testimonials.filter(item => item.id === params.id)[0];

        if (!currTestimonial) return;

        const customer = currTestimonial.customerDetails;
        const type = currTestimonial.type;

        const textTestimonial = currTestimonial.textTestimonialContent ?? "";
        const videoTranscript = currTestimonial.transcribed ? currTestimonial.transcribedText : "";
        const audioTranscript = currTestimonial.transcribed ? currTestimonial.transcribedText : "";

        // useSocialProofStore.getState().setSelectedTestimonial({ ...currTestimonial });

        const text = type === "text" ? textTestimonial :
            type === "audio" ? audioTranscript :
                type === "video" ? videoTranscript : "";

        useSocialProofStore.getState().setImageTemplateContent({
            company: (customer["company"]?.answer as string) ?? "",
            customerName: (customer["name"]?.answer as string) ?? "",
            profileUrl: (customer["photo"]?.answer as string) ?? "",
            rating: (customer["rating"]?.answer as number) ?? 0,
            role: (customer["role"]?.answer as string) ?? "",
            text: text ?? "",
        });

    };

    return (
        <div className="h-full overflow-y-auto">

            <Text
                variant="primary"
                size="md"
                className="font-medium tracking-wide"
                text="Instant images from studio templates"
            />

            <div className="grid grid-cols-3 gap-3 mt-2">

                {templates.map(({ img, id }, index) => (
                    <div className="w-36 rounded-md cursor-pointer border-2 hover:border-primary group">

                        <img
                            key={index}
                            src={img}
                            className={cn(`
                                rounded-md cursor-pointer group-hover:scale-[1.04]
                                transition-all duration-300 ease-in-out 
                            `)}
                            onClick={() => onClick(id)}
                        />
                    </div>
                ))}

            </div>

        </div>
    );
}