import { Button } from "@components/button";
import { EmptyIcon } from "@components/icons";
import { UpgradePopup } from "@components/upgradePopup";
import { useCommonStore, useUserStore } from "@store";
import { MdOutlineFileUpload } from "react-icons/md";


export function NewInvites() {

    const activePopup = useCommonStore(state => state.activePopup);

    const onClick = () => {

        const { used, limit } = useUserStore.getState().billing;

        if (used.emailInvites >= limit.emailInvites) {
            useCommonStore.getState().setActivePopup("upgrade-plan");
            return;
        }

        useCommonStore.getState().setActivePopup("invite-emails");

    };

    return (
        <div className="min-h-full w-full flex flex-col items-center justify-center">

            <EmptyIcon />

            <div className="text-center mt-2 space-y-1">

                <p className="text-lg font-semibold tracking-wide">
                    Email Invites
                </p>

                <p className="text-sm font-medium tracking-wide text-gray-600">
                    Invite your customers to leave you a testimonial and track their progress.
                </p>

            </div>


            <Button
                className="w-auto text-sm font-medium mt-4"
                onClick={() => onClick()}
            >

                <MdOutlineFileUpload className="w-6 h-6" />

                <p className="text-sm font-medium tracking-wide mx-2">
                    Upload File
                </p>

            </Button>

            {activePopup === "upgrade-plan" ? <UpgradePopup /> : null}

        </div>
    );
}