import { AxiosError } from "axios";
import { ApiError } from "src/type";

export function ParseAxiosError(error: ApiError): AxiosError {

    const errorMessage = error.response?.data?.message ?? null;

    if (errorMessage === null) return {
        ...error,
        message: ""
    };

    if (typeof error === "object" && typeof errorMessage === "object") {
        return {
            ...error,
            message: errorMessage[0] as string
        };
    }

    if (typeof errorMessage === "string") {
        return {
            ...error,
            message: errorMessage
        };
    }

    return { ...error };

}