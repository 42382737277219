import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { QueryKeys, useActiveSpace, useWidgetsApiClient } from "@hooks";
import { useCommonStore, useWidgetsStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export function CreateWidgetPopup() {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [searchParams] = useSearchParams();

    const id = searchParams.get("id");

    const activeSpace = useActiveSpace();

    const [name, setName] = useState("");

    const { save } = useWidgetsApiClient();

    const onClose = () => useCommonStore.getState().setActivePopup("");

    const getSettings = () => {

        if (id === "w1") return useWidgetsStore.getState().widget1;
        else if (id === "w2") return useWidgetsStore.getState().widget2;
        else if (id === "w3") return useWidgetsStore.getState().widget3;
        else if (id === "w4") return useWidgetsStore.getState().widget4;
        else if (id === "w5") return useWidgetsStore.getState().widget5;
        else if (id === "w7") return useWidgetsStore.getState().widget7;
        else if (id === "w8") return useWidgetsStore.getState().widget8;
        else return;

    };

    const onCreate = async () => {
        try {

            const settings = getSettings();

            if (!settings) return;

            const isDynamic = id === "w1" || id === "w5" || id === "w7";

            const data = await save.mutateAsync({
                settings,
                create: true,
                name,
                // previewImage: "",
                templateId: id as string,
                type: isDynamic ? "dynamic" : "static",
                widgetId: "",
                formId: activeSpace.formId,
            });

            const getAllQuery = queryClient.getQueryData<Api.Widget.getAll>([QueryKeys.widget.getAll]);


            getAllQuery?.widgets.push({
                widgetId: data.id,
                name,
                // previewImage: "",
                settings,
                src: data.url,
                templateId: id as string
            });

            queryClient.setQueryData([QueryKeys.widget.getAll], getAllQuery);

            navigate(`edit/${data.id}?templateId=${id}`);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Popup
            width="30%"
            name="Create Widget"
            onClose={() => onClose()}
        >

            <p className="text-sm font-normal text-gray-400">
                Name this widget so you can find it later in Saved
            </p>

            <div className="mt-3">

                <Input
                    type="text"
                    value={name}
                    placeholder="Widget widget"
                    onChange={(e) => setName(e.target.value)}
                />

                <div className="flex items-center justify-end space-x-4 mt-4">

                    <SecondaryButton
                        disabled={save.isPending}
                        className="w-auto text-sm"
                        onClick={() => onClose()}
                    >
                        Close
                    </SecondaryButton>

                    <Button
                        disabled={save.isPending}
                        loading={save.isPending}
                        className="w-auto text-sm"
                        onClick={() => onCreate()}
                    >
                        Create
                    </Button>

                </div>

            </div>

        </Popup>
    );
}