import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { Text } from "@components/text";
import {
    QueryKeys, useActiveSpace, useGetQueryData,
    useInvalidateQuery, useTeamsApiClient
} from "@hooks";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";

type MProps = {
    type: "add" | "update",
    memberId?: string,
    spaceId?: string,
    memberEmail?: string,
    oldPermissions?: string[];
};

export function MemberPopup({ type, memberEmail, memberId, spaceId, oldPermissions }: MProps) {

    const activeSpaceId = useActiveSpace().id;

    const getAllMembersQuery = useGetQueryData<Api.Teams.GetAllMembers>([QueryKeys.teams.getAll]);

    const { invalidate } = useInvalidateQuery();
    const { addMember, updatePermission, getAll } = useTeamsApiClient({});

    const [permission, setPermission] = useState<"editor" | "viewer">("editor");
    const [email, setEmail] = useState<string>("");

    const [err, setErr] = useState("");

    const isLoading = type === "add" ? addMember.isPending : updatePermission.isPending;

    useEffect(() => { setEmail(memberEmail ?? ""); }, []);

    const onAdd = async () => {
        try {

            if (!email) {
                setErr("Email can be empty");
                return;
            }

            const isValidEmail = z.string().email().safeParse(email);

            if (isValidEmail.success === false) {
                setErr(isValidEmail.error.message);
                return;
            }

            await addMember.mutateAsync({
                email,
                permissions: permission === "editor" ? ["create", "delete", "read", "update"] : ["read"],
                id: activeSpaceId
            });

            // invalidate([QueryKeys.teams.getAll]);

            getAll.refetch();

            useCommonStore.getState().setActivePopup("");

        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const onUpdate = async () => {
        try {

            const teamId = getAllMembersQuery.teamId ?? "";

            if (!teamId || type !== "update") return;

            const newPermission = permission === "editor" ? ["create", "delete", "read", "update"] : ["read"];

            console.log(oldPermissions, newPermission);

            if (JSON.stringify(oldPermissions) !== JSON.stringify(newPermission)) {

                await updatePermission.mutateAsync({
                    teamId,
                    memberId: memberId ?? "",
                    spaceId: spaceId ?? "",
                    permissions: newPermission as any[]
                });

                invalidate([QueryKeys.teams.update]);

                useCommonStore.getState().setActivePopup("");

            }

            console.log(4);

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    return (
        <Popup
            width="34%"
            name={type === "add" ? "Invite Member" : "Manage Access"}
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <div className="mt-3 space-y-2">

                <Input
                    readOnly={type === "update"}
                    required={true}
                    label="Email"
                    type="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => {
                        if (err) setErr("");
                        setEmail(e.target.value);
                    }}
                    error={err}
                />

                <div>

                    <Text
                        variant="label"
                        text="Permissions"
                    />

                    <div className="mt-1 space-y-3">

                        <Card
                            active={permission === "editor"}
                            title="Editor"
                            label="Can edit content in this space but can't invite new teammates."
                            onClick={() => setPermission("editor")}
                        />

                        <Card
                            active={permission === "viewer"}
                            title="Viewer"
                            label="Can edit content in this space but can't invite new teammates."
                            onClick={() => setPermission("viewer")}
                        />

                    </div>

                </div>

            </div>

            <div className="flex items-center justify-end space-x-3 mt-2 pt-2">

                <SecondaryButton
                    disabled={isLoading}
                    className="w-auto text-sm font-medium mt-0"
                    onClick={() => useCommonStore.getState().setActivePopup("")}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    className="w-auto text-sm font-medium mt-0"
                    onClick={() => type === "add" ? onAdd() : onUpdate()}
                >
                    {type === "add" ? "Invite" : "Update"}
                </Button>

            </div>

        </Popup>
    );
};

type Props = {
    title: string,
    active: boolean,
    label: string,
    onClick: () => void;
};

function Card({ title, active, label, onClick }: Props) {
    return (
        <div
            className={cn(`
                border border-gray-300 p-3 rounded-lg cursor-pointer
                hover:border-blue-600 hover:text-primary hover:bg-blue-50 
                transition-all duration-200 ease-in-out space-y-1
                outline- outline-1 outline-offset-1 outline-gray-300
            `, {
                "outline-blue-300 text-primary bg-blue-50 ring-1 ring-offset-1 ring-blue-400 ": active
            })}
            onClick={() => onClick()}
        >

            <p className="text-xs font-medium tracking-wide">
                {title}
            </p>

            <p className="text-xs font-normal tracking-wide text-gray-600">
                {label}
            </p>

        </div>
    );
}