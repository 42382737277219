import { useWallOfLoveStore } from "@store";
import { WallOfLoveTemplatePreview } from "./preview/main.preview";
import { WallOfLoveSettings } from "./settings/main.settings";
import { SelectTestimonials } from "@components/selectTestimonials";
import { useSearchParams } from "react-router-dom";

export function EditWallOfLovePage() {

    const showTestimonials = useWallOfLoveStore((store) => store.showSelectTestimonials);

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="w-full h-full max-h-full max-w-full overflow-hidden flex">

            <WallOfLoveSettings />

            <div className="w-full h-full">

                {showTestimonials ?
                    <SelectTestimonials
                        className=""
                        onHandleApply={() => {
                            searchParams.set("tab", "branding");
                            setSearchParams(searchParams);
                        }}
                    /> :
                    <WallOfLoveTemplatePreview />
                }

            </div>

        </div>
    );
}