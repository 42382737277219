import { useEffect, useState } from "react";
import { Card } from "./card";
import { useFormStore } from "@store";
import toast from "react-hot-toast";
import { QueryKeys, useFormApiClient, useGetQueryData } from "@hooks";

type Option = { label: string, value: string; };

type Props = {
    loading: boolean;
};

export function NotifyRespondentMail({ loading }: Props) {

    const { formId, } = useFormStore((store) => ({
        formId: store.id,

    }));

    // const query = useGetQueryData<Form.State["emailNotification"]>([QueryKeys.form.getEmailTemplates]);

    const [enable, setEnable] = useState(false);
    const [show, setShow] = useState(false);
    const [sendTo, setSendTo] = useState("");
    const [subject, setSubject] = useState(`GoZen Forms: new response to your form`);
    const [message, setMessage] = useState(`
        <div> Hello, </div>
        <div><br></div>
        <div> We’ve received your submission. </div>
        <div><br></div>
        <div> Thank you & have a nice day! </div><div><br></div>
        <div> We’ve received your submission. </div>
        <div><br></div>
        <div> Thank you & have a nice day! </div>
    `);
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {

        const newOptions: Option[] = [];

        const primaryEmailField = useFormStore.getState().customDetails.fields.email.collect;

        const emailFields = useFormStore.getState().customDetails.customFields.filter(field => field.type === "email");

        emailFields.forEach(field => newOptions.push({ label: field.label, value: field.id }));

        if (primaryEmailField) newOptions.push({ label: "email", value: "email" });

        setOptions([...newOptions]);

        if (options.length) {

            setEnable(true);

            // if (query && query.respondents) {

            //     if (query.respondents?.message) setMessage(query.respondents?.message);

            //     if (query.respondents?.subject) setSubject(query.respondents?.subject);

            //     setShow(true);

            // } else setShow(false);

        } setEnable(false);

    }, []);

    const { saveEmailTemplate } = useFormApiClient({});

    const { isPending } = saveEmailTemplate;

    const onSave = async (disable: boolean) => {

        await saveEmailTemplate.mutateAsync({
            id: formId,
            emailNotification: {
                respondents: disable === false ? { message, sendTo, subject } : null,
            },
            onError: (err: any) => toast.error(err),
        });

    };

    const onShowToggle = () => {

        // const previouslyEnabled = query.respondents?.message !== null && query.respondents?.subject !== null;

        // if (!show === false && previouslyEnabled) onSave(true);

        // setShow(!show);

    };

    return (
        <Card
            type="respondents"
            loading={loading}
            enable={enable}
            active={show}
            saving={isPending}
            sendTo={sendTo}
            options={options}
            title="Notify Respondents"
            description="Send email to your respondents."
            subject={subject}
            message={message}
            onSave={() => saveEmailTemplate.mutate(false)}
            onSendToChange={(val) => setSendTo(val)}
            onSubjectChange={(val) => setSubject(val)}
            onMessageChange={(val) => setMessage(val)}
            onToggle={() => onShowToggle()}
        />
    );
}