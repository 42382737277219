import { QueryKeys, useGetQueryData } from "@hooks";
import { BillingUtils, } from "@utils/billingUtils";
import { cn } from "@utils/cn";
import { FaStar } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

export const CurrentPlan: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const [searchParams, setSearchParams] = useSearchParams();

    const onChangePlan = () => {
        searchParams.set("b_tab", "plans");
        setSearchParams(searchParams);
    };

    const plan = BillingUtils.getSubscriptionPricing(billing.plan);

    return (
        <div className="relative w-[45%] px-6 py-5 rounded-2xl bg-primary space-y-3 shadow-lg">

            <div className="absolute top-7 right-7 bg-gray-50/20 rounded-full p-2">
                <FaStar className="w-10 h-10 text-gray-50" />
            </div>

            <div className="text-sm font-medium text-white">
                Your current subscription plan.
            </div>

            <h1 className="text-4xl font-bold text-white">

                <span className="text-sm"> $ </span>

                {billing.billingInterval === "monthly" ?
                    plan.monthlyPrice.toFixed(2) :
                    plan.yearlyPrice.toFixed(2)
                }

                <span className="text-sm">
                    / {billing.billingInterval === "monthly" ? "month" : "year"}
                </span>

            </h1>

            <div className="font-medium tracking-wide text-white capitalize">
                {billing.plan}
            </div>

            {BillingUtils.isBundlePlan(billing.plan) === false ?
                <div
                    className={cn(`
                        bg-white text-primary text-sm font-normal px-4 py-2 rounded-lg inline-block 
                        hover:bg-gray-200 cursor-pointer
                    `)}
                    onClick={() => onChangePlan()}
                >
                    Change Plan
                </div> : null
            }

        </div>
    );
};