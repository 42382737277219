import { Button } from "@components/button";
import { DeleteInvites } from "./table/delete";
import { EmailInvitesTable } from "./table/main.table";
import { Text } from "@components/text";
import { MdSend } from "react-icons/md";
import { useCommonStore, useInvitesStore } from "@store";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@hooks";



export function ListEmailInvites() {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const onEditTemplate = () => {

        const query = queryClient.getQueryData<Api.EmailInvites.GetAll>([QueryKeys.emailInvites.getAll]);

        if (!query) return;

        useInvitesStore.getState().setTemplate({ ...query.template });

        navigate("/invites/template");

    };


    return (
        <div>

            <div className="flex items-center justify-between px-2">

                <div className="flex items-center space-x-4">

                    <Text
                        variant="primary"
                        className="text-xl font-semibold tracking-wide"
                    >
                        Email Invites
                    </Text>

                    <DeleteInvites />

                </div>


                <div className="flex items-center space-x-4">

                    <Button
                        variant="outline"
                        className="text-sm font-medium w-auto tracking-wide"
                        onClick={() => onEditTemplate()}
                    >

                        <MdOutlineModeEdit className="w-5 h-5 mr-2" />

                        <p> Edit Template</p>

                    </Button>

                    <Button
                        className="w-auto text-sm font-medium tracking-wide"
                        onClick={() => {
                            useCommonStore.getState().setActivePopup("invite-emails");
                        }}
                    >

                        <MdSend className="w-4 h-4 mr-2" />

                        <span>
                            Add New Invites
                        </span>

                    </Button>

                </div>

            </div>

            <EmailInvitesTable />

        </div>
    );
}