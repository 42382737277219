import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useState } from "react";
import { ProfileDropDown } from "./dropDown";
import { useClickOutside, useUsersApiClient } from "@hooks";
import { useUserStore } from "@store";
import toast from "react-hot-toast";
import { ParseAxiosError } from "@lib/axios";
import { cn } from "@utils/cn";


export function Profile() {

    const [showDropdown, setShowDropdown] = useState(false);

    const { avatarUrl, name, setAvatarUrl } = useUserStore();

    const ref = useClickOutside(() => showDropdown && setShowDropdown(false));

    const { getProfile } = useUsersApiClient({
        getProfileParams: {
            params: {
                enabled: false,
                // enabled: !email && !billing.plan,
                onError: (err: any) => toast.error(ParseAxiosError(err).message),
            }
        }
    });

    const { isPending } = getProfile;

    const onInvalidPic = () => { setAvatarUrl(GenerateProfilePic(name[0])); };

    return (
        <div
            ref={ref}
            className="flex items-center h-full"
        >

            <div className="relative">

                <div
                    className="flex items-center pl-2"
                    onClick={() => { if (!isPending) setShowDropdown(true); }}
                >

                    {isPending ?
                        <div>
                            loading...
                        </div> :
                        <img
                            className={cn(`
                                flex-shrink-0 object-cover object-center w-10 h-10 
                                border border-gray-300 rounded-full cursor-pointer
                            `)}
                            src={avatarUrl}
                            onError={() => onInvalidPic()}
                        />
                    }

                </div>

                {showDropdown && !isPending ? <ProfileDropDown /> : null}

            </div>

        </div>
    );
}