import { UndefinedInitialDataOptions, useMutation, useQuery, } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { CustomQueryParams } from "../useCustomQuery";
import { useFormStore } from "@store";
import { LocalStorageUtil } from "@utils/localStorage";
import { ParseAxiosError } from "@lib/axios";
import { Config } from "@config";
import { useActiveSpace } from "../useActiveSpace";


type Params = {
    getParams?: {
        params: Partial<CustomQueryParams>,
    },
    getEmailTemplateParams?: {
        apiParams: { formId: string; },
        params: Partial<UndefinedInitialDataOptions>,
    };
};

export function useFormApiClient({ getParams, getEmailTemplateParams }: Params) {

    const { formId } = useActiveSpace();

    const get = useQuery({
        queryKey: [QueryKeys.form.get, formId],
        enabled: false,
        queryFn: async () => {
            try {

                const data = (await axios.get<{ form: Form.State; }>("/api/v1/form", {
                    params: { id: formId }
                })).data;

                useFormStore.getState().setForm({ ...data.form });

                const snapshot = { ...data.form };

                ["id", "version", "publishedVersion"].forEach(key => delete (snapshot as any)[key]);

                LocalStorageUtil.set<FormSnapShot>("formSnapshot", snapshot);

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...getParams?.params
    });

    const update = useMutation({
        mutationKey: [QueryKeys.form.update],
        mutationFn: async (form: any) => {
            try {
                return (await axios.put<Api.Form.update>("/api/v1/form", form)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getEmailTemplate = useQuery({
        enabled: false,
        queryKey: [QueryKeys.form.getEmailTemplates],
        queryFn: async () => {
            try {
                // return (await axios.get<Form.State["emailNotification"]>("/api/v1/form/notification", {
                //     params: getEmailTemplateParams?.apiParams
                // })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const saveEmailTemplate = useMutation({
        mutationKey: [QueryKeys.form.saveEmailTemplate],
        mutationFn: async (form: any) => {
            try {
                // return (await axios.put<Form.State["emailNotification"]>("/api/v1/form/notification", form)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const uploadVideo = async (
        params: Api.Form.uploadVideoParams & { file: Blob; },
        onUploadProgress?: (progress: number) => void
    ) => {
        try {

            const formData = new FormData();

            Object.keys(params).forEach(field => formData.set(field, (params as any)[field]));

            formData.set("file", params.file);

            const unAxios = axios.create({
                withCredentials: true
            });

            const res = await unAxios.post<{ url: string; }>(`${Config.Api()}/api/v1/form/upload`,
                formData,
                {
                    onUploadProgress: (progress) => {

                        if (progress && progress.total && progress.upload) {

                            onUploadProgress?.(Math.round((progress.loaded * 100) / progress.total));

                        }

                    }
                }
            );

            return { url: res.data.url };

        } catch (err) {
            console.log({ err });
            throw err;
        }
    };

    return {
        get,
        update,
        getEmailTemplate,
        saveEmailTemplate,
        uploadVideo
    };

}