import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { State } from "./state";

export const useWidgetsStore = create<WidgetsStore.Type>()(devtools(immer(set => ({
    ...State,
    setSaving: (val) => set((state) => {

        state.saving = val;

    },
        false,
        "widgets/setSaving"
    ),
    setSelectTestimonials: (val) => set((state) => {

        state.selectTestimonials = val;

    },
        false,
        "widgets/setSelectTestimonials"
    ),
    setWidget1Settings: (params) => set((state) => {

        state.widget1 = { ...state.widget1, ...params };

    },
        false,
        "widgets/setWidget1Settings"
    ),
    setWidget2Settings: (params) => set((state) => {

        state.widget2 = { ...state.widget2, ...params };

    },
        false,
        "widgets/setWidget2Settings"
    ),
    setWidget3Settings: (params) => set((state) => {

        state.widget3 = { ...state.widget3, ...params };

    },
        false,
        "widgets/setWidget3Settings"
    ),
    setWidget4Settings: (params) => set((state) => {

        state.widget4 = { ...state.widget4, ...params };

    },
        false,
        "widgets/setWidget4Settings"
    ),
    setWidget5Settings: (params) => set((state) => {

        state.widget5 = { ...state.widget5, ...params };

    },
        false,
        "widgets/setWidget5Settings"
    ),
    setWidget7Settings: (params) => set((state) => {

        state.widget7 = { ...state.widget7, ...params };

    },
        false,
        "widgets/setWidget7Settings"
    ),
    setWidget8Settings: (params) => set((state) => {

        state.widget8 = { ...state.widget8, ...params };

    },
        false,
        "widgets/setWidget8Settings"
    ),
    setSelectedTestimonialIds: (widgetId, id) => set((state) => {

        /* only some widgets require testimonial ids */

        if (widgetId === "w2") {

            if (state.widget2.testimonialIds.includes(id)) {
                state.widget2.testimonialIds = [...state.widget2.testimonialIds.filter(item => item !== id)];
            } else {
                state.widget2.testimonialIds.push(id);
            }

        } else if (widgetId === "w3") {

            if (state.widget3.testimonialIds.includes(id)) {
                state.widget3.testimonialIds = [...state.widget2.testimonialIds.filter(item => item !== id)];
            } else {
                state.widget3.testimonialIds.push(id);
            }

        } else if (widgetId === "w4") {

            if (state.widget4.testimonialIds.includes(id)) {
                state.widget4.testimonialIds = [...state.widget2.testimonialIds.filter(item => item !== id)];
            } else {
                state.widget4.testimonialIds.push(id);
            }

        } else if (widgetId === "w5") {

            if (state.widget5.testimonialIds.includes(id)) {
                state.widget5.testimonialIds = [...state.widget2.testimonialIds.filter(item => item !== id)];
            } else {
                state.widget5.testimonialIds.push(id);
            }

        } else if (widgetId === "w7") {

            if (state.widget7.testimonialIds.includes(id)) {
                state.widget7.testimonialIds = [...state.widget2.testimonialIds.filter(item => item !== id)];
            } else {
                state.widget7.testimonialIds.push(id);
            }

        }

    },
        false,
        "widgets/setWidget8Settings"
    )
}))));