

export const State: WolStore.State = {
    showSelectTestimonials: false,
    templateId: "",
    settings: {
        branding: {
            logo: "",
            logoSize: 50,
            primaryColor: "#2563EB",
            fontFamily: "Inter",
            removeBranding: false,
            fontColor: "white",
            buttonColor: "white",
        },
        content: {
            showDates: true,
            showRatings: true,
            showCustomerPhoto: true,
            showGravatarPhotoIfNotAvailable: true,
            columnCount: 3,
            inlineCTA: null,
        },
        hero: {
            title: "Wall of Love",
            subTitle: "See what customers are saying about us",
            cta: null,
        },
        navigation: {
            hideNavBar: true,
            homeLink: "",
            centerNav: false,
            headerLinks: []
        },
    }
};
