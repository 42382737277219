import { Input } from "@components/input";
import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useImportTestimonialStore } from "@store";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    content: z.string().min(1, "Testimonial cannot be empty.")
});

export function ProductHuntImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const navigate = useNavigate();

    const onImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial: { ...testimonial, type: "text" },
                formId: activeSpace.formId,
                source: "productHunt"
            });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

            navigate("/testimonials");

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import from Product Hunt"
            onImport={() => onImport()}
        >

            {true ?
                <ManuallyImport
                    fields={{
                        email: null,
                        company: false,
                        companyLogo: false,
                        role: false,
                        testimonial: {
                            text: { content: true, imageAttachments: false },
                            audio: { url: false, transcribedText: false },
                            video: { url: false, transcribedText: false }
                        },
                        sourceUrl: {
                            label: "Post Url",
                            placeholder: "https://producthunt.com/products/gozen"
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="Product Hunt Product / Launch URL"
                        required={true}
                    />

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>
            }

        </ImportCard>
    );
}