import { Input } from "@components/input";
import { useCommonStore, useUserStore } from "@store";
import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { ChangeEmailPopup } from "../changeEmail.popup";
import { Button, SecondaryButton } from "@components/button";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IsValidPhoneNumber } from "@utils/isValidPhoneNumber";
import { z } from "zod";
import { useUsersApiClient } from "@hooks";
import { ChangePassword } from "./changePassword";
import toast from "react-hot-toast";

const PhoneInput: React.FC<PhoneInputProps> = (ReactPhoneInput as any)?.default || ReactPhoneInput;

const schema = z.object({
    firstName: z.string().min(3, "First Name Should Be At Least 2 Character."),
    lastName: z.string().optional(),
    phoneNumber: z.string().optional().refine((val) => val && val?.length ? IsValidPhoneNumber(val) : true, "Enter A Valid Phone Number.")
});

export function ProfileDetails() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const { updateProfileInfo } = useUsersApiClient({});

    const { name, email, phoneNumber } = useUserStore((store) => ({
        name: store.name,
        email: store.email,
        phoneNumber: store.phoneNumber
    }));

    const [firstName, lastName] = name.split(" ");

    const [newFirstName, setNewFirstName] = useState(firstName);
    const [newLastName, setNewLastName] = useState(lastName);
    const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber);

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const changed = firstName !== newFirstName || lastName !== newLastName || phoneNumber !== newPhoneNumber;

    const onUndo = () => {
        setNewFirstName(firstName);
        setNewLastName(lastName);
        setNewPhoneNumber(phoneNumber);
    };

    const onSaveChanges = async () => {
        try {

            const result = schema.safeParse({
                firstName: newFirstName,
                lastName: newLastName,
                phoneNumber: newPhoneNumber ?? ""
            });

            if (result.success === false) {

                const issues = result.error.issues;

                setFirstNameError(issues.find((issue) => issue.path.includes("firstName"))?.message ?? "");
                setLastNameError(issues.find((issue) => issue.path.includes("lastName"))?.message ?? "");
                setPhoneNumberError(issues.find((issue) => issue.path.includes("phoneNumber"))?.message ?? "");

                return;

            }

            await updateProfileInfo.mutateAsync({
                firstName: newFirstName ?? "",
                lastName: newLastName ?? "",
                phoneNumber: newPhoneNumber ?? ""
            });

            useUserStore.getState().setName(`${newFirstName} ${newLastName}`);

            useUserStore.getState().setPhoneNumber(newPhoneNumber);

            toast.success("Updated successfully.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-full h-full space-y-4">

            <div
                className="w-full h-full border border-gray-200 bg-gray-50 rounded-md px-3 py-2"
            >

                <div className="flex">

                    <div className="w-[50%] p-5 space-y-4">

                        <Input
                            type="text"
                            label="First name"
                            placeholder="Ex: Gavin"
                            value={newFirstName}
                            error={firstNameError}
                            required={true}
                            onChange={(e) => setNewFirstName(e.target.value)}
                        />

                        <Input
                            type="text"
                            label="Last name"
                            placeholder="Ex: Smith"
                            required={false}
                            value={newLastName}
                            error={lastNameError}
                            onChange={(e) => setNewLastName(e.target.value)}
                        />

                    </div>

                    <div className="w-[50%] p-5 space-y-4">

                        <Input
                            label="Email"
                            value={email}
                            type="email"
                            readOnly={true}
                            className="cursor-pointer"
                            onClick={() => useCommonStore.getState().setActivePopup("change-email")}
                        />

                        <div className="mb-4 w-full">

                            <label className="block mb-2 text-sm font-normal text-gray-600">
                                Phone number
                            </label>

                            <div>

                                <PhoneInput
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                    inputClass="text-gray-600 font-normal"
                                    country="us"
                                    placeholder="Enter phone number"
                                    value={phoneNumber}
                                    onChange={(val) => setNewPhoneNumber(val)}
                                />

                                {phoneNumberError &&
                                    <motion.span
                                        className="text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 text-sm font-normal text-left"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                    >
                                        * {phoneNumberError}
                                    </motion.span>
                                }

                            </div>

                        </div>

                    </div>

                </div>

                <AnimatePresence>

                    {changed ?
                        <motion.div
                            className="flex items-center justify-end px-5 pb-2 space-x-3"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >

                            <SecondaryButton
                                disabled={updateProfileInfo.isPending}
                                className="w-auto text-sm font-medium"
                                onClick={() => onUndo()}
                            >
                                Undo
                            </SecondaryButton>

                            <Button
                                loading={updateProfileInfo.isPending}
                                disabled={updateProfileInfo.isPending}
                                className="w-auto text-sm font-medium"
                                onClick={() => onSaveChanges()}
                            >
                                Save Changes
                            </Button>

                        </motion.div> : null
                    }

                </AnimatePresence>

                {activePopup === "change-email" ? <ChangeEmailPopup /> : null}

            </div>

            <ChangePassword />

        </div>
    );
}