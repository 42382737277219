import { useGetQueryData, QueryKeys } from "@hooks";
import { cn } from "@utils/cn";

export const CardInfo: React.FC = () => {

    const user = useGetQueryData<Api.Auth.Login>([QueryKeys.user.getProfile]);

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const name = user?.user?.name ?? "";

    const card = billing.cards;

    return (
        <div className={cn(`border rounded-xl w-[45%] py-4 px-6 relative shadow-lg`, {
            "blur-sm": billing.plan === "free"
        })}>

            <p className="mt-3 text-sm font-medium"> Card Information </p>

            <div className="absolute top-10 right-10">

                {card?.brand === "mastercard" ? <></> : null}

                {card?.brand === "Visa" ? <></> : null}

                {card?.brand === "amex" ? <></> : null}

            </div>

            <div className="h-[85%] flex flex-col justify-between">

                <div className="mt-10 text-xl">
                    ****  ****  ****  {card?.last4 ?? ""}
                </div>


                <div className="flex items-center justify-between mb-2">

                    <div className="text-sm font-normal capitalize">
                        {name}
                    </div>

                    <div className="flex items-center justify-center space-x-2">

                        <p className="text-xxs"> VALID UPTO </p>

                        <p className="text-sm">
                            {`${card?.expMonth ?? "00"} / ${card?.expYear ?? "0000"}`}
                        </p>

                    </div>

                </div>

            </div>

        </div>
    );
};