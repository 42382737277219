import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";
import { SocialProof } from "./socialProof.type";
import { SocialProofState } from "./state";

export const useSocialProofStore = create<SocialProof.Type>()(devtools(persist(immer(set => ({
    ...SocialProofState,
    clear: () => set((state) => {

    },
        false,
        "socialProof/clear"
    ),
    /* image template */
    setImageTemplate: (params) => set((state) => {

        state.imageTemplate = params;

    },
        false,
        "testimonials/setImageTemplate"
    ),
    setImageTemplateContent: (content) => set((state) => {

        state.imageTemplate.content = { ...state.imageTemplate.content, ...content };

    },
        false,
        "testimonials/setImageTemplateContent"
    ),
    setImageTemplateSettings: (settings) => set((state) => {

        state.imageTemplate.settings = { ...state.imageTemplate.settings, ...settings };

    },
        false,
        "testimonials/setImageTemplateSettings"
    ),
    setImageTemplateBackground: (background) => set((state) => {

        state.imageTemplate.background = { ...state.imageTemplate.background, ...background };

    },
        false,
        "testimonials/setImageTemplateBackground"
    ),
    setImageTemplateBranding: (branding) => set((state) => {

        state.imageTemplate.branding = { ...state.imageTemplate.branding, ...branding };

    },
        false,
        "testimonials/setImageTemplateBranding"
    ),
    /* video template */
    setVideoTemplate: (params) => set((state) => {

        state.videoTemplate = { ...state.videoTemplate, ...params };

    },
        false,
        "testimonials/setVideoTemplate"
    ),
    setVideoTemplateBackground: (background) => set((state) => {

        state.videoTemplate.background = { ...state.videoTemplate.background, ...background };

    },
        false,
        "testimonials/setVideoTemplateBackground"
    ),
})), {
    name: StoreKeys.socialProof
}), {
    name: StoreKeys.socialProof,
    enabled: import.meta.env.DEV
}));