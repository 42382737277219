import { Select } from "@components/select";
import { QueryKeys, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import {
    ResponsiveContainer,
    AreaChart,
    XAxis,
    YAxis,
    Area,
    Tooltip
} from "recharts";


export function TestimonialsTrendChart() {

    const data = useGetQueryData<Api.SpacesClient.getDetails>([QueryKeys.spaces.getDetails]);

    const monthlyTestimonialsTrend = data?.monthlyTestimonialsTrend ?? {};

    const options = Object.keys(monthlyTestimonialsTrend).map((key) => {
        const [month, year] = key.split("_");
        const monthName = new Date(parseInt(year), parseInt(month), 1).toLocaleString('default', { month: 'long' });

        return {
            label: `${monthName}-${year}`,
            value: key
        };
    });

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const [currentMonthKey, setCurrentMonthKey] = useState(`${currentMonth}_${currentYear}`);

    const [chartData, setChartData] = useState<{ date: string, value: number; }[]>([]);

    useEffect(() => {

        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

        const trendData = data.monthlyTestimonialsTrend[currentMonthKey] || {};

        const chartData = Array.from({ length: daysInMonth }, (_, index) => {

            const day = (index + 1).toString().padStart(2, '0');

            const dateKey = `${currentYear}-${currentMonth + 1}-${day}`;

            return {
                date: dateKey,
                value: trendData[dateKey] || 0
            };

        });

        setChartData(chartData);

    }, [currentMonthKey]);

    return (
        <div className={cn(`
            h-[60%] w-full space-y-3
            flex flex-col items-start justify-center bg-white rounded-lg 
            border p-4 shadow-md
        `)}>

            <div className="flex items-center justify-between w-full">

                <p className="text-lg font-semibold tracking-wide px-2">
                    Monthly Testimonials Trend
                </p>

                <Select
                    className="w-auto m-0 p-0"
                    placeholder="Select Month"
                    options={options}
                    defaultValue={currentMonthKey}
                    onChange={(e: any) => setCurrentMonthKey(e.value as string)}
                />

            </div>

            <ResponsiveContainer
                width="99%"
                height={400}
                className={"mt-4"}
            >

                <AreaChart
                    data={chartData}
                    className="p-0 m-0"
                    style={{ right: 0 }}
                >

                    <defs>
                        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
                            <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05} />
                        </linearGradient>
                    </defs>

                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#2451B7"
                        fill="url(#color)"
                    />

                    <XAxis
                        dataKey="date"
                        axisLine={false}
                        tickLine={false}
                    />

                    <YAxis
                        dataKey="value"
                        axisLine={false}
                        tickLine={false}
                        tickCount={8}
                        tickFormatter={(number) => parseInt(number).toFixed(1)}
                    />

                    <Tooltip content={<CustomTooltip />} cursor={false} />

                </AreaChart>
            </ResponsiveContainer>

        </div >
    );
}


function CustomTooltip({ active, payload, label }: any) {
    if (active && payload && payload.length) {
        const date = new Date(label); // Assuming label is a date string
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();

        return (
            <div className="tooltip bg-white p-4 rounded shadow-md border space-y-2">
                <h4 className="font-semibold">{`${month} ${day}`}</h4>
                <p className="text-sm">{`Value: ${Math.round(payload[0].value)}`}</p> {/* Round to nearest integer */}
            </div>
        );
    }
    return null;
}