import { Rating } from "@components/rating";
import { Fragment } from "react";
import TemplateBg from "./t3.png";
import { useSocialProofStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { PoweredBy } from "@components/branding";
import Avatar from "react-avatar";


export function Template3() {

    const {
        name, role, company, rating, text, profileUrl,
        showProfile, showRating, showBranding
    } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text,
        profileUrl: store.imageTemplate.content.profileUrl,
        showProfile: store.imageTemplate.settings.showProfile,
        showRating: store.imageTemplate.settings.showRating,
        showBranding: !store.imageTemplate.branding.remove,
    }));

    return (
        <div
            id="preview-template"
            className="border border-gray-600 rounded-lg bg-gray-300 relative h-[1080px] w-[1080px] scale-[0.4]"
        >

            <img
                src={TemplateBg}
                className="w-full h-full rounded-md"
            />

            <div className="absolute top-[98px] left-[70px] w-[84%] p-8 flex items-center space-y-4">

                <div className="flex flex-col items-start ">

                    <div className="flex items-center justify-start space-x-6 w-full h-full">

                        {showProfile && (profileUrl.length || name.length) ?
                            <Fragment>

                                {profileUrl.length ?
                                    <img
                                        src={profileUrl}
                                        crossOrigin="anonymous"
                                        className="w-[6rem] h-[6rem] rounded-full"
                                    /> : null
                                }

                                {!profileUrl && name.length ?
                                    <Avatar
                                        name={name}
                                        size="90px"
                                        round
                                        textSizeRatio={1}
                                    /> : null
                                }

                            </Fragment> : null
                        }

                        <div className="space-y-3">

                            <p className="text-4xl font-semibold tracking-wider text-white">
                                {name}
                            </p>

                            {role && company ?
                                <p className="text-3xl font-normal text-gray-100">

                                    {role && company ? <> {role} of {company} </> : null}

                                    {role ? role : null}

                                    {company ? company : null}

                                </p> : null
                            }

                        </div>

                    </div>

                    {showRating && rating ?
                        <Rating
                            rated={rating}
                            className="mt-4 w-[70px] h-[70px] mx-2"
                            iconProps={{
                                stroke: "black",
                                strokeWidth: 8,
                                className: "mt-4 w-[60px] h-[60px] mx-2"
                            }}
                        /> : null
                    }

                    <p
                        className="text-white text-4xl leading-normal font-medium tracking-wide break-words mt-2 p-4"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                    />

                </div>

            </div>

            {showBranding ?
                <div className="absolute bottom-6 right-6">

                    <PoweredBy />

                </div> : null
            }

        </div>
    );

}