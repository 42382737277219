import { NameField } from "./name";
import { EmailField } from "./email";
import { AvatarField } from "./avatar";
import { CompanyField } from "./company";
import { RoleField } from "./role";
import { RatingField } from "./rating";
import { CompanyUrlField } from "./companyUrl";
import { TextTestimonialField } from "./text/textTestimonial";
import { TextTestimonialImageAttachmentsField } from "./text/imageAttachements";
import { CompanyLogoField } from "./companyLogo";
import { DateField } from "./date";
import { UploadVideoTestimonialField } from "./video/upload";
import { VideoTestimonialTranscriptField } from "./video/transcript";
import { UploadAudioTestimonialField } from "./audio/upload";
import { AudioTestimonialTranscriptField } from "./audio/transcript";
import { Fragment } from "react";
import { SourceUrlField } from "./sourceUrl";
import { TagLineField } from "./tagline";

type Props = {
    fields?: {
        name?: {
            placeholder?: string,
            label?: string,
        } | null,
        email?: {
            placeholder?: string,
            label?: string,
        } | null,
        tagline?: {
            placeholder?: string,
            label?: string;
        },
        avatar?: {
            labe?: string,
        },
        company?: boolean,
        companyLogo?: boolean,
        role?: boolean,
        rating?: boolean,
        testimonial?: {
            text?: {
                content: boolean,
                imageAttachments: boolean;
            } | null,
            audio?: {
                transcribedText: boolean,
                url: boolean;
            } | null,
            video?: {
                transcribedText: boolean,
                url: boolean;
            } | null;
        },
        sourceUrl?: {
            placeholder?: string,
            label?: string;
        } | null,
        date?: boolean,
        attachmentImages?: boolean;
    };
};

export function ManuallyImport({ fields }: Props) {

    fields = {
        attachmentImages: true,
        company: true,
        tagline: {},
        companyLogo: true,
        date: true,
        email: {},
        name: {},
        rating: true,
        role: true,
        avatar: {},
        testimonial: {
            text: null,
            audio: null,
            video: null
        },
        sourceUrl: {},
        ...fields
    };

    return (
        <Fragment>

            <div className="flex space-x-4">

                {fields.name ? <NameField {...fields.name} /> : null}

                {fields.email ? <EmailField {...fields.email} /> : null}

            </div>

            {fields.tagline ? <TagLineField {...fields.tagline} /> : null}

            <div className="flex justify-between space-x-4">

                {fields.avatar ? <AvatarField /> : null}

                {fields.rating ? <RatingField /> : null}

            </div>


            {/* text testimonial */}

            {fields.testimonial?.text && fields.testimonial?.text?.content ?
                <TextTestimonialField /> : null
            }

            {fields.testimonial?.text && fields?.testimonial?.text.content && fields.testimonial.text.imageAttachments ?
                <TextTestimonialImageAttachmentsField /> :
                null
            }

            {/* video testimonial */}

            {fields.testimonial?.video && fields.testimonial.video.url ?
                <UploadVideoTestimonialField /> : null
            }

            {fields.testimonial?.video && fields.testimonial.video.url && fields.testimonial.video.transcribedText ?
                <VideoTestimonialTranscriptField /> : null
            }

            {/* audio testimonial */}

            {fields.testimonial?.audio && fields.testimonial.audio.url ?
                <UploadAudioTestimonialField /> : null
            }

            {fields.testimonial?.audio && fields.testimonial.audio.url && fields.testimonial.audio.transcribedText ?
                <AudioTestimonialTranscriptField /> : null
            }

            {fields.company || fields.role ?
                <div className="flex space-x-4">

                    {fields.company ? <CompanyField /> : null}

                    {fields.role ? <RoleField /> : null}

                </div> : null
            }

            {fields.company || fields.companyLogo ?
                <div className="flex space-x-4">

                    {fields.company ? <CompanyUrlField /> : null}

                    {fields.companyLogo ? <CompanyLogoField /> : null}

                </div> : null
            }

            {fields.sourceUrl ? <SourceUrlField {...fields.sourceUrl} /> : null}

            {fields.date ? <DateField /> : null}

        </Fragment>
    );
}