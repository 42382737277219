import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";
import axios from "axios";

export function useWallOfLoveApiClient() {

    const save = useMutation({
        mutationKey: [QueryKeys.wol.save],
        mutationFn: async (params: Api.Wol.SaveParams) => {
            try {

                return (await axios.put("/api/v1/wall-of-love", params)).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.wol.delete],
        mutationFn: async (id: string) => {
            try {

                return (await axios.delete(`/api/v1/wall-of-love`, { data: { id } })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getAll = useQuery({
        enabled: false,
        retry: 1,
        queryKey: [QueryKeys.wol.getAll],
        queryFn: async () => {
            try {

                return (await axios.get<Api.Wol.getAll>("/api/v1/wall-of-love")).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const create = useMutation({
        mutationKey: [QueryKeys.wol.create],
        mutationFn: async (params: Api.Wol.CreateParams) => {
            try {

                return (await axios.post<Api.Wol.Create>("/api/v1/wall-of-love", params)).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        save,
        deleteQuery,
        getAll,
        create,
    };

}
