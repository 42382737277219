import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useInvitesStore } from "@store";


export function Message() {

    const { content, subject } = useInvitesStore((store) => ({
        content: store.template.message.content,
        subject: store.template.message.subject
    }));

    return (
        <div className="space-y-2">

            <div>

                <Text
                    variant="label"
                    text="Subject"
                />

                <QuillInput
                    valueAsHTML={subject}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "1.5rem",

                    }}
                    placeHolder="Send a Testimonial"
                    recall={true}
                    onChange={(e) => useInvitesStore.getState().setMessage({ subject: e.html })}
                />

            </div>

            <div>

                <Text
                    variant="label"
                    text="Message"
                />

                <QuillInput
                    valueAsHTML={content}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                    }}
                    placeHolder="Write a message"
                    recall={true}
                    onChange={(e) => {

                        useInvitesStore.getState().setMessage({ content: e.html });

                    }}
                />

            </div>

        </div>
    );
}