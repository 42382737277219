import { useSearchParams } from "react-router-dom";
import { ImportSection } from "./import/main.import";
import { MigrateSection } from "./migrate/main.section";
import { Providers } from "./providers";


export function ImportTestimonialsPage() {

    const [searchParams] = useSearchParams();

    const migrate = searchParams.get("migrate") ?? false;

    return (
        <div className="flex items-start h-full max-h-full overflow-y-auto">

            <Providers />

            {migrate ? <MigrateSection /> : <ImportSection />}

        </div>
    );
}