
export const TimeLine = [
    {
        "title": "Add Custom Domains and Sub Domains",
        "description": "Now add custom/sub to your forms with ease",
        "latest": true,
    },
    {
        "title": "Add Others option for multiple choice field",
        "description": "Now add others option in your multiple choice field - it's fun and easy!",
        "latest": false,
    },
    {
        "title": "Collect Payments In Your Form",
        "description": "Experience the thrill of instant transactions with Gozen Forms! Now you can seamlessly collect payments right within your form.",
        "latest": false,
    },
    {
        "title": "Edit Form After Publishing",
        "description": "Now you can edit your online forms even after publishing without unpublishing to make changes.",
        "latest": false,
    },
    {
        "title": "Generate Form Using AI 🪄",
        "description": "Easily create forms with just a few lines of description.",
        "latest": false,
    },
];