import { Loading } from "@components/loading";
import { Tab } from "@components/tab";
import { useSocialProofApiClient } from "@hooks";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ImageTemplates } from "./data";
import { SavedTemplates } from "./editor/image/list/saved";
import { PreviewCard } from "./previewCard";

export function SocialProofPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get("tab") || "saved";

    const navigate = useNavigate();

    const { getAll } = useSocialProofApiClient();

    useEffect(() => { getAll.refetch(); }, []);

    return (
        <div className="h-full w-full space-y-2 bg-gray-100">

            <div className="px-6 pt-6">

                <p className="text-xl font-semibold tracking-wide">
                    Social Proof Studio
                </p>

                <p className="text-sm font-normal text-gray-600 tracking-wide">
                    Turn your testimonials into shareable social proof.
                </p>

            </div>

            {getAll.isLoading || getAll.isFetching ?
                <Loading /> :
                <div className="m-6 h-[82%] w-[95%]">

                    <Tab
                        active={tab}
                        tabs={[
                            {
                                name: "Saved",
                                value: "saved",
                            },
                            {
                                name: "Images",
                                value: "images"
                            },
                            // {
                            //     name: "Videos",
                            //     value: "videos"
                            // }
                        ]}
                        onChange={(val) => {
                            searchParams.set("tab", val as any);
                            setSearchParams(searchParams);
                        }}
                    />

                    {tab === "saved" ?
                        <SavedTemplates
                            templates={getAll.data?.templates ?? []}
                        /> : null
                    }

                    {tab === "images" ?
                        <div className="flex items-center flex-wrap gap-6 my-4">

                            {ImageTemplates.map((item, idx) => (
                                <PreviewCard
                                    key={idx}
                                    {...item}
                                    onClick={() => navigate(`edit/image/${item.templateId}`)}
                                />
                            ))}

                        </div> : null
                    }


                </div>
            }

        </div>
    );
}