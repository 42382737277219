

export enum StoreKeys {
    onboarding = "onboarding",
    user = "user",
    form = "form",
    socialProof = "social-proof-fe",
    invites = "invites-fe",
    caseStudy = "case-study-fe",
    importTestimonial = "import-testimonial-fe",
    integrations = "integrations-fe",
    widgets = "widgets-fe"
}