import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { ReactNode } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isActive: boolean;
    icon: ReactNode,
    disable?: boolean,
    text: string,
}

export function NavItem({ isActive, className, icon, text, disable, ...props }: Props) {

    const { showFullNavbar } = useCommonStore();

    return (
        <div
            {...props}
            className={cn(`
                flex items-center rounded-lg cursor-pointer hover:text-primary overflow-hidden
                transition-all duration-300 px-[12px] py-2.5 select-none
            `, {
                "text-primary": isActive,
                "bg-blue-100": isActive && showFullNavbar,
                "hover:bg-blue-100": showFullNavbar,
                "bg-blue-100 px-[14px]": !showFullNavbar && isActive,
                "px-[14px]": !showFullNavbar
            })}
        >

            <div>
                {icon}
            </div>

            <p
                className={cn(`
                    whitespace-nowrap text-sm tracking-wide font-medium pl-2.5 
                    transition-all duration-500 ease-in-out
                `, {
                    "pl-0 opacity-0 translate-x-1 overflow-hidden pointer-events-none": !showFullNavbar
                })}
            >
                {text}
            </p>

        </div>
    );
}