import * as Accordion from '@radix-ui/react-accordion';
import { FaAngleDown, FaRegHeart } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { MdOutlineDesignServices } from 'react-icons/md';
import { WallOfLove_Branding } from './branding';
import { WallOfLove_Content } from './content/main.content';
import { MdDomainVerification } from "react-icons/md";
import { WallOfLove_HeroSection } from './heroSection';
import { IoNavigate } from "react-icons/io5";
import { WallOfLove_Navigation } from './navigation';
import { cn } from '@utils/cn';
import { useWallOfLoveStore } from '@store';
import { useSearchParams } from 'react-router-dom';

const Tabs = [
    {
        icon: <MdOutlineDesignServices className="w-5 h-5 text-yellow-600" />,
        title: "Branding",
        value: "branding",
        content: <WallOfLove_Branding />
    },
    {
        icon: <MdDomainVerification className="w-5 h-5 text-blue-600" />,
        title: "Hero Section",
        value: "hero",
        content: <WallOfLove_HeroSection />
    },
    {
        icon: <IoNavigate className="w-5 h-5 text-orange-600" />,
        title: "Navigation",
        value: "navigation",
        content: <WallOfLove_Navigation />
    },
    {
        icon: <FiEdit3 className="w-5 h-5 text-green-600" />,
        title: "Content",
        value: "content",
        content: <WallOfLove_Content />
    },
    // {
    //     icon: <IoPersonOutline className="w-5 h-5 text-pink-600" />,
    //     title: "Custom Domain",
    //     value: "customDomain",
    //     content: <WallOfLove_CustomDomain />
    // },
    // {
    //     icon: <FaAngleDown className="w-5 h-5 text-gray-600" />,
    //     title: "SEO",
    //     value: "SEO",
    //     content: <div className="p-4">More</div>
    // },
];

export function WallOfLoveSettings() {

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="p-4 border-r h-full max-h-full w-[30%] overflow-y-scroll bg-white">

            <Accordion.Root
                type="single"
                defaultValue="item-1"
                collapsible
            >

                <div
                    className={cn(`flex items-center justify-between w-full p-4 cursor-pointer hover:bg-gray-100 rounded-md`, {
                        "bg-gray-100 border border-blue-300": searchParams.get("tab") === "select"
                    })}
                    onClick={() => {
                        useWallOfLoveStore.getState().setShowSelectTestimonials(true);
                        searchParams.set("tab", "select");
                        setSearchParams(searchParams);
                    }}
                >

                    <div
                        className={cn(`flex items-center space-x-2`)}
                    >

                        <FaRegHeart className="w-4 h-4 text-primary" />

                        <p className="font-medium text-sm tracking-wide">
                            Select Testimonials
                        </p>

                    </div>

                </div>

                {Tabs.map((tab, idx) => {
                    return (
                        <Accordion.Item
                            key={idx}
                            value={tab.title}
                            className={cn("hover:bg-gray-100 rounded-md transition-all duration-300 ease-in-out", {
                                "border-t": idx === 0,
                                "border-y": idx > 0 && idx < 6,
                                "bg-gray-100 border border-blue-300": searchParams.get("tab") === tab.value
                            })}
                            onClick={() => {
                                searchParams.set("tab", tab.value);
                                setSearchParams(searchParams);
                            }}
                        >

                            <Accordion.Header className={cn(`p-4 `)}>

                                <Accordion.Trigger
                                    className="flex items-center justify-between w-full"
                                    onClick={() => useWallOfLoveStore.getState().setShowSelectTestimonials(false)}
                                >

                                    <div className="flex items-center space-x-2">

                                        {tab.icon}

                                        <p className="font-medium text-sm tracking-wide">
                                            {tab.title}
                                        </p>

                                    </div>

                                    <FaAngleDown
                                        className={cn(`
                                            ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform 
                                            duration-300 group-data-[state=open]:rotate-180 text-gray-700
                                        `)}
                                    />

                                </Accordion.Trigger>

                            </Accordion.Header>

                            <Accordion.Content
                                className={cn(`
                                    data-[state=open]:border-t
                                    data-[state=open]:animate-slideDown 
                                    data-[state=closed]:animate-slideUp 
                                    overflow-hidden p-2
                                `)}
                            >

                                {tab.content}

                            </Accordion.Content>

                        </Accordion.Item>
                    );
                })}

            </Accordion.Root>

        </div>
    );
}