import { cn } from "@utils/cn";

type Props = {
    type: "success" | "danger" | "warning" | "info";
    children?: React.ReactNode,
    text?: string,
};

export function Badge({ type, children, text }: Props) {
    return (
        <div className={cn(`text-xs font-medium  tracking-wide px-2 py-1 rounded-full cursor-default`, {
            "text-green-600 bg-green-100": type === "success",
            "text-red-600 bg-red-100": type === "danger",
            "text-yellow-600 bg-yellow-100": type === "warning",
            "text-blue-600 bg-blue-100": type === "info",
        })}>

            {children ?? text}

        </div>
    );
}