import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useImportTestimonialStore } from "@store";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { z } from "zod";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    email: z.string().email(),
    content: z.string().min(1, "Testimonial cannot be empty.")
});

export function TextImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const navigate = useNavigate();

    const onImport = async () => {
        try {

            const newTestimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: newTestimonial.name,
                email: newTestimonial.email,
                content: newTestimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({ [key]: item.message });

                });

            }

            await manualImport.mutateAsync({
                testimonial: newTestimonial,
                formId: activeSpace.formId,
                source: "gozenTestimonial"
            });

            toast.success("Successfully, Imported your testimonial.");

            useImportTestimonialStore.getState().setClear();

            navigate("/testimonials");

        } catch (err) {
            console.log(err);
            toast.error("Something went wrong, Please contact support.");
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import a Text Testimonial"
            onImport={() => onImport()}
        >

            <ManuallyImport
                fields={{
                    testimonial: {
                        text: {
                            content: true,
                            imageAttachments: true
                        },
                    },
                    sourceUrl: null
                }}
            />

        </ImportCard>
    );
}