import { Button, SecondaryButton } from "@components/button";
import { DropdownMenu } from "@components/dropDown";
import { useSocialProofApiClient } from "@hooks";
import domtoimage from "dom-to-image";
import toast from "react-hot-toast";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

export function SocialProofImageTemplateHeader() {

    const { id } = useParams<EditWidgetPageParams>();

    const navigate = useNavigate();

    // const selectedTestimonials = useSocialProofStore((store) => store.selectedTestimonials);

    const socialProof = useSocialProofApiClient();

    const onShare = async (type: "png" | "jpeg" | "clipboard") => {
        try {

            const ele = document.getElementById("preview-template");

            if (!ele) return;

            if (type === "clipboard") {

                const blob = await domtoimage.toBlob(ele);

                const copy = new ClipboardItem({ "image/png": blob });

                window.navigator.clipboard.write([copy]);

                toast.success("Successfully, Copied to Clipboard.");

                return;

            }

            let dataUrl: any;

            if (type === "png") dataUrl = await domtoimage.toPng(ele);

            if (type === "jpeg") dataUrl = await domtoimage.toJpeg(ele);

            if (!dataUrl) return;

            const a = document.createElement("a") as any;

            a.style = "display:none;";

            a.download = "template";

            a.href = dataUrl;

            document.body.appendChild(a);

            a.click();

        } catch (err) {
            console.log(err);
        }
    };

    const onSave = async () => {
        try {

            // const testimonialId = selectedTestimonials[0].id;

            // const ele = document.getElementById("preview-template");

            // if (!ele) return;

            // const base64 = await domtoimage.toPng(ele,);

            // const imageTemplate = useSocialProofStore.getState().imageTemplate;

            // const testimonial = selectedTestimonials[0];

            // if (!testimonial) return;

            // await socialProof.saveImageTemplate.mutateAsync({
            //     background: imageTemplate.background,
            //     previewImage: base64,
            //     branding: imageTemplate.branding,
            //     showProfilePicture: imageTemplate.settings.showProfile,
            //     showRating: imageTemplate.settings.showRating,
            //     templateId: id as string,
            //     testimonialId
            // });

        } catch (err) {
            console.log(err);
        }
    };

    const onHandleSave = () => {
        toast.promise(onSave(), {
            loading: 'Loading...',
            success: () => `Template Saved.`,
            error: 'Error fetching data',
        });
    };

    return (
        <div className="w-full bg-white border-b">

            <div className="flex items-center justify-between w-full bo space-x-4 py-2 px-4">

                <SecondaryButton
                    className="text-sm font-medium w-auto px-3"
                    onClick={() => navigate("/social-proof?tab=images")}
                >

                    <MdArrowBackIos className="w-4 h-4 mr-1" />

                    <p> Back </p>

                </SecondaryButton>

                <div className="flex items-center space-x-4">

                    {/* <SecondaryButton
                        className="mt-0 w-auto text-sm font-medium"
                        onClick={() => { }}
                    >
                        Resize
                    </SecondaryButton> */}

                    <DropdownMenu dir="ltr">

                        <DropdownMenu.Trigger className="outline-none">

                            <Button className="mt-0 w-auto text-sm font-medium">
                                Share
                            </Button>

                        </DropdownMenu.Trigger>

                        <DropdownMenu.Content
                            side="left"
                            sideOffset={5}
                            align="start"
                            className="absolute top-11 left-[-130px] w-[200px]"
                        >

                            <DropdownMenu.Item
                                className="hover:bg-gray-100 flex items-center space-x-2"
                                onClick={() => onShare("png")}
                            >

                                Download As PNG

                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                                className="hover:bg-gray-100 flex items-center space-x-2"
                                onClick={() => onShare("jpeg")}
                            >

                                Download As JPEG

                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                                className="hover:bg-gray-100 flex items-center space-x-2"
                                onClick={() => onShare("clipboard")}
                            >

                                Copy To Clipboard

                            </DropdownMenu.Item>

                            <DropdownMenu.Item
                                className="hover:bg-gray-100 flex items-center space-x-2"
                                onClick={() => onHandleSave()}
                            >
                                Save As Template
                            </DropdownMenu.Item>

                        </DropdownMenu.Content>

                    </DropdownMenu>

                </div>

            </div>

        </div>
    );
}