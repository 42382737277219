import { Badge } from "@components/badge";
import { Button, SecondaryButton } from "@components/button";
import { Loading } from "@components/loading";
import { Text } from "@components/text";
import { QueryKeys, useGetQueryStatus, useTestimonialsApiClient } from "@hooks";
import { useSelectTestimonialsStore } from "@store";
import { cn } from "@utils/cn";
import { Fragment, useEffect, useMemo } from "react";
import { DateRangeFilter } from "./dateFilter";
import { ListTestimonials } from "./list/main.list";
import { OtherFilters } from "./otherFilter";

type Props = {
    className?: string,
    onHandleClear?: () => void;
    onHandleApply?: () => void;
};

export function SelectTestimonials({ className, onHandleClear, onHandleApply }: Props) {

    const { isLoading } = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const { getAll } = useTestimonialsApiClient({});

    const { selectedTestimonialIds, filter } = useSelectTestimonialsStore((store) => ({
        selectedTestimonialIds: store.selectedTestimonialIds,
        filter: store.filter,
    }));

    const isFilterApplied = useMemo(() => {

        const { from, to } = filter.range;

        if (from !== null || to !== null) return true;

        if (filter.sources.length !== 0) return true;

        if (filter.type.length !== 0) return true;

        if (filter.approved !== null || filter.rating !== null) return true;

        return false;

    }, [JSON.stringify(filter)]);

    useEffect(() => { getAll.refetch(); }, []);

    const onClear = () => {

        useSelectTestimonialsStore.getState().setFilter({
            range: { from: null, to: null },
            type: [],
            rating: null,
            sources: [],
        });

        useSelectTestimonialsStore.getState().clearSelectedTestimonialIds();

        onHandleClear?.();

    };

    const onSave = () => {


        onHandleApply?.();

    };

    return (
        <div
            className={cn(`w-full h-full py-4 px-6 hover:bg-gray-100`, className)}
        >

            <div className="flex items-center justify-between">

                <div className="flex items-center space-x-4">

                    <Text
                        text="Select Testimonials"
                        size="lg"
                        className="font-medium"
                    />

                    {selectedTestimonialIds.length === 0 ?
                        <Badge
                            type="info"
                            text="All Testimonials Selected"
                        /> :
                        <Badge
                            type="info"
                            text={`${selectedTestimonialIds.length} Testimonials Selected`}
                        />
                    }

                    {isFilterApplied ?
                        <Badge
                            type="info"
                            text="Filter Applied"
                        /> : null
                    }

                </div>

                <div className="flex items-center space-x-4">

                    <SecondaryButton
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-sm tracking-wider font-medium px-3 py-2 w-auto bg-white hover:bg-white"
                        text="Clear"
                        onClick={() => onClear()}
                    />

                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-sm tracking-wider font-medium px-3 py-2 w-auto"
                        text="Save"
                        onClick={() => onSave()}
                    />

                </div>

            </div>

            {
                getAll.isLoading || getAll.isFetching ?
                    <Loading /> :
                    <Fragment>

                        <div className="flex items-center space-x-4 py-2">

                            <DateRangeFilter refresh={() => getAll.refetch()} />

                            <OtherFilters />

                        </div>

                        <ListTestimonials />

                    </Fragment>
            }

        </div >
    );
}