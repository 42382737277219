import { ImageTemplates } from "./main.template";
import { SelectTestimonials } from "@components/selectTestimonials";
import { useSearchParams } from "react-router-dom";

export function ImageTemplatePreview() {

    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get("tab") ?? "select";

    return (
        <div className="w-full h-full">

            {activeTab === "select" ?
                <SelectTestimonials
                    onHandleApply={() => {
                        searchParams.set("tab", "content");
                        setSearchParams(searchParams);
                    }}
                /> :
                <ImageTemplates />
            }

        </div>
    );
}