import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestInterceptor, onErrorResponseInterceptor } from '@lib/axios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Support } from '@components/support/index.tsx';
import { ErrorBoundary } from 'react-error-boundary';
import { PostHogProvider } from 'posthog-js/react';

const queryClient = new QueryClient();

axios.interceptors.request.use((config: AxiosRequestConfig) => RequestInterceptor(config));

axios.interceptors.response.use((response: AxiosResponse) => response, (error) => onErrorResponseInterceptor(error));

const options = {
    api_host: import.meta.env.PROD ? import.meta.env.VITE_PUBLIC_POSTHOG_HOST : "",
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <PostHogProvider
        apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
        options={options}
    >

        <BrowserRouter>

            {import.meta.env.PROD ?
                <ErrorBoundary
                    fallback={<p> something went wrong!... </p>}
                    onError={(err) => console.log(err)}
                >
                    <QueryClientProvider client={queryClient}>

                        <App />

                        <ReactQueryDevtools />

                    </QueryClientProvider>

                </ErrorBoundary> :
                <QueryClientProvider client={queryClient}>

                    <Support />

                    <App />

                    <ReactQueryDevtools />

                </QueryClientProvider>
            }

        </BrowserRouter>

    </PostHogProvider>

    // </React.StrictMode>,
);
