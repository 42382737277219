import { Config } from "@config";
import { ApiError } from "src/type";

export const RequestInterceptor = (config: any) => {

    const api = Config.Api();

    config.url = api + config.url;

    config.withCredentials = true;

    return config;

};


export const onErrorResponseInterceptor = (error: ApiError) => {

    console.log({ error });

    const isUnauthorizedError = (
        error.response?.status === 401 ||
        error.response?.data.error === "UnauthorizedError" ||
        error.response?.status === 403 ||
        error.response?.data.error === "Forbidden"
    );

    console.log({ isUnauthorizedError });

    if (isUnauthorizedError) {

        window.location.replace("/logout");

    } else {

        return Promise.reject(error);

    }
};