import { Button } from "@components/button";
import { cn } from "@utils/cn";
import { FaCheckCircle } from "react-icons/fa";
import { useOnboardingStore } from "@store";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { QueryKeys, useUsersApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import { LocalStorageUtil } from "@utils/localStorage";

const actions = [
    {
        id: "create",
        label: "I want to collect testimonials"
    },
    {
        id: "import",
        label: "I want to manage all my testimonials in one place"
    },
    {
        id: "importAndShare",
        label: "I want to add testimonials to my website"
    },
];

export function Action() {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { action, businessType, role, website, setAction } = useOnboardingStore();

    const { saveOnBoarding } = useUsersApiClient({});

    const isPending = saveOnBoarding.isPending;

    const onClick = async () => {

        const data = await saveOnBoarding.mutateAsync({ action, businessType, role, website });

        let redirectTo = "/";

        if (action === "create") redirectTo = "/form";
        else if (action === "import") redirectTo = "/import-testimonials";
        else if (action === "importAndShare") redirectTo = "/wall-of-love";
        else redirectTo = "/";

        if (data?.activeSpace) {

            const spacesQuery = queryClient.getQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

            const updatedData: Api.SpacesClient.getAll = {
                activeSpace: saveOnBoarding.data?.activeSpace,
                spaces: spacesQuery?.spaces ?? [],
                sharedSpaces: spacesQuery?.sharedSpaces ?? [],
            };

            queryClient.setQueryData([QueryKeys.spaces.getAll], updatedData);

        }

        LocalStorageUtil.set("onboardingCompleted", "true");

        navigate(redirectTo, { replace: true });

    };

    const onChange = (id: any) => {

        if (isPending) return;

        setAction(id as any);

    };

    return (
        <motion.div
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, type: "spring" }}
        >

            <div>

                <p className="text-xl font-semibold text-left tracking-wide mt-4">
                    Great! What would you like to do first?
                </p>

                <p className="text-gray-600 text-sm font-normal mt-2">
                    We make it easy to collect, manage, and share testimonials. <br />What's your goal today?
                </p>

            </div>

            <div className="mt-4 space-y-4">

                {actions.map(({ id, label }, idx) => (
                    <div
                        key={idx}
                        className={cn(`
                            flex items-center border-2 border-gray-200 p-4 rounded-lg space-x-4 cursor-pointer
                            hover:border-blue-600 hover:text-primary hover:bg-blue-50
                            transition-all duration-200 ease-in-out
                        `, {
                            "border-blue-600 text-primary bg-blue-50": id === action
                        })}
                        style={{
                            transform: id === action ? "scale(1.02)" : "scale(1)"
                        }}
                        onClick={() => onChange(id)}
                    >

                        {id === action ?
                            <FaCheckCircle className="w-5 h-5 text-primary" /> :
                            <div className="w-5 h-5 border-2 border-gray-400 rounded-full" />
                        }

                        <p className="text-sm font-medium tracking-wide ">
                            {label}
                        </p>

                    </div>
                ))}

            </div>

            <Button
                disabled={isPending}
                loading={isPending}
                className="mt-4 text-lg font-medium tracking-wide"
                text="Continue"
                onClick={() => onClick()}
            />

        </motion.div>
    );
}