import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const State: ChromeExtension.State = {
    customerDetails: {},
    source: "gozenTestimonial",
    imageAttachments: [],
    submittedAt: "",
    type: "text",
    rating: null,
};

export const useChromeExtensionStore = create<ChromeExtension.Type>()(devtools(immer((set => ({
    ...State,
    set: (val) => set((store) => {

        store.customerDetails = val.customerDetails;
        store.source = val.source;
        store.submittedAt = val.submittedAt;
        store.type = val.type;
        store.rating = val.rating;
    },
        false,
        "useChromeExtensionStore/set"
    ),
    setAttachments: (attachments) => set((store) => {

        store.imageAttachments = [...attachments];

    },
        false,
        "useChromeExtensionStore/setAttachments"
    )
}))), {
    name: "Chrome Extension - fe",
    enabled: import.meta.env.DEV,
}));