import { ColorPicker } from "@components/colorPicker";
import { FontFamily } from "@components/fontFamily";
import { TextToggle } from "@components/toggle";
import { UploadPicture } from "@components/upload";
import { useWallOfLoveStore } from "@store";
import { useState } from "react";


export function WallOfLove_Branding() {

    const { logo, primaryColor, fontColor, buttonColor, fontFamily } = useWallOfLoveStore((store) => ({
        logo: store.settings.branding.logo,
        primaryColor: store.settings.branding.primaryColor,
        fontColor: store.settings.branding.fontColor,
        buttonColor: store.settings.branding.buttonColor,
        fontFamily: store.settings.branding.fontFamily
    }));

    const [file, setFile] = useState(null);

    const [branding, setBranding] = useState(false);

    return (
        <div className="space-y-4">

            <UploadPicture
                id="wall-of-love-branding"
                file={file}
                title="Logo"
                titleClassName="text-sm font-medium text-gray-700"
                imgClassName="w-[50px] h-[50px]"
                onChange={() => setFile(null)}
            />

            <ColorPicker
                defaultColor={primaryColor}
                label="Primary Color"
                onChange={(val) => useWallOfLoveStore.getState().setWolTemplateBranding({
                    primaryColor: val
                })}
            />

            <ColorPicker
                defaultColor={fontColor}
                label="Font Color"
                onChange={(val) => useWallOfLoveStore.getState().setWolTemplateBranding({
                    fontColor: val
                })}
            />

            <ColorPicker
                defaultColor={buttonColor}
                label="Button Color"
                onChange={(val) => useWallOfLoveStore.getState().setWolTemplateBranding({
                    buttonColor: val
                })}
            />

            <FontFamily
                defaultFontFamily={fontFamily}
                onChange={(font) => useWallOfLoveStore.getState().setWolTemplateBranding({
                    fontFamily: font
                })}
            />

            <TextToggle
                isActive={branding}
                name="Remove Branding"
                onClick={() => setBranding(!branding)}
            />

        </div>
    );
}