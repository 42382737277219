import { useSearchParams } from "react-router-dom";
import { Widget1_Settings } from "./w1";
import { Widget2_Settings } from "./w2";
import { Widget3_Settings } from "./w3";
import { Widget4_Settings } from "./w4";
import { Widget5_Settings } from "./w5";
import { Widget7_Settings } from "./w7";
import { Widget8_Settings } from "./w8";

export function WidgetSettings() {

    const [searchParams] = useSearchParams();

    const templateId = searchParams.get("templateId") ?? "";

    return (
        <div className="w-[30%] h-full max-h-full overflow-y-scroll bg-white border-r">

            <div className="border-b px-4 py-3 bg-gray-100">

                <p className="text-base font-medium tracking-wide">
                    Design
                </p>

            </div>

            <div className="p-4 ">

                {templateId === "w1" ? <Widget1_Settings /> : null}

                {templateId === "w2" ? <Widget2_Settings /> : null}

                {templateId === "w3" ? <Widget3_Settings /> : null}

                {templateId === "w4" ? <Widget4_Settings /> : null}

                {templateId === "w5" ? <Widget5_Settings /> : null}

                {templateId === "w7" ? <Widget7_Settings /> : null}

                {templateId === "w8" ? <Widget8_Settings /> : null}

            </div>

        </div>
    );
}