import { MdAddCircle } from "react-icons/md";
import { Text } from "@components/text";
import { useCommonStore, useUserStore } from "@store";
import { Button } from "@components/button";
import { MemberPopup } from "./member.popup";
import { useTeamsApiClient } from "@hooks";
import { useEffect } from "react";
import { MemberCard } from "./member.card";
import { EmptyIcon } from "@components/icons";
import { cn } from "@utils/cn";
import { UpgradePopup } from "@components/upgradePopup";


export function TeamsPage() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const { getAll } = useTeamsApiClient({});

    const getAllMembersQuery = (getAll.data as Api.Teams.GetAllMembers) ?? [];

    const noMembers = getAllMembersQuery && getAllMembersQuery.members && !getAllMembersQuery.members.length;

    useEffect(() => { if (!getAll.data) getAll.refetch(); }, []);

    const onClick = () => {

        const { used, limit } = useUserStore.getState().billing;

        if (used.member + 1 >= limit.member) {
            useCommonStore.getState().setActivePopup("upgrade-plan");
            return;
        }

        useCommonStore.getState().setActivePopup("add-member");

    };

    return (
        <div className="px-6 py-6 bg-gray-50 h-[90%] max-h-[90%]">

            <div className={cn(`flex items-center justify-between px-3 border-b pb-4`, {
                "border-none": noMembers
            })}>

                <Text
                    variant="primary"
                    className="text-xl font-semibold tracking-wide"
                >
                    Teams
                </Text>

                {getAllMembersQuery && getAllMembersQuery.members && getAllMembersQuery.members.length ?
                    <Button
                        className="w-auto text-sm font-medium tracking-wide"
                        onClick={() => onClick()}
                    >

                        <MdAddCircle className="mr-2 w-4 h-4" />

                        <p>
                            Invite Team Member
                        </p>

                    </Button> : null
                }

            </div>

            {getAllMembersQuery.members && getAllMembersQuery.members.length ?
                getAllMembersQuery.members.map((member, idx) => (<MemberCard key={idx} {...member} />)) :
                <div className="w-full h-full flex flex-col items-center justify-center">

                    <EmptyIcon />

                    <p className="text-base font-medium tracking-wide mt-4">
                        It fun with people, Let's invite your team members.
                    </p>

                    <Button
                        className="w-auto font-medium text-base mt-4"
                        onClick={() => onClick()}
                    >
                        Invite our first member
                    </Button>

                </div>
            }

            {activePopup === "add-member" ? <MemberPopup type="add" /> : null}

            {activePopup === "upgrade-plan" ? <UpgradePopup /> : null}

        </div>
    );
}