import { Config } from "@config";

export const State: Form.State = {
    name: "",
    design: {
        fontFamily: "Inter",
        logo: Config.misc.placeHolderLogo,
        alignment: "left",
        fontColor: "#000",
        primaryColor: "#2563EB",
        buttonFontColor: "#fff",
        cardColor: "#fff",
        background: {
            color: "#eeeeee"
        },
        enableRTL: false
    },
    welcome: {
        allowedTestimonialTypes: ["text", "video", "audio"],
        introVideo: null,
        title: `<p>Share a testimonial!</p>`,
        description: `<p>Do you love using our product? We'd love to hear about it!</p><p><br></p><p>  	
        - Share your experience with a quick video or text testimonial</p><p>    
        - Recording a video? Don't forget to smile 😊</p>`,
    },
    response: {
        audio: {
            title: "<p> Record a audio testimonial </p>",
            description: "<p> What do you like most about us? </p> <br/> <p> Would you recommend us to a friend? </p>",
            collectAttachments: false,
        },
        text: {
            title: "Write a Testimonial",
            description: "<p> What do you like most about us? </p> <br/> <p> Would you recommend us to a friend? </p>",
            collectAttachments: false
        },
        video: {
            title: "<p> Record a video testimonial </p>",
            description: "<p> What do you like most about us? </p> <br/> <p> Would you recommend us to a friend? </p>",
        },
        collectRating: true
    },
    customDetails: {
        title: "<p> Almost done 🙌 </p>",
        askConsent: true,
        fields: {
            name: { collect: true, required: true },
            email: { collect: true, required: true },
            company: { collect: false, required: false },
            companyLogo: { collect: false, required: false },
            job: { collect: false, required: true },
            photo: { collect: false, required: true },
            role: { collect: false, required: true },
            website: { collect: false, required: true },
            companyUrl: { collect: false, required: false, }
        },
        customFields: []
    },
    thankYou: {
        allowSharing: false,
        cta: {
            text: "",
            url: ""
        },
        customPage: "",
        thankyouVideo: null,
        title: "Thank you 🙏",
        message: "<p> Thank you so much for your support! We appreciate your support and we hope you enjoy using our product. </p>",
    },
    reward: {
        allow: false,
        buttonText: "Claim your reward",
        message: "Send a video testimonial for a chance to spin and win gifts",
        onlyReward: ["video"],
        color1: "#79a3ff",
        color2: "#1a5eed",
        fontColor: "#eeeeee",
        segments: [],
        page: {
            button: "Spin the wheel",
            message: "<p> Spin the wheel and stand a chance to win one of the amazing prizes. </p>",
            title: "<p> Spin the wheel </p>"
        }
    },
    settings: {
        applyWatermarkOnVideo: false,
        autoApproveTestimonials: false,
        autoTranscribeAudioTestimonials: false,
        autoTranscribeVideoTestimonials: false,
        disableVideoRecordingForIphone: false,
        maxRecordDuration: 60,
        preferLandscapeRecordingOnMobile: false,
        submissions: {
            allowMultipleSubsBrowser: false,
            close: false,
            limit: 0
        },
        removeBranding: false,
    },
    customLabels: null,
    tags: [],
    id: "",
    version: 0,
};