import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore } from "@store";


export function EditTestimonialContent() {

    const { showProfile, showRating, customerName } = useSocialProofStore((store) => ({
        showProfile: store.imageTemplate.settings.showProfile,
        showRating: store.imageTemplate.settings.showRating,
        customerName: store.imageTemplate.content.customerName
    }));

    return (
        <div className="space-y-4 p-2">

            <Input
                label="Customer Name"
                value={customerName}
                onChange={(e) => useSocialProofStore.getState().setImageTemplateContent({
                    customerName: e.target.value
                })}
            />

            <TextToggle
                isActive={showProfile}
                name="Show Profile Picture"
                onClick={() => {

                    useSocialProofStore.getState().setImageTemplateSettings({
                        showProfile: !showProfile
                    });

                }}
            />

            <TextToggle
                isActive={showRating}
                name="Show Rating"
                onClick={() => useSocialProofStore.getState().setImageTemplateSettings({
                    showRating: !showRating
                })}
            />

        </div>
    );
}