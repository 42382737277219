import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { CgSpinner } from 'react-icons/cg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "danger" | "outline",
    size?: "sm" | "md" | "lg" | "xl";
    loading?: boolean,
    text?: string,
    children?: ReactNode;
}


export function Button({
    variant = "primary",
    size = "md",
    children,
    text,
    className,
    loading = false,
    disabled = false,
    ...props
}: Props) {
    return (
        <button
            {...props}
            disabled={loading}
            className={cn(`
                w-full bg-blue-600 rounded-md px-4 py-2 font-medium text-base text-white opacity-100 relative
                flex items-center justify-center text-center
                transition-all duration-300 ease-in-out 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300 
            `, {
                "hover:ring-2 hover:ring-offset-1 hover:ring-blue-400 hover:bg-blue-700": !loading && !disabled,
                "cursor-not-allowed": loading || disabled,
                "hover:ring-red-400 hover:bg-red-700 bg-red-600 text-white": variant === "danger",
                "bg-blue-50 border border-primary text-primary hover:text-white": variant === "outline",
            }, className)}
        >

            {loading ? <CgSpinner className="w-6 h-6 animate-spin absolute" /> : null}

            <span className={cn("visible flex items-center justify-center", { "invisible": loading })}>
                {text}
                {children}
            </span>

        </button>
    );
}