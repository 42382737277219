import lib from 'google-libphonenumber';


export function IsValidPhoneNumber(newPhoneNumber: string) {
    try {

        const phoneUtil = lib.PhoneNumberUtil.getInstance();

        const number = phoneUtil.parse(`+${newPhoneNumber}`);

        const countryCode = phoneUtil.getRegionCodeForNumber(number);

        if (!countryCode) return false;

        return phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(newPhoneNumber, countryCode.toUpperCase()),
            countryCode.toUpperCase()
        );

    } catch (err) {
        console.log(err);
        return false;
    }
}