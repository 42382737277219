import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { useAuthApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";


export function ChangeEmailPopup() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState("");

    const { changeEmail } = useAuthApiClient({});


    const onChange = async () => {
        try {

            const isEmail = z.string().email();

            const isValid = isEmail.safeParse(email);

            if (isValid.success === false) {
                setError("Enter a valid email.");
                return;
            }

            await changeEmail.mutateAsync(email);

            toast.success("We have send email. Please verify to conform your new email, \n you will be logged out now.", { duration: 5 * 1000 });

            setTimeout(() => navigate("/logout"), 5000);

        } catch (err: any) {
            toast.error(err.message);
        }

    };

    return (
        <Popup
            width="35%"
            name="Change Your Email"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <p className="text-sm font-normal text-gray-700 mt-2">
                We’ll send an email to the new address for you to confirm. Your current email is valid until then.
            </p>

            <div className="mt-4">

                <Input
                    type="email"
                    placeholder="test@gmail.com"
                    label="Your new email"
                    required={true}
                    value={email}
                    error={error}
                    onChange={(e) => setEmail(e.target.value)}
                />


                <div className="flex items-center justify-end space-x-2 mt-4">

                    <SecondaryButton
                        disabled={changeEmail.isPending}
                        className="w-auto text-sm font-medium"
                        onClick={() => useCommonStore.getState().setActivePopup("")}
                    >
                        Cancel
                    </SecondaryButton>

                    <Button
                        disabled={changeEmail.isPending}
                        loading={changeEmail.isPending}
                        className="w-auto text-sm font-medium"
                        onClick={() => onChange()}
                    >
                        Change
                    </Button>

                </div>

            </div>


        </Popup>
    );
}