import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header } from "./header/header.main";
import { NavBar } from "./navbar/navbar.main";
import { Toaster } from "react-hot-toast";
import {
    useAuthApiClient, useDetectPageReload, useFormApiClient,
    useSpacesApiClient, useUsersApiClient
} from "@hooks";
import { useUserStore } from "@store";
import { Fragment, useEffect, useState } from "react";
import { Loading } from "@components/loading";
import { LocalStorageUtil } from "@utils/localStorage";

export function DashboardLayout() {

    const navigate = useNavigate();

    const location = useLocation();

    const formApi = useFormApiClient({});
    const { getProfile } = useUsersApiClient({});
    const { verifyToken } = useAuthApiClient({});
    const { getDetails, getAll } = useSpacesApiClient({});

    const { hasReloaded } = useDetectPageReload();

    const [loading, setLoading] = useState(true);

    const isAuthenticated = LocalStorageUtil.get("auth") == "true" ? true : false;

    useEffect(() => { onLoad(); }, [location.pathname]);

    useEffect(() => {

        if (isAuthenticated === false) {
            navigate("/login", { replace: true });
        }

    }, [isAuthenticated]);

    useEffect(() => setLoading(getDetails.isFetching), [getDetails.isFetching]);

    useEffect(() => { verifyToken.refetch(); }, [hasReloaded]);

    useEffect(() => {

        if (
            isAuthenticated &&
            getProfile.isPending === false &&
            !getProfile.data?.onboardingCompleted
        ) {
            navigate("/onboarding?screen=website", { replace: true });
        }

    }, [getProfile.isPending]);

    const onLoad = async () => {

        const isNotOnboardingScreen = location.pathname !== "/onboarding";

        const completedOnboarding = (LocalStorageUtil.get<string>("onboardingCompleted") ?? "false") == "true" ? true : false;

        if (isAuthenticated && isNotOnboardingScreen && completedOnboarding === false) {
            setLoading(false);
            navigate("/onboarding?screen=website", { replace: true });
            return;
        }

        const notFetched = getDetails.isFetched === false && getAll.isFetched === false && formApi.get.isFetched === false;

        if (notFetched && isNotOnboardingScreen) {

            const [user] = await Promise.all([
                getProfile.mutateAsync(),
                getDetails.refetch(),
                getAll.refetch(),
                formApi.get.refetch()
            ]);

            useUserStore.getState().setUser({ ...user });

            setLoading(false);

        }

    };

    const showNavbar = location.pathname !== "/spaces";

    // if (!onboardingCompleted) {
    //     <Navigate to={"/onboarding?screen=website"} />;
    // }

    if (location.pathname === "/onboarding") return <Outlet />;

    return (
        <section className="w-screen h-screen overflow-hidden ">

            <Toaster />

            <Header />

            <section className="xl:h-[95%] md:h-[92%] w-full max-w-full bg-gray-50 flex items-center">

                {loading ? <Loading /> :
                    <Fragment>

                        {showNavbar ? <NavBar isLoading={loading} /> : null}

                        <main className="h-full flex-1 max-h-full bg-transparent overflow-auto ">

                            <Outlet />

                        </main>

                    </Fragment>
                }

            </section>

        </section>
    );
}