/* widget */
import hero from "@assets/socialProofPreview/widgets/hero.webp";
import highLights from "@assets/socialProofPreview/widgets/highlights.webp";
import list from "@assets/socialProofPreview/widgets/list.webp";
import logos from "@assets/socialProofPreview/widgets/logos.webp";
import rating from "@assets/socialProofPreview/widgets/rating.webp";
import profileCarousal from "@assets/socialProofPreview/widgets/profile-carousel.webp";
import profileGrid from "@assets/socialProofPreview/widgets/profileCard.webp";
import richSnippet from "@assets/socialProofPreview/widgets/rich-snippets.png";
import { Config } from "@config";
import { useCommonStore } from "@store";
import { useSearchParams } from "react-router-dom";
import { PreviewCard } from "../socialProof/previewCard";
import { CreateWidgetPopup } from "./create";
import { cn } from "@utils/cn";

export const WidgetTemplates: WidgetType[] = [
    {
        templateId: "w1",
        type: "widget",
        name: "rating",
        previewImage: rating,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w1"
    },
    {
        templateId: "w2",
        type: "widget",
        name: "hero",
        previewImage: hero,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w2"

    },
    {
        templateId: "w3",
        type: "widget",
        name: "highlights",
        previewImage: highLights,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w3"
    },
    {
        templateId: "w4",
        type: "widget",
        name: "list",
        previewImage: list,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w4"
    },
    {
        templateId: "w5",
        type: "widget",
        name: "logos",
        previewImage: logos,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w5"
    },
    // {
    //     templateId: "w6",
    //     type: "widget",
    //     name: "popups",
    //     previewImage: popups,
    //     previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w6"

    // },
    {
        templateId: "w7",
        type: "widget",
        name: "profileCarousal",
        previewImage: profileCarousal,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w7"
    },
    {
        templateId: "w8",
        type: "widget",
        name: "profileGrid",
        previewImage: profileGrid,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w8"
    },
    {
        templateId: "w9",
        type: "widget",
        name: "richSnippets",
        previewImage: richSnippet,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w9"
    },
];

export function ListWidgets() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = async (id: string) => {

        useCommonStore.getState().setActivePopup("get-widget-embed-code");

        searchParams.set("id", id);

        setSearchParams(searchParams.toString());

    };

    return (
        <div className="my-4 w-full">

            <div className="flex items-center gap-6 flex-wrap w-full">

                {WidgetTemplates.map((item, idx) => (
                    <div
                        key={item.templateId}
                        className={cn(`
                            border border-gray-300 bg-gray-100 rounded-md 
                            w-[260px] h-[240px] relative 
                            flex items-center justify-center
                            cursor-pointer group shadow-lg overflow-hidden
                        `)}
                        onClick={() => onClick(item.templateId)}
                    >

                        <img
                            src={item.previewImage}
                            className={cn(`
                                group-hover:scale-[1.03] w-full rounded-lg
                                transition-all duration-300 ease-in-out 
                            `)}
                        />

                    </div>
                ))}

            </div>

            {activePopup === "get-widget-embed-code" ? <CreateWidgetPopup /> : null}

        </div>
    );
}