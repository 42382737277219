import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { Config } from "@config";
import { useCommonStore } from "@store";
import { useRef } from "react";
import toast from "react-hot-toast";
import { MdContentCopy } from "react-icons/md";
import { useParams } from "react-router-dom";

export function GetWallOfLoveEmbedCodePopup() {

    const { id } = useParams();

    const ref = useRef<HTMLDivElement>(null);

    const onClose = () => useCommonStore.getState().setActivePopup("");

    return (
        <Popup
            width="30%"
            name="Get Embed Code"
            onClose={onClose}
        >

            <div className="relative">

                <p className="text-sm font-normal mt-3 mb-3">
                    Copy the link
                </p>

                <Input
                    disabled
                    value={`${Config.RenderEngineUrl()}/wall-of-love/${id}`}
                    className="w-full "
                />

                <div
                    className="absolute top-12 right-2 cursor-pointer bg-white"
                    onClick={() => {
                        window.navigator.clipboard.writeText(`${Config.RenderEngineUrl()}/wall-of-love/${id}`);
                        toast.success("Copied!...");
                        onClose();
                    }}
                >

                    <MdContentCopy />

                </div>

            </div>

            <div className="">

                <p className="text-sm font-normal mt-3 mb-3">
                    Paste this code in your html, for this widget to appear in your website.
                </p>


                <div
                    ref={ref}
                    className="bg-black rounded-md max-w-full overflow-hidden p-4 text-white text-sm font-normal"
                >

                    {`<script src="${Config.WallOfLoveEmbedScript()}" type="text/javascript" async></script>
                <div class="gz-testimonial-wol-embed" data-id="${id}"></div>`}

                </div>

                <div className="mt-5 flex items-center justify-end space-x-2">

                    <SecondaryButton
                        className="w-auto text-sm font-medium"
                        onClick={() => onClose()}
                        text="Cancel"
                    />

                    <Button
                        className="w-auto text-sm font-medium"
                        text={"Copy code"}
                        onClick={() => {

                            if (ref.current) {
                                window.navigator.clipboard.writeText(ref.current.innerText);
                                toast.success("Copied!...");
                                onClose();
                            }

                        }}
                    />

                </div>

            </div>


        </Popup >
    );
}