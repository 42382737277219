import { AiOutlineSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { Menu } from "@components/Menu";
import { useUserStore } from "@store";


export function ProfileDropDown() {

    const navigate = useNavigate();

    const { avatarUrl, name, email } = useUserStore();

    return (
        <Menu className="w-[240px]">

            <div className="flex items-center px-1 py-2 space-x-2">

                <div className="flex items-center text-gray-800">
                    <img
                        className="flex-shrink-0 object-cover object-center w-10 h-10 border rounded-full cursor-pointer"
                        src={avatarUrl}
                    />
                </div>

                <div className='w-[70%] max-w-[70%]'>

                    <p className="mb-1 text-base font-medium tracking-wide">
                        {name.length > 15 ? `${name.slice(0, 15)}...` : name}
                    </p>

                    <p className="text-xs font-normal tracking-wide">
                        {email.length > 20 ? `${email.slice(0, 20)}...` : email}
                    </p>

                </div>

            </div>

            <hr className="mt-1" />

            <div className="mt-1.5 flex flex-col w-full">

                <Menu.Item
                    icon={<AiOutlineSetting className="w-6 h-6 text-gray-600 group-hover:text-primary" />}
                    text="Settings"
                    onClick={() => navigate("/settings")}
                />

                <Menu.Item
                    icon={<FiLogOut className="w-5 h-5 text-gray-700 group-hover:text-primary" />}
                    text="Logout"
                    onClick={() => navigate("/logout")}
                />


            </div>

        </Menu>
    );
}