import { useSocialProofStore } from "@store";
import { VideoPreviewTemplates } from "./templates";
import { SelectTestimonials } from "@components/selectTestimonials";

export function VideoTemplatePreview() {

    return (
        <div className="h-full w-full max-h-full overflow-y-scroll">

            {/* {activeTab === "selectTestimonials" ?
                <SelectTestimonials
                    onHandleClear={() => { }}
                    onHandleApply={() => { }}
                /> : null
            }

            {activeTab === "template" ? <VideoPreviewTemplates /> : null} */}

        </div>
    );
}