import * as Accordion from '@radix-ui/react-accordion';
import { cn } from '@utils/cn';
import { FaAngleDown } from 'react-icons/fa';
import { Message } from './message';
import { Advance } from './advance';
import { BiMessageAltDetail } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineDesignServices } from "react-icons/md";
import { Design } from './design';

export function TemplateSettings() {

    const Tabs = [
        {
            title: "Message",
            icon: <BiMessageAltDetail className='w-6 h-6 text-blue-600' />,
            content: (<Message />)
        },
        {
            title: "Design",
            icon: <MdOutlineDesignServices className='w-6 h-6 text-yellow-500' />,
            content: (<Design />)
        },
        {
            title: "Advance",
            icon: <IoMdSettings className='w-6 h-6 text-gray-800' />,
            content: (<Advance />)
        },
    ];

    return (
        <div className='w-[45%] pr-4 h-auto'>

            <div className='bg-white h-full my-4 mx-2 rounded-xl shadow-md border'>

                <Accordion.Root
                    type="single"
                    defaultValue="Message"
                    collapsible
                >

                    {Tabs.map((tab, idx) => {
                        return (
                            <Accordion.Item
                                key={idx}
                                value={tab.title}
                                className={cn({
                                    "border-y": idx > 0 && idx < 2,
                                })}
                            >

                                <Accordion.Header
                                    className={cn(`p-4 `)}
                                >

                                    <Accordion.Trigger
                                        className="flex items-center justify-between w-full"
                                    >

                                        <div className='flex items-center space-x-2'>

                                            {tab.icon}

                                            <p className="font-medium text-base tracking-wide">
                                                {tab.title}
                                            </p>

                                        </div>


                                        <FaAngleDown
                                            className={cn(`
                                            ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform 
                                            duration-300 group-data-[state=open]:rotate-180 text-gray-700
                                        `)}
                                        />

                                    </Accordion.Trigger>

                                </Accordion.Header>

                                <Accordion.Content
                                    className={cn(`
                                        data-[state=open]:border-t
                                        data-[state=open]:animate-slideDown 
                                        data-[state=closed]:animate-slideUp 
                                        overflow-hidden p-4
                                    `)}
                                >

                                    {tab.content}

                                </Accordion.Content>

                            </Accordion.Item>
                        );
                    })}

                </Accordion.Root>

            </div>

        </div>
    );
}