import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { State } from "./state";


export const useWallOfLoveStore = create<WolStore.Type>()(devtools(immer(set => ({
    ...State,
    setSettings: (settings) => set((state) => {

        state.settings = { ...state.settings, ...settings };

    },
        false,
        "wallOfLove/setSettings"
    ),
    setShowSelectTestimonials: (show) => set((state) => {

        state.showSelectTestimonials = show;

    },
        false,
        "wallOfLove/setShowSelectTestimonials"
    ),
    setWolTemplateBranding: (branding) => set((state) => {

        state.settings.branding = { ...state.settings.branding, ...branding };

    },
        false,
        "testimonials/setWolTemplateBranding"
    ),
    setWolTemplateContent: (content) => set((state) => {

        state.settings.content = { ...state.settings.content, ...content };

    },
        false,
        "testimonials/setWolTemplateContent"
    ),
    setWolTemplateHero: (hero) => set((state) => {

        state.settings.hero = { ...state.settings.hero, ...hero };

    },
        false,
        "testimonials/setWolTemplateHero"
    ),
    setWolTemplateNavigation: (navigation) => set((state) => {

        state.settings.navigation = { ...state.settings.navigation, ...navigation };

    },
        false,
        "testimonials/setWolTemplateNavigation"
    ),
}))));
