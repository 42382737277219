
export const State: WidgetsStore.State = {
    selectTestimonials: false,
    widget1: {
        backgroundColor: "white",
        borderColor: "transparent",
        borderWidth: "1px",
        content: "from 150 testimonials",
        fontSize: "1.2rem",
        height: "6.2rem",
        image: "https://app.testimonial.gozen.io/logo.svg",
        ratingCounterBgColor: "black",
        ratingCounterTextColor: "white",
        stars: 3.4,
        textColor: "black",
        width: "24rem",
    },
    widget2: {
        "width": "28rem",
        "height": "22rem",
        "borderColor": "transparent",
        "borderWidth": "1px",
        "padding": "0px",
        "textAlign": "center",
        "fontSize": "1.2rem",
        "customCSS": "",
        "backgroundColor": "white",
        "textColor": "black",
        // testimonials: [
        //     {
        //         image: "https://ui-avatars.com/api/?background=F6F6F6&color=12344D&name=J",
        //         content: "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
        //         stars: 5,
        //         author: "Jhon",
        //         subtitle: "CEO of Pied Piper"
        //     },
        //     {
        //         image: "https://ui-avatars.com/api/?background=F6F6F6&color=12344D&name=D",
        //         content: "Another testimonial text.Another testimonial text.Another testimonial text.Another testimonial text...",
        //         stars: 4,
        //         author: "Doe",
        //         subtitle: "CTO of XYZ"
        //     }
        // ],
        "authorColor": "black",
        "authorSubTitleColor": "#272727",
        "testimonialIds": []
    },
    widget3: {
        "borderColor": "transparent",
        "borderWidth": "1px",
        "fontSize": "1.2rem",
        "customCSS": "",
        "backgroundColor": "white",
        "textColor": "black",
        // testimonials: [
        //     {
        //         image: "https://ui-avatars.com/api/?background=F6F6F6&color=12344D&name=J",
        //         content: "Lorem ipsum dolor sit amet consectetur adipisicing elit...",
        //     },
        //     {
        //         image: "https://ui-avatars.com/api/?background=F6F6F6&color=12344D&name=D",
        //         content: "Another testimonial text.Another testimonial text.",
        //     }
        // ],
        "testimonialIds": []
    },
    widget4: {
        "customCSS": "",
        "fontSize": "1.2rem",
        "backgroundColor": "white",
        "textColor": "black",
        "quotationBgColor": "#BFDBFE",
        "quotationInnerColor": "#2563eb",
        // testimonials: [
        //     {
        //         image: "https://images.pexels.com/photos/4855373/pexels-photo-4855373.jpeg?auto=compress&cs=tinysrgb&w=600",
        //         content: "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
        //         author: "Jenifer",
        //         subtitle: "CEO of Pied Piper"
        //     },
        //     {
        //         image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=600",
        //         content: "Another testimonial text.Another testimonial text.Another testimonial text.Another testimonial text...",
        //         author: "David",
        //         subtitle: "CTO of XYZ"
        //     }

        // ],
        "testimonialIds": []
    },
    widget5: {
        "width": "fit-content",
        "height": "8rem",
        "borderColor": "transparent",
        "borderWidth": "1px",
        "logoImages": ["https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Tesla_logo.png/120px-Tesla_logo.png", "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Tesla_logo.png/120px-Tesla_logo.png", "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Tesla_logo.png/120px-Tesla_logo.png"],
        "backgroundColor": "white",
        "customCSS": "",
        "testimonialIds": []
    },
    widget7: {
        "customCSS": "",
        "fontSize": "1.2rem",
        "backgroundColor": "white",
        "textColor": "black",
        "quotationBgColor": "#BFDBFE",
        "quotationInnerColor": "#2563eb",
        "image": "https://images.pexels.com/photos/4855373/pexels-photo-4855373.jpeg?auto=compress&cs=tinysrgb&w=600",
        "content": "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
        "author": "Jenifer",
        "subtitle": "CEO of Pied Piper",
        "testimonialIds": []
    },
    widget8: {
        "height": "20rem",
        "customCSS": "",
        "backgroundColor": "white",
        "front_textColor": "white",
        "back_textColor": "#525252",
        "transition": "0.5s",
        "starColor": "white",
        "darkStarColor": "rgb(161, 161, 161)",
        "testimonials": [
            {
                "image": "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg",
                "content": "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
                "stars": 5,
                "author": "Jhon",
                "subtitle": "CEO of Pen"
            },
            {
                "image": "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=600",
                "content": "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
                "stars": 5,
                "author": "David",
                "subtitle": "CEO at Software"
            },
            {
                "image": "https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=600",
                "content": "Lorem ipsum dolor sit amet consectetur adipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...ipisicing elit...",
                "stars": 5,
                "author": "Anna",
                "subtitle": "Founder of Fun"
            },
        ],
    },
    saving: false
};