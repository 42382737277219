import { Loading } from "@components/loading";
import { useAuthApiClient } from "@hooks";
import { Fragment, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { LocalStorageUtil } from "@utils/localStorage";
import toast, { Toaster } from "react-hot-toast";


export function VerifyEmailPage() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const token = searchParams.get("token") ?? null;
    const changeEmail = (searchParams.get("change") ?? "false") == "true" ? true : false;

    const { verifyEmail, changeEmailVerify } = useAuthApiClient({});

    useEffect(() => {

        if (token && changeEmail === false) onVerifyToken(token);

        if (token && changeEmail) onVerifyChangeEmailToken(token);

    }, []);

    const onVerifyToken = async (token: string) => {
        try {

            const body = await verifyEmail.mutateAsync(token);

            if (body && body.redirect) {
                navigate("/login", { replace: true });
                return;
            }

            LocalStorageUtil.set("onboardingCompleted", "false");

            LocalStorageUtil.set("auth", "true");

            toast.success("Successfully, verified your email.");

            navigate("/onboarding?screen=website", { replace: true });

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    const onVerifyChangeEmailToken = async (token: string) => {
        try {

            await changeEmailVerify.mutateAsync(token);

            toast.success("Successfully, verified your email.");

            navigate("/login", { replace: true });

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    if (token === null) {
        navigate("/login");
        return;
    }

    return (
        <div className="w-[66%] h-full flex flex-col items-center justify-center">

            <Toaster />

            {verifyEmail.isPending || changeEmailVerify.isPending ?
                <Loading text="Verifying your email..." /> :
                <Fragment>

                    <div className="p-2 w-[50%] flex flex-col items-start ">

                        <p className="mb-5 text-xl font-medium text-center">
                            Verify Email
                        </p>

                        {verifyEmail.error ?
                            <div className="flex items-center justify-center w-full py-3 mt-3 bg-red-300 border border-red-600 rounded-lg shadow">

                                <div className="w-full ml-3 text-sm font-normal text-center">
                                    {verifyEmail.error.message ?? ""}
                                </div>

                                <button
                                    className="ml-auto -mx-1.5 -my-1 mr-2 hover:text-gray-900 rounded-lg p-1.5 inline-flex h-8 w-8 dark:text-gray-500"
                                >

                                    <p className="sr-only">Close</p>

                                    <MdClose className="" />

                                </button>

                            </div> : null
                        }


                    </div>

                    <p
                        className="py-2 mt-3 font-normal text-center text-md"
                        onClick={() => navigate("/login", { replace: true })}
                    >
                        Back to <u className="cursor-pointer text-primary"> Login </u>
                    </p>

                </Fragment>
            }

        </div>
    );
}