import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useWallOfLoveStore } from "@store";
import { cn } from "@utils/cn";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

export function WallOfLove_HeroSection() {

    const { title, subTitle, cta } = useWallOfLoveStore((store) => ({
        title: store.settings.hero.title,
        subTitle: store.settings.hero.subTitle,
        cta: store.settings.hero.cta
    }));

    return (
        <div className="space-y-3">

            <Input
                label="Title"
                value={title}
                onChange={(e) => useWallOfLoveStore.getState().setWolTemplateHero({ title: e.target.value, })}
            />

            <Input
                label="Sub Title"
                value={subTitle}
                onChange={(e) => useWallOfLoveStore.getState().setWolTemplateHero({ subTitle: e.target.value, })}
            />

            <TextToggle
                isActive={cta ? true : false}
                name="Enable CTA"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateHero({
                    cta: cta ? null : { title: "", url: "" }
                })}
            >

                <FiInfo
                    className={cn(`text-gray-600`)}
                    id={`allow-sharing-tooltip`}
                />

                <Tooltip
                    anchorSelect={`#allow-sharing-tooltip`}
                    place="top"
                    style={{
                        width: 340,
                        textAlign: "left"
                    }}
                >
                    Add a call to action block that shows up as people scroll down your Wall of Love.
                    This needs 4 testimonials or more to work.
                </Tooltip>

            </TextToggle>

            {cta ?
                <div className="space-y-2 my-2">

                    <Input
                        label="CTA Title"
                        placeholder="Button Text"
                        value={cta?.title ?? ""}
                        onChange={(e) => useWallOfLoveStore.getState().setWolTemplateHero({
                            cta: {
                                ...cta,
                                title: e.target.value
                            }
                        })}
                    />

                    <Input
                        label="CTA Url"
                        placeholder="Button Url"
                        value={cta?.url ?? ""}
                        onChange={(e) => useWallOfLoveStore.getState().setWolTemplateHero({
                            cta: {
                                ...cta,
                                url: e.target.value
                            }
                        })}
                    />

                </div> : null
            }



        </div>
    );
}