import { TextToggle } from "@components/toggle";
import { useCommonStore, useSocialProofStore, useUserStore } from "@store";

export function ImageTemplateBranding() {

    const { remove } = useSocialProofStore((store) => ({
        remove: store.imageTemplate.branding.remove
    }));

    const onClick = () => {

        const { removeLoadingBranding, } = useUserStore.getState().billing;

        if (removeLoadingBranding === false) {
            useCommonStore.getState().setActivePopup("upgrade-plan");
            return;
        } else {
            useSocialProofStore.getState().setImageTemplateBranding({ remove: !remove });
        }

    };


    return (
        <div className="p-2 space-y-3">

            <TextToggle
                isActive={remove}
                name="Remove Branding"
                onClick={() => onClick()}
            />

        </div>
    );
}