


/* photo */
import photoPreview1 from "@assets/socialProofPreview/images/t1.png";
import photoPreview2 from "@assets/socialProofPreview/images/t2.png";
import photoPreview3 from "@assets/socialProofPreview/images/t3.png";
import photoPreview4 from "@assets/socialProofPreview/images/t4.png";
import photoPreview5 from "@assets/socialProofPreview/images/t5.png";
import photoPreview6 from "@assets/socialProofPreview/images/t6.png";
import photoPreview7 from "@assets/socialProofPreview/images/t7.png";
import photoPreview8 from "@assets/socialProofPreview/images/t8.png";
import photoPreview9 from "@assets/socialProofPreview/images/t9.png";


/* video */
import videoPreview1 from "@assets/socialProofPreview/video/v1.png";
import videoPreview2 from "@assets/socialProofPreview/video/v2.png";
// import videoPreview3 from "@assets/socialProofPreview/video/v3.png";
// import videoPreview4 from "@assets/socialProofPreview/video/v4.png";

export const ImageTemplates: WidgetType[] = [
    {
        templateId: "i1",
        type: "image",
        name: "photo1",
        previewImage: photoPreview1,
    },
    {
        templateId: "i2",
        type: "image",
        name: "photo2",
        previewImage: photoPreview2,
    },
    {
        templateId: "i3",
        type: "image",
        name: "photo3",
        previewImage: photoPreview3,
    },
    {
        templateId: "i4",
        type: "image",
        name: "photo4",
        previewImage: photoPreview4,
    },
    {
        templateId: "i5",
        type: "image",
        name: "photo5",
        previewImage: photoPreview5,
    },
    {
        templateId: "i6",
        type: "image",
        name: "photo6",
        previewImage: photoPreview6,
    },
    {
        templateId: "i7",
        type: "image",
        name: "photo7",
        previewImage: photoPreview7,
    },
    {
        templateId: "i8",
        type: "image",
        name: "photo8",
        previewImage: photoPreview8,
    },
    {
        templateId: "i9",
        type: "image",
        name: "photo9",
        previewImage: photoPreview9,
    }
];

export const VideoTemplates: WidgetType[] = [
    {
        templateId: "v1",
        type: "video",
        name: "video1",
        previewImage: videoPreview1,
    },
    {
        templateId: "v2",
        type: "video",
        name: "video2",
        previewImage: videoPreview2,
    },
    // {
    //     templateId: "v3",
    //     type: "video",
    //     name: "video3",
    //     previewImage: videoPreview3,
    //     videoType: "reel"
    // },
    // {
    //     templateId: "v4",
    //     type: "video",
    //     name: "video4",
    //     previewImage: videoPreview4,
    // }
];


