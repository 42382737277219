import { QueryKeys, useGetQueryData } from "@hooks";
import { CurrentPlan } from "./currentPlan";
import { CardInfo } from "./cardInfo";
import { Invoices } from "./invoices";
import { UpcomingInvoice } from "./upcomingInvoice";
import { LtdCard } from "./ltdCard";


export function Summary() {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    console.log({ billing });

    if (billing && billing.billingType === "ltd") return <LtdCard />;

    return (
        <div className="flex w-full h-full px-2 py-5 fade-in">

            <div className="w-[70%] h-auto">

                <div className="flex space-x-4 justify-evenly">

                    <CurrentPlan />

                    <CardInfo />

                </div>

                <Invoices />

            </div>

            <UpcomingInvoice />

        </div>
    );
}