import { Fragment, useEffect, useState } from "react";
import { Popup } from "@components/popup";
import { cn } from "@utils/cn";
import { useCommonStore } from "@store";
import { Button } from "@components/button";
import { useSearchParams } from "react-router-dom";
import { QueryKeys, useGetQueryData } from "@hooks";


export function ImageAttachments() {

    const [searchParams] = useSearchParams();

    const activePopup = useCommonStore(state => state.activePopup);

    const testimonialId = searchParams.get("id") ?? "";

    const testimonialRequest = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [selectedImage, setSelectedImage] = useState("");
    const [imageAttachments, setImageAttachments] = useState<string[]>([]);

    useEffect(() => {

        const item = testimonialRequest.testimonials?.filter(item => item.id === testimonialId)[0] ?? null;

        if (item) {

            const attachments = item.imageAttachments ?? [];

            setImageAttachments([...attachments]);

        }

    }, []);

    return (
        <Fragment>

            {imageAttachments.length ?
                <div className="flex flex-wrap items-baseline space-x-4 relative cursor-pointer">

                    {imageAttachments.map((image, index) => {

                        const src = image;

                        return (
                            <img
                                key={index}
                                src={src}
                                className={cn(`
                                w-24 h-24 border rounded-md bg-gray-200 transition-all 
                                ease-in-out duration-200 hover:border-gray-600
                            `)}
                                onClick={() => {
                                    useCommonStore.getState().setActivePopup("testimonial-image-attachment");
                                    setSelectedImage(src);
                                }}
                            />
                        );
                    })}

                </div> : null
            }

            <div>

                {activePopup === "testimonial-image-attachment" ?
                    <Popup
                        name="Image Attachment"
                        width="30%"
                        onClose={() => { }}
                    >

                        <img
                            src={selectedImage}
                            className="w-auto h-auto my-2"
                        />

                        <div className="flex items-center justify-end space-x-3">

                            <Button
                                variant="danger"
                                className="w-auto text-sm font-normal"
                                onClick={() => { }}
                            >
                                Remove
                            </Button>

                            <Button
                                className="w-auto text-sm font-normal"
                                onClick={() => { console.log("///"); }}
                            >
                                Download
                            </Button>

                        </div>


                    </Popup> : null
                }

            </div>


        </Fragment>
    );
}