import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { QueryKeys, useActiveSpace, useTestimonialsApiClient } from "@hooks";
import { useCommonStore, useTestimonialStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";


export function DeleteTestimonialsBtn() {

    const showPopup = useCommonStore((store) => store.activePopup === "delete-testimonials");
    const selectedIds = useTestimonialStore((store) => store.selectedRows);

    const queryClient = useQueryClient();

    const { formId } = useActiveSpace();

    const { deleteQuery } = useTestimonialsApiClient({});

    const { isPending } = deleteQuery;

    const onDelete = async () => {

        await deleteQuery.mutateAsync({ ids: selectedIds, formId });

        if (deleteQuery.isError) {
            toast.error(deleteQuery.error.message);
            return;
        }

        queryClient.setQueryData([QueryKeys.testimonials.getAll], (oldData: Api.Testimonials.getAll) => ({
            ...oldData,
            testimonials: [...oldData.testimonials.filter(item => !selectedIds.includes(item.id))]
        } as Api.Testimonials.getAll));

        toast.success("Testimonials deleted successfully");

        useCommonStore.getState().setActivePopup("");

    };

    if (selectedIds.length === 0) return null;

    return (
        <Fragment>

            <button
                className={cn(`
                    py-2 px-3 bg-red-200 rounded-md w-auto relative cursor-pointer 
                    inline-flex items-center space-x-3 select-none transition-all 
                `)}
                onClick={() => useCommonStore.getState().setActivePopup("delete-testimonials")}
            >

                <MdDelete className="w-5 h-5 text-red-800" />

            </button>

            {showPopup ?
                <Popup
                    width="30%"
                    name="Delete Testimonials"
                    onClose={() => useCommonStore.getState().setActivePopup("")}
                >

                    <div className="my-3 space-y-4">

                        <p className="text-sm font-normal">
                            You're about to delete. It'll be gone forever and we won't be able to recover it.
                        </p>

                        <p className="text-sm font-normal">
                            Are you sure? you want to delete?
                        </p>

                    </div>

                    <div className="flex items-center justify-end space-x-3 mt-0 pt-2">

                        <SecondaryButton
                            disabled={isPending}
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => useCommonStore.getState().setActivePopup("")}
                        >
                            Cancel
                        </SecondaryButton>

                        <Button
                            disabled={isPending}
                            loading={isPending}
                            variant="danger"
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => onDelete()}
                        >
                            Delete
                        </Button>

                    </div>

                </Popup> : null
            }

        </Fragment >
    );
}