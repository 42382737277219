import PoweredByImgSrc from "@assets/powered-by.png";
import { cn } from "@utils/cn";

type Props = {
    className?: string;
};

export function PoweredBy({ className }: Props) {
    return (
        <img
            src={PoweredByImgSrc}
            className={cn("h-[60px]", className)}
        />
    );
}