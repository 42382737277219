import { Button } from "@components/button";
import { Input } from "@components/input";
import { useAuthApiClient } from "@hooks";
import { Auth0 } from "@lib/auth0";
import { useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";


const ResendMailSchema = z.object({
    email: z.string().email()
});

export function ResendVerificationMail() {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const { resendVerificationMail } = useAuthApiClient({});

    const isLoading = resendVerificationMail.isPending;

    const onSubmit = async () => {

        const schema = ResendMailSchema.safeParse({ email });

        if (!schema.success) {
            setError(schema.error.message);
            return;
        };

        await resendVerificationMail.mutateAsync(email);

        toast.success("Verification mail has been send to you. Please verify your account.");

        Auth0.logout();

    };

    return (
        <div
            className="flex flex-col items-center justify-center w-full h-full p-10 space-y-4"
        >

            <div className="w-full">

                <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    error={error}
                    type="email"
                    placeholder="Enter your email here"
                    onClick={() => setError("")}
                />

            </div>

            <Button
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                onClick={onSubmit}
            >
                Resend Email
            </Button>

        </div>
    );
}