import { Button, SecondaryButton } from "@components/button";
import { useWallOfLoveApiClient } from "@hooks";
import { useCommonStore, useWallOfLoveStore } from "@store";
import toast from "react-hot-toast";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { GetWallOfLoveEmbedCodePopup } from "./getCode.popup";


export function WallOfLovePreviewHeader() {

    const { id } = useParams();

    const navigate = useNavigate();

    const activePopup = useCommonStore((store) => store.activePopup);

    const { save } = useWallOfLoveApiClient();


    const onSave = async () => {
        try {

            if (!id) return;

            const data = await save.mutateAsync({
                id,
                settings: useWallOfLoveStore.getState().settings,
            });

            toast.success("Saved...");

        } catch (err: any) {
            console.log(err);
            toast.error(err.message);
        }
    };

    return (
        <div className="border-b flex items-center justify-between w-full space-x-4 py-3 px-4 bg-white">

            <SecondaryButton
                disabled={save.isPending}
                className="text-sm font-medium w-auto px-3"
                onClick={() => navigate("/wall-of-love")}
            >

                <MdArrowBackIos className="w-4 h-4 mr-1" />

                <p> Back </p>

            </SecondaryButton>

            <div className="flex items-center space-x-4">

                <SecondaryButton
                    disabled={save.isPending}
                    loading={save.isPending}
                    className="text-sm"
                    onClick={() => onSave()}
                >
                    Save Changes
                </SecondaryButton>

                <Button
                    disabled={save.isPending}
                    className="mt-0 w-auto text-sm font-medium"
                    onClick={() => {
                        useCommonStore.getState().setActivePopup("get-wall-of-love-embed-code");
                    }}
                >
                    Share
                </Button>

            </div>

            {activePopup === "get-wall-of-love-embed-code" ?
                <GetWallOfLoveEmbedCodePopup /> : null
            }

        </div>
    );
}