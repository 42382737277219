import { QueryKeys, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";

export const UpcomingInvoice: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    return (
        <div className={cn(`w-[30%] h-[80%] rounded-xl py-2 border shadow-lg`, {
            "blur-sm": billing.plan === "free"
        })}>

            <div className="px-5 py-3 border-b">

                <p className="text-lg font-medium">
                    Upcoming invoice
                </p>

                <p className="mt-1 text-xs font-normal text-font_secondary">
                    {billing?.upcomingInvoice?.nextPaymentOn ?? ""}
                </p>

            </div>

            <div className="px-5 py-3 border-b">

                <p className="py-2 text-xs"> Billed items </p>

                {billing?.upcomingInvoice?.items?.map(item => (
                    <div className="px-4 my-2">

                        <p className="text-sm font-normal">
                            {item.description}
                        </p>

                    </div>
                ))}

            </div>

            <div className="py-3 my-4 px-7">

                <div className="flex justify-between">

                    <p className="text-sm font-normal">
                        Sub total
                    </p>

                    <p className="text-sm font-medium">
                        {billing?.upcomingInvoice?.subtotal ?? ""}
                    </p>

                </div>

                <div className="flex justify-between my-3">

                    <p className="text-sm font-normal">
                        Discount
                    </p>

                    <p className="text-sm font-medium">
                        {billing?.upcomingInvoice?.discount ?? ""}
                    </p>

                </div>

                <div className="flex justify-between pt-3 border-t">

                    <p className="text-sm font-normal">
                        Total
                    </p>

                    <p className="text-sm font-medium">
                        {billing?.upcomingInvoice?.total ?? ""}
                    </p>
                </div>

            </div>

        </div>
    );
};