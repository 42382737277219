import { Loading } from "@components/loading";
import { Tab } from "@components/tab";
import { useWidgetsApiClient } from "@hooks";
import { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ListWidgets } from "./list";
import { SavedWidgets } from "./saved";


export function WidgetPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get("tab") ?? "saved";

    const { getAll } = useWidgetsApiClient();

    useEffect(() => { getAll.refetch(); }, []);

    return (
        <div className="h-full w-full max-w-full max-h-full space-y-2 bg-gray-100 px-6 pt-6">

            <p className="text-xl font-semibold tracking-wide">
                Widgets
            </p>

            <p className="text-sm font-normal text-gray-600 tracking-wide">
                Embed testimonials on your website without code.
            </p>

            <div className="w-full h-full">

                {getAll.isLoading || getAll.isFetching ?
                    <Loading /> :
                    <Fragment>

                        <Tab
                            active={tab}
                            tabs={[
                                {
                                    name: "Saved",
                                    value: "saved",
                                },
                                {
                                    name: "Widgets",
                                    value: "widgets"
                                }
                            ]}
                            onChange={(val) => {
                                searchParams.set("tab", val as any);
                                setSearchParams(searchParams);
                            }}
                        />

                        {tab === "saved" ? <SavedWidgets /> : null}

                        {tab === "widgets" ? <ListWidgets /> : null}

                    </Fragment>
                }

            </div>

        </div>
    );
}