import { create } from "zustand";
import { devtools, } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const state: SelectTestimonialsStore.State = {
    filter: {
        range: { from: null, to: null },
        sources: [],
        type: [],
        approved: null,
        rating: null,
    },
    selectedTestimonialIds: [],
};


export const useSelectTestimonialsStore = create<SelectTestimonialsStore.Type>()(devtools(immer(set => ({
    ...state,
    setFilter: (filter) => set((state) => {

        state.filter = { ...state.filter, ...filter };

    },
        false,
        "selectTestimonials/setFilter"
    ),
    setSelectedTestimonialId: (id) => set((state) => {

        if (window.location.pathname.includes("social-proof")) {

            state.selectedTestimonialIds = [id];

        } else {

            if (state.selectedTestimonialIds.includes(id)) {

                state.selectedTestimonialIds = state.selectedTestimonialIds.filter(id => id !== id);

            } else {

                state.selectedTestimonialIds = [...state.selectedTestimonialIds, id];

            }

        }

    },
        false,
        "selectTestimonials/setSelectedTestimonialId"
    ),
    clearSelectedTestimonialIds: () => set((state) => {
        state.selectedTestimonialIds = [];
    },
        false,
        "selectTestimonials/clearSelectedTestimonialIds"
    ),
}))));
