import { Button, SecondaryButton } from "@components/button";
import { TestimonialSourceTypes } from "@components/icons";
import { CheckBoxInput } from "@components/input";
import { RadioInput } from "@components/input/radio.input";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { Rating } from "@components/rating";
import { Text } from "@components/text";
import { QueryKeys, useGetQueryStatus, useInvalidateQuery, useTestimonialsApiClient } from "@hooks";
import { useSelectTestimonialsStore, useTestimonialStore } from "@store";
import { useClickAway } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { useState } from "react";
import { MdFilterList, MdKeyboardArrowDown } from "react-icons/md";



export function OtherFilters() {

    const { approved, rating, sources, types } = useSelectTestimonialsStore((store) => ({
        approved: store.filter.approved,
        types: store.filter.type as string[],
        rating: store.filter.rating,
        sources: store.filter.sources,
    }));

    const { isLoading } = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const clickOutSideRef = useClickAway<any>(() => setShow(!show));

    const [show, setShow] = useState(false);

    const { invalidate } = useInvalidateQuery([QueryKeys.testimonials.getAll]);

    const { getAll } = useTestimonialsApiClient({});

    const onCancel = () => {

        useSelectTestimonialsStore.getState().setFilter({
            approved: null,
            rating: null,
            sources: [],
            type: []
        });

        invalidate([QueryKeys.testimonials.getAll]);

        setShow(false);

    };

    const onChangeType = (type: string) => {

        useSelectTestimonialsStore.getState().setFilter({
            type: types.includes(type) ? types.filter((t) => t !== type) : [...types, type]
        });

    };

    const onApply = () => {

        if (isLoading) return;

        invalidate([QueryKeys.testimonials.getAll]);

        getAll.refetch();

        setShow(false);

    };


    return (
        <Popover open={show}>

            <PopoverTrigger>

                <SecondaryButton
                    className={cn(`p-2 px-3 bg-white hover:bg-white`)}
                    onClick={() => setShow(!show)}
                >

                    <div className="space-x-2 flex items-center justify-center mr-2">

                        <MdFilterList className="w-5 h-5" />

                        <p className="text-sm font-medium"> Filter </p>

                    </div>

                    <MdKeyboardArrowDown className="w-6 h-6" />

                </SecondaryButton>

            </PopoverTrigger>

            <PopoverContent
                ref={clickOutSideRef}
                side="bottom"
                align="start"
                className="mt-2 bg-white w-auto h-full"
                style={{
                    width: "450px"
                }}
            >

                <div className="flex items-start space-x-4">

                    <div className="space-y-2 w-[45%] border-r">

                        <div className="">

                            <Text
                                variant="label"
                                text={"Approval Status"}
                                size="sm"
                                className="font-medium tracking-wide text-gray-900"
                            />

                            <div className="space-y-2 mt-2 px-1">

                                <RadioInput
                                    name="approved"
                                    label="Approved"
                                    defaultChecked={approved ? true : false}
                                    value="approved"
                                    onClick={() => useTestimonialStore.getState().setFilter({ approved: true })}
                                />

                                <RadioInput
                                    name="approved"
                                    label="Unapproved"
                                    defaultChecked={approved ? true : false}
                                    value="unapproved"
                                    onClick={() => useTestimonialStore.getState().setFilter({ approved: false })}
                                />

                            </div>

                        </div>

                        <div>

                            <Text
                                variant="label"
                                size="sm"
                                className="font-medium tracking-wide text-gray-900"
                                text={"Testimonial Type"}
                            />

                            <div className="space-y-1 px-1 my-1">

                                <CheckBoxInput
                                    label="video"
                                    value={"video"}
                                    defaultChecked={types.includes("video")}
                                    onClick={() => onChangeType("video")}
                                />

                                <CheckBoxInput
                                    label="audio"
                                    value={"audio"}
                                    defaultChecked={types.includes("audio")}
                                    onClick={() => onChangeType("audio")}
                                />

                                <CheckBoxInput
                                    label="text"
                                    value={"text"}
                                    defaultChecked={types.includes("text")}
                                    onClick={() => onChangeType("text")}
                                />

                            </div>

                        </div>

                        <div className="space-y-2">

                            <Text
                                variant="label"
                                size="sm"
                                className="font-medium tracking-wide text-gray-900"
                                text={"Rating"}
                            />

                            <Rating
                                rated={(rating ?? 0)}
                                onChange={(val) => useTestimonialStore.getState().setFilter({ rating: val })}
                            />

                        </div>

                    </div>

                    <div className="w-[55%]">

                        <Text
                            variant="label"
                            text="Testimonial Source"
                            size="sm"
                            className="font-medium tracking-wide text-gray-900"
                        />

                        <div className="grid grid-cols-4 gap-3 mt-2">

                            {TestimonialSourceTypes.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={cn(`
                                            border bg-gray-50 hover:bg-gray-200 cursor-pointer p-1 
                                            flex items-center justify-center rounded-md transition-all duration-300 ease-in-out
                                        `, {
                                            "bg-blue-100 border-blue-500": sources.includes(item.value)
                                        })}
                                        onClick={() => {

                                            useSelectTestimonialsStore.getState().setFilter({
                                                sources: sources.includes(item.value) ?
                                                    sources.filter(source => source !== item.value) :
                                                    ([...sources, item.value])
                                            });

                                        }}
                                    >
                                        {item.icon}
                                    </div>
                                );
                            })}

                        </div>

                    </div>

                </div>

                <div className="flex items-center justify-end space-x-3 mt-4">

                    <SecondaryButton
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        text="Cancel"
                        onClick={() => onCancel()}
                    />

                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        text="Apply"
                        onClick={() => onApply()}
                    />

                </div>

            </PopoverContent>

        </Popover>
    );
}