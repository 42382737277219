import { cn } from "@utils/cn";
import { EmailTemplate } from "./template";
import { useInvitesStore } from "@store";


export function TemplatePreview() {

    const subject = useInvitesStore((store) => store.template.message.subject);

    const from = "no-replay@testimonial-gozen.io";
    const to = "example@gmail.com";

    return (
        <div className={cn(`
            w-[60%] h-[90%] max-h-[90%] overflow-y-auto 
            flex flex-col items-center justify-start py-4 pb-8 
        `)}>

            <div className="shadow-lg w-[94%] ml-2 rounded-lg h-auto bg-white border">

                <div className="text-xs font-medium space-y-1.5 text-gray-700 tracking-wide p-4">

                    <p>
                        From: {from}
                    </p>

                    <p>
                        To: {to}
                    </p>

                </div>

                <div className="border-t border-b border-gray-300 p-4">

                    <p
                        className="text-sm font-medium"
                        dangerouslySetInnerHTML={{ __html: subject }}
                    />

                </div>

                <div className="flex items-center justify-center bg-gray-100 rounded-b-lg py-8">

                    <EmailTemplate />

                </div>

            </div>

        </div >
    );
} 