import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget8_Settings() {

    const settings = useWidgetsStore((store) => store.widget8);
    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-2">

            <Input
                value={settings.height}
                label="Height"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("height", value, setErrors);
                    useWidgetsStore.getState().setWidget8Settings({ height: value });
                }}
                error={errors?.height}
            />

            <Input
                value={settings.transition}
                label="Transition Speed"
                placeholder="0.5s"
                onChange={(e) => {
                    const value = e.target.value;
                    useWidgetsStore.getState().setWidget8Settings({ transition: value });
                }}
                error={errors?.transition}
            />

            <ColorPicker
                defaultColor={settings.front_textColor}
                label="Front Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ front_textColor: e })}
            />
            <ColorPicker
                defaultColor={settings.back_textColor}
                label="Back Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ back_textColor: e })}
            />

            <ColorPicker
                defaultColor={settings.starColor}
                label="Star Color"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ starColor: e })}
            />


            <ColorPicker
                defaultColor={settings.darkStarColor}
                label="Dark Star Color"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ darkStarColor: e })}
            />

            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ backgroundColor: e })}
            />


            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget8Settings({ customCSS: e.target.value })}
            />

        </div>
    );
}