import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from 'zustand/middleware/immer';
import { StoreKeys } from "../enum";

const State: User.State = {
    id: "",
    avatarUrl: "",
    email: "",
    name: "",
    phoneNumber: "",
    billing: {
        plan: "",
        type: "subscription",
        billingInterval: "",
        limit: {
            testimonials: 0,
            storage: 0,
            member: 0,
            domains: 0,
            emailInvites: 0,
            aiCredits: 0,
            spaces: 0
        },
        used: {
            testimonials: 0,
            storage: 0,
            member: 0,
            domains: 0,
            emailInvites: 0,
            aiCredits: 0,
            spaces: 0
        },
        removeBranding: false,
        removeLoadingBranding: false
    },
    onboardingCompleted: false
};

export const useUserStore = create<User.Type>()(devtools(persist(immer(set => ({
    ...State,
    setUser: (data) => set(() => data,
        false,
        "user/setUser"
    ),
    setName: (name) => set((state) => {
        state.name = name;
    },
        false,
        "user/setName"
    ),
    setAvatarUrl: (url) => set((state) => {
        state.avatarUrl = url;
    },
        false,
        "user/setAvatarUrl"
    ),
    setOnboardingCompleted: (val) => set((state) => {
        state.onboardingCompleted = val;
    },
        false,
        "user/setOnboarding"
    ),
    setPhoneNumber: (val) => set((state) => {
        state.phoneNumber = val;
    },
        false,
        "user/setPhoneNumber"
    )
})), {
    name: StoreKeys.user
}), {
    name: StoreKeys.user,
    enabled: import.meta.env.DEV
}));