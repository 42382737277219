import { SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useWallOfLoveStore } from "@store";
import { MdOutlineAdd, MdOutlineDelete } from "react-icons/md";


export function WallOfLove_Navigation() {

    const { hide, homeLink, centerNav, headerLinks } = useWallOfLoveStore((store) => ({
        hide: store.settings.navigation.hideNavBar,
        homeLink: store.settings.navigation.homeLink,
        centerNav: store.settings.navigation.centerNav,
        headerLinks: store.settings.navigation.headerLinks ?? []
    }));

    console.log({ headerLinks });

    console.log(useWallOfLoveStore.getState().settings.navigation);

    return (
        <div className="my-3 space-y-3">

            <TextToggle
                isActive={hide}
                name="Hide Navigation"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateNavigation({
                    hideNavBar: !hide,
                })}
            />

            {hide === false ?
                <Input
                    label="Home Link"
                    placeholder="ex: https://gozen.io"
                    value={homeLink ?? ""}
                    onChange={(e) => useWallOfLoveStore.getState().setWolTemplateNavigation({
                        homeLink: e.target.value,
                    })}
                /> : null
            }

            <TextToggle
                isActive={hide}
                name="Center navigation"
                onClick={() => useWallOfLoveStore.getState().setWolTemplateNavigation({
                    centerNav: !centerNav,
                })}
            />

            <div className="my-4 flex items-center justify-between">

                <p className="text-sm font-normal text-gray-900">
                    Header Links
                </p>

                <SecondaryButton
                    className="w-auto p-1"
                    onClick={() => useWallOfLoveStore.getState().setWolTemplateNavigation({
                        headerLinks: [...headerLinks, { title: "", url: "", openInNewTab: false }]
                    })}
                >

                    <MdOutlineAdd className="w-4 h-4" />

                </SecondaryButton>

            </div>

            {headerLinks && headerLinks.map((link, idx) => (
                <div
                    key={idx}
                    className="border border-gray-300 px-2 py-3 rounded-md bg-gray-50 space-y-3"
                >

                    <Input
                        placeholder="Title"
                        value={link.title}
                        onChange={(e) => useWallOfLoveStore.getState().setWolTemplateNavigation({
                            headerLinks: headerLinks.map((l, lIdx) => ({
                                ...l,
                                title: lIdx === idx ? e.target.value : l.title
                            }))
                        })}
                    />

                    <Input
                        placeholder="URL"
                        value={link.url}
                        onChange={(e) => useWallOfLoveStore.getState().setWolTemplateNavigation({
                            headerLinks: headerLinks.map((l, lIdx) => ({
                                ...l,
                                url: lIdx === idx ? e.target.value : l.url
                            }))
                        })}
                    />

                    <div className="flex items-center justify-between pt-1 mx-1">

                        <div className="flex items-center gap-2 mx-2">

                            <input
                                type="checkbox"
                                onClick={(e) => {
                                    useWallOfLoveStore.getState().setWolTemplateNavigation({
                                        headerLinks: headerLinks.map((l, lIdx) => ({
                                            ...l,
                                            openInNewTab: lIdx === idx ? !l.openInNewTab : l.openInNewTab
                                        }))
                                    });
                                }}
                            />

                            <p className="text-sm font-normal text-gray-600">
                                Open in new tab
                            </p>

                        </div>

                        <SecondaryButton
                            className="w-auto p-1"
                            onClick={() => useWallOfLoveStore.getState().setWolTemplateNavigation({
                                headerLinks: headerLinks.filter((_, lIdx) => lIdx !== idx)
                            })}
                        >
                            <MdOutlineDelete className="w-4 h-4" />
                        </SecondaryButton>

                    </div>

                </div>
            ))}


        </div>
    );
}