import { useCustomQuery } from "../useCustomQuery";
import { UndefinedInitialDataOptions, useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { ParseAxiosError } from "@lib/axios";
import { QueryKeys } from "./keys";

type Props = {
    getAll?: {
        params: Partial<UndefinedInitialDataOptions>,
    };
};

export function useTeamsApiClient({ getAll }: Props) {

    const getAllQuery = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.teams.getAll],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Teams.GetAllMembers>("/api/v1/team")).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        onError: (err: any) => toast.error(ParseAxiosError(err).message),
        ...getAll?.params
    });

    const addMember = useMutation({
        mutationKey: [QueryKeys.teams.add],
        mutationFn: async (data: Api.Teams.addMemberParams) => {
            try {
                return (await axios.post("/api/v1/team", data)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const updatePermission = useMutation({
        mutationKey: [QueryKeys.teams.update],
        mutationFn: async (data: Api.Teams.updatePermissionParams) => {
            try {
                return (await axios.put("/api/v1/team", data)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteMember = useMutation({
        mutationKey: [QueryKeys.teams.delete],
        mutationFn: async (params: Api.Teams.deleteParams) => {
            try {
                return (await axios.delete("/api/v1/team", { params })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const reInvite = useMutation({
        mutationKey: [QueryKeys.teams.reinvite],
        mutationFn: async (id: string) => {
            try {
                return (await axios.get("/api/v1/team/invite", { params: { id } })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const joinTeam = useMutation({
        mutationKey: [QueryKeys.teams.join],
        mutationFn: async (token: string) => {
            try {

                return (await axios.get("/api/v1/team/token", { params: { token } })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        getAll: getAllQuery,
        addMember,
        updatePermission,
        deleteMember,
        reInvite,
        joinTeam
    };

}