import { Tab } from "@components/tab";
import { SavedWallOfLoveTemplates } from "./saved";
import { WallOfLoveTemplates } from "./template";
import { useSearchParams } from "react-router-dom";
import { useWallOfLoveApiClient } from "@hooks";
import { Fragment, useEffect } from "react";
import { Loading } from "@components/loading";

export function WallOfLovePage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get("tab") ?? "saved";

    const { getAll } = useWallOfLoveApiClient();

    useEffect(() => { getAll.refetch(); }, []);

    return (
        <div className="h-full w-full max-h-full space-y-2 bg-gray-100 px-6 pt-6 overflow-hidden">

            <p className="text-xl font-semibold tracking-wide">
                Wall Of Love
            </p>

            <p className="text-sm font-normal text-gray-600 tracking-wide">
                Create a beautiful, shareable Wall of Love with your best testimonials
            </p>

            <div className="w-full h-full max-h-full overflow-hidden">

                {getAll.isLoading || getAll.isFetching ?
                    <Loading /> :
                    <Fragment>

                        <Tab
                            active={tab}
                            tabs={[
                                {
                                    name: "Saved",
                                    value: "saved",
                                },
                                {
                                    name: "Templates",
                                    value: "templates"
                                }
                            ]}
                            onChange={(val) => {
                                searchParams.set("tab", val);
                                setSearchParams(searchParams);
                            }}
                        />


                        {tab === "saved" ? <SavedWallOfLoveTemplates /> : null}

                        {tab === "templates" ? <WallOfLoveTemplates /> : null}

                    </Fragment>
                }

            </div>

        </div>
    );

}