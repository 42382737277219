import { Button } from "@components/button";
import { Input } from "@components/input";
import { useAuthApiClient } from "@hooks";
import { LocalStorageUtil } from "@utils/localStorage";
import { DateTime, Interval } from "luxon";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";


export function ForgotPasswordPage() {

    const navigate = useNavigate();

    const { sendForgotPasswordVerifyToken } = useAuthApiClient({});

    const [email, setEmail] = useState("");

    const [emailSend, setEmailSend] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    useEffect(() => {

        const lastSend = LocalStorageUtil.get<string>("lastSendForgotPassword");

        if (lastSend) {

            const time = DateTime.fromISO(new Date(parseInt(lastSend)).toISOString());
            const current = DateTime.now();

            const diff = Interval.fromDateTimes(time, current);

            if (diff.length("minutes") < 30) {

                toast.error("This link is expired.");

                navigate("/login", { replace: true });

            } else {

                LocalStorageUtil.remove("lastSendForgotPassword");

            }
        }

        setShowForgotPassword(true);

    }, []);

    const onSend = async () => {
        try {

            const schema = z.object({ email: z.string().email() });

            schema.safeParse({ email });

            await sendForgotPasswordVerifyToken.mutateAsync(email);

            setEmailSend(true);

        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <div className="w-[65%] flex flex-col items-center justify-center">

            {emailSend ?
                <div className="w-[50%]">

                    <h4 className="mb-5 text-xl font-bold text-left text-primary-bold">
                        Forgot Password
                    </h4>

                    <p className="mb-5 font-normal">
                        We have successfully send email to you on how to
                        reset password instructions do check your inbox.
                    </p>

                    <p
                        className="px-5 py-2 text-center"
                        onClick={() => navigate("/login", { replace: true })}
                    >
                        Back to <u className="text-blue-800 cursor-pointer"> Login </u>
                    </p>

                </div> :
                <Fragment>

                    {showForgotPassword ?
                        <Fragment>

                            {sendForgotPasswordVerifyToken.error && sendForgotPasswordVerifyToken.error.message === "Please try signing up with google oauth." ?
                                <div className="px-5 py-7 rounded-md m-auto bg-white w-[50%]">

                                    <h4 className="mb-5 text-xl font-bold text-primary-bold">
                                        Forgot Password
                                    </h4>

                                    <p className="mb-5 font-normal">
                                        Your signed up with google oauth. So try logging in with google oauth
                                    </p>

                                    <p
                                        className=""
                                        onClick={() => navigate("/login", { replace: true })}
                                    >
                                        Back to <u className="text-blue-800 cursor-pointer"> Login </u>
                                    </p>

                                </div> :
                                <div className="w-[50%] p-5 space-y-5">

                                    <h4 className="mb-5 text-xl font-bold text-primary-bold">
                                        Forgot Password
                                    </h4>

                                    {sendForgotPasswordVerifyToken.error?.message ?
                                        <div className="flex items-center w-full py-3 mt-3 bg-red-200 border border-red-600 rounded-lg shadow">

                                            <div className="ml-3 text-sm font-normal">
                                                {sendForgotPasswordVerifyToken.error.message}
                                            </div>

                                        </div> : null
                                    }

                                    <div className="w-full my-2">

                                        <Input
                                            required={true}
                                            label='Email'
                                            className='w-full'
                                            placeholder="test@gozen.io"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />

                                    </div>

                                    <Button
                                        disabled={sendForgotPasswordVerifyToken.isPending}
                                        loading={sendForgotPasswordVerifyToken.isPending}
                                        onClick={() => onSend()}
                                    >
                                        Send Instruction
                                    </Button>

                                    <p
                                        className="px-5 py-2 mt-5 text-sm text-center"
                                        onClick={() => navigate("/login", { replace: true })}
                                    >
                                        Back to <u className="text-blue-800"> Login </u>
                                    </p>

                                </div>
                            }

                        </Fragment> : null
                    }

                </Fragment>
            }

        </div>
    );
}