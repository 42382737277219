

export function AppSumoLogo() {
    return (
        <svg version="1.1" height={20} id="as-appsumo-logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="-3924 3989.8 150 23.2" xmlSpace="preserve">

            <path id="Path_1007" className="st0" d="M-3829.3,4004c0,3.9-1.8,3.9-2.7,3.9s-2.7,0-2.7-3.9v-11h-4.6v11.7c0,4.5,3,7.7,7.3,7.7
	c4.3,0,7.3-3.2,7.3-7.7V3993h-4.6L-3829.3,4004z"/>
            <path id="Path_1008" className="st0" d="M-3784.2,3992.4c-5.6,0-10.2,4.5-10.2,10.2c0,5.6,4.6,10.2,10.2,10.2c5.6,0,10.2-4.6,10.2-10.2
	C-3774,3997-3778.6,3992.4-3784.2,3992.4z M-3784.2,4008.1c-3.1,0-5.5-2.5-5.5-5.6c0-3,2.4-5.5,5.4-5.6c3,0,5.5,2.4,5.6,5.4
	c0,0,0,0.1,0,0.1C-3778.7,4005.6-3781.2,4008.1-3784.2,4008.1z"/>
            <path id="Path_1009" className="st0" d="M-3913.7,3989.8l-10.3,22.4h5.2l2-4.3h6.2l2,4.3h5.2L-3913.7,3989.8z M-3915.7,4004.1l1.9-4.7
	l1.9,4.7H-3915.7z"/>
            <path id="Path_1010" className="st0" d="M-3891.5,3993.1h-7.5v18.9h4.7v-5.4h2.5c4.6,0,7.5-2.6,7.5-6.8v-0.1
	C-3884.2,3995.6-3887,3993.1-3891.5,3993.1z M-3889,4000c0,1.6-1.1,2.6-2.9,2.6h-2.4v-5.2h2.4C-3890,3997.3-3889,3998.3-3889,4000
	L-3889,4000z"/>
            <path id="Path_1011" className="st0" d="M-3872.1,3993.1h-7.5v18.9h4.7v-5.4h2.5c4.6,0,7.5-2.6,7.5-6.8v-0.1
	C-3864.8,3995.6-3867.6,3993.1-3872.1,3993.1z M-3869.6,4000c0,1.6-1.1,2.6-2.9,2.6h-2.4v-5.2h2.4
	C-3870.6,3997.3-3869.6,3998.3-3869.6,4000L-3869.6,4000z"/>
            <path id="Path_1012" className="st0" d="M-3850.9,3999.1c-3.7-1-4.5-1.4-4.5-2.7v-0.1c0-1,1-1.7,2.6-1.7c2,0.1,3.9,0.8,5.5,2.1l0.1,0.1
	l2.6-3.8l-0.1-0.1c-2.3-1.8-5.2-2.8-8.1-2.8c-4.5,0-7.7,2.7-7.7,6.6v0.1c0,4.5,3.1,5.7,7.3,6.7c3.6,0.9,4.2,1.5,4.2,2.6v0.1
	c0,1.2-1.1,1.9-2.9,1.9c-2.3-0.1-4.6-1-6.3-2.6l-0.1-0.1l-3,3.6l0.1,0.1c2.5,2.3,5.8,3.5,9.2,3.5c5,0,8.1-2.6,8.1-6.8v-0.1
	C-3843.9,4001.7-3846.7,4000.1-3850.9,3999.1z"/>
            <path id="Path_1013" className="st0" d="M-3809.2,4013l-5.7-9.6l-1.7,8.9h-4.1l4.2-19.9l7.4,12.5l7-12.5l4.2,19.9h-4.4l-1.6-8.8
	L-3809.2,4013z"/>
        </svg>
    );
}