import { QueryKeys, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";
import { MdOutlineLink } from "react-icons/md";

export const Invoices: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    return (
        <div className="mt-3 px-5 h-[40%] overflow-y-scroll no-scrollbar">

            <p className="my-4 ml-4 font-medium">
                Payment history
            </p>

            <table className={cn(`w-full bg-gray-100 rounded-lg`, {
                "blur-sm": billing.plan === "free"
            })}>

                <thead>

                    <tr className="flex flex-row items-start text-center bg-gray-200 rounded-t-lg">

                        <th className="w-1/3 px-5 py-2 font-normal rounded-l-lg">
                            Order Id
                        </th>

                        <th className="w-1/3 px-5 py-2 font-normal">
                            Date
                        </th>

                        <th className="w-1/3 px-5 py-2 font-normal rounded-r-lg">
                            Invoice
                        </th>

                    </tr>
                </thead>

                <tbody className="overflow-y-scroll text-center h-[90%]">

                    {billing?.invoices?.map(invoice => {
                        return (
                            <tr className="flex items-center justify-center py-3 hover:bg-white">

                                <p className="w-1/3 px-5 text-sm font-normal text-font_primary">
                                    {invoice?.id ?? ""}
                                </p>

                                <p className="w-1/3 px-5 text-sm font-normal text-font_primary">
                                    {invoice?.createdAt ?? ""}
                                </p>

                                <a
                                    className={cn(`
                                        w-[30%] px-5 text-primary font-normal text-sm 
                                        flex items-center space-x-2 group cursor-pointer
                                    `)}
                                    href={invoice?.url ?? ""}
                                    target="blank"
                                >

                                    <span>View and download</span>

                                    <MdOutlineLink className="w-5 h-4" />

                                </a>

                            </tr>
                        );
                    })}

                </tbody>

            </table>

        </div>
    );
};