import { MdClose } from "react-icons/md";
import { motion } from 'framer-motion';
import { TimeLine } from "./timeLine";
import { TimeLineCard } from "./itemCard";
import { cn } from "@utils/cn";
import { useCommonStore } from "@store";


export function WhatsNew() {

    const { setActivePopup } = useCommonStore();

    return (
        <div className={cn(`
            absolute top-0 right-0 bg-[#4e4e4e7c] w-screen h-screen z-[100] overflow-hidden
            transition-all duration-100 ease-in-out
        `)}>

            <motion.div
                className="bg-white w-[40%] h-full ml-auto shadow-md pb-5"
                initial={{
                    x: "100px",
                    opacity: 0
                }}
                animate={{
                    x: 0,
                    opacity: 1,
                }}
                exit={{
                    x: "100px",
                    opacity: 0,
                    transition: {
                        duration: 0.2
                    }
                }}
            >

                <div className="h-[100%] overflow-y-scroll p-8 pb-2">

                    <ol className="relative border-gray-300 border-l h-auto">

                        {TimeLine.map((item, idx) => (
                            <TimeLineCard
                                key={idx}
                                title={item.title}
                                description={item.description}
                                latest={item.latest}
                            />
                        ))}

                    </ol>

                </div>

                <div
                    className="absolute top-5 right-8 p-1 rounded-md bg-gray-100 hover:bg-gray-200 cursor-pointer"
                    onClick={() => setActivePopup("")}
                >
                    <MdClose className="w-6 h-6 " />
                </div>

            </motion.div>

        </div >

    );
};