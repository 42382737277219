import { SecondaryButton } from "@components/button";
import { useCommonStore, useUserStore } from "@store";
import { Fragment } from "react";
import { ChangePasswordPopup } from "../changePassword.popup";


export function ChangePassword() {

    const activePopup = useCommonStore((store) => store.activePopup);
    const email = useUserStore((store) => store.email);

    return (
        <Fragment>

            <div className="w-full h-full border border-gray-200 bg-gray-50 rounded-md p-5">

                <p className="text-sm font-medium">
                    Login Details <span className="text-gray-600 text-xs tracking-wide"> ({email}) </span>
                </p>

                <div className="mt-4 flex items-center space-x-4">

                    <SecondaryButton
                        className="w-auto text-sm font-medium tracking-wide bg-white"
                        onClick={() => useCommonStore.getState().setActivePopup("change-password")}
                    >
                        Change Password

                    </SecondaryButton>

                </div>

            </div>

            {activePopup === "change-password" ? <ChangePasswordPopup /> : null}

        </Fragment>
    );
}