import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Twitter } from "./twitter";
import { TextImport } from "./text";
import { VideoImport } from "./video";
import { ProductHuntImport } from "./productHunt";
import { LinkedInImport } from "./linkedIn";
import { RedditImport } from "./reddit";
import { FaceBookImport } from "./faceBook";
import { InstagramImport } from "./instaGram";
import { CapterraImport } from "./capterra";
import { TrustPilotImport } from "./trustPilot";
import { G2Import } from "./g2";
import { YoutubeImport } from "./youTube";
import { TiktokImport } from "./tiktok";
import { PlayStoreImport } from "./playStore";
import { AppStoreImport } from "./appStore";
import { WhatsappImport } from "./whatsapp";
import { TelegramImport } from "./telegram";
import { DiscordImport } from "./discord";
import { AmazonImport } from "./amzon";
import { AirbnbImport } from "./airbnb";
import { SlackImport } from "./slack";
import { GoogleReviewImport } from "./google";


export function ImportSection() {

    const [searchParams, setSearchParams] = useSearchParams();

    const importFrom = searchParams.get("import") ?? "text";

    useEffect(() => {

        if (!searchParams.get("import")) {
            searchParams.set("import", "text");
            setSearchParams(searchParams);
        }

    }, []);

    return (
        <div className="w-full h-full border overflow-y-auto">

            {/* manual imports */}

            {importFrom === "text" ? <TextImport /> : null}

            {importFrom === "video" ? <VideoImport /> : null}

            {importFrom === "reddit" ? <RedditImport /> : null}

            {importFrom === "product-hunt" ? <ProductHuntImport /> : null}

            {importFrom === "g2" ? <G2Import /> : null}

            {importFrom === "play-store" ? <PlayStoreImport /> : null}

            {importFrom === "app-store" ? <AppStoreImport /> : null}

            {importFrom === "whatsapp" ? <WhatsappImport /> : null}

            {importFrom === "telegram" ? <TelegramImport /> : null}

            {importFrom === "slack" ? <SlackImport /> : null}

            {importFrom === "discord" ? <DiscordImport /> : null}


            {/* social imports */}

            {importFrom === "X" ? <Twitter /> : null}

            {importFrom === "linkedin" ? <LinkedInImport /> : null}

            {importFrom === "facebook" ? <FaceBookImport /> : null}

            {importFrom === "instagram" ? <InstagramImport /> : null}

            {importFrom === "youtube" ? <YoutubeImport /> : null}

            {importFrom === "tiktok" ? <TiktokImport /> : null}



            {/* scrape and import */}


            {importFrom === "capterra" ? <CapterraImport /> : null}

            {importFrom === "trustpilot" ? <TrustPilotImport /> : null}

            {importFrom === "amazon" ? <AmazonImport /> : null}

            {importFrom === "airbnb" ? <AirbnbImport /> : null}

            {importFrom === "google" ? <GoogleReviewImport /> : null}


            {/* {importFrom === "yelp" ? <YelpImport /> : null} */}

            {/* {importFrom === "shopify" ? <ShopifyImport /> : null} */}




        </div>
    );
}