import { SecondaryButton } from "@components/button";
import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { useWidgetsStore } from "@store";
import { checkSize } from "@utils/checkSize";
import { useState } from "react";


export function Widget2_Settings() {

    const settings = useWidgetsStore((store) => store.widget2);

    const [errors, setErrors] = useState<any>({});

    return (
        <div className="w-full space-y-3">

            <SecondaryButton
                className="text-sm"
                onClick={() => useWidgetsStore.getState().setSelectTestimonials(true)}
            >
                Select Testimonials
            </SecondaryButton>

            <Input
                value={settings.width}
                label="Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("width", value, setErrors);
                    useWidgetsStore.getState().setWidget2Settings({ width: value });
                }}
                error={errors?.width}

            />

            <Input
                value={settings.height}
                label="Height"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("height", value, setErrors);
                    useWidgetsStore.getState().setWidget2Settings({ height: value });
                }}
                error={errors?.height}
            />


            <Input
                value={settings.padding}
                label="Padding"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("padding", value, setErrors);
                    useWidgetsStore.getState().setWidget2Settings({ padding: value });
                }}
                error={errors?.padding}
            />


            <ColorPicker
                defaultColor={settings.borderColor}
                label="Border Color"
                onChange={(e) => useWidgetsStore.getState().setWidget2Settings({ borderColor: e })}
            />

            <Input
                value={settings.borderWidth}
                label="Border Width"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("borderWidth", value, setErrors);
                    useWidgetsStore.getState().setWidget2Settings({ borderWidth: value });
                }}
                error={errors?.borderWidth}
            />



            <ColorPicker
                defaultColor={settings.backgroundColor}
                label="Background Color"
                onChange={(e) => useWidgetsStore.getState().setWidget2Settings({ backgroundColor: e })}
            />


            <Input
                value={settings.customCSS}
                label="Custom CSS"
                placeholder="Enter your style here"
                onChange={(e) => useWidgetsStore.getState().setWidget2Settings({ customCSS: e.target.value })}
            />


            <ColorPicker
                defaultColor={settings.textColor}
                label="Text Color"
                onChange={(e) => useWidgetsStore.getState().setWidget2Settings({ textColor: e })}
            />


            <Input
                value={settings.fontSize}
                label="Font Size"
                placeholder="px / % / rem"
                onChange={(e) => {
                    const value = e.target.value;
                    checkSize("fontSize", value, setErrors);
                    useWidgetsStore.getState().setWidget2Settings({ fontSize: value });
                }}
                error={errors?.fontSize}
            />

        </div>
    );
}