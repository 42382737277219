import isEqual from "lodash.isequal";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDebounce } from "@uidotdev/usehooks";
import { LocalStorageUtil } from "@utils/localStorage";
import { useFormStore } from "@store";
import { Config } from "@config";
import { useActiveSpace, useFormApiClient } from "@hooks";

type Props = {
    isLoading: boolean;
};

export function AutoSaveForm({ isLoading }: Props) {

    const activeFormId = useActiveSpace().formId;

    const form = useFormStore((store) => ({
        id: store.id,
        version: store.version,
        name: store.name,
        design: store.design,
        welcome: store.welcome,
        response: store.response,
        customDetails: store.customDetails,
        thankYou: store.thankYou,
        reward: store.reward,
        settings: store.settings,
        tags: store.tags,
        customLabels: store.customLabels
    }));

    const changed = useDebounce(JSON.stringify(form), 500);

    const { update } = useFormApiClient({});

    useEffect(() => {

        syncPreview();

        window.addEventListener("message", handleMessages);

        return () => window.removeEventListener("message", handleMessages);

    }, []);

    useEffect(() => onChange(), [changed]);

    const onUpdate = async (params: any) => {

        const data = await update.mutateAsync({ ...params });

        if (update.isError) {
            toast.error(update.error.message);
            return;
        }

        useFormStore.getState().setVersion({ version: data?.version as number });

        delete (form as any).id;
        delete (form as any).version;
        delete (form as any).publishedVersion;

        if (update?.data?.version) LocalStorageUtil.set("formSnapshot", form);

    };

    const onChange = () => {

        syncPreview();

        if (isLoading) return;

        const snapshot = LocalStorageUtil.get<FormSnapShot>("formSnapshot");

        if (!snapshot) {
            onUpdate({ ...form, id: activeFormId });
            return;
        }

        const formId = form.id ? form.id : activeFormId;

        const version = form.version;

        (["id", "version", "publishedVersion"]).forEach(key => delete (form as any)[key]);

        if (isEqual(snapshot, form) === true) return;

        const changedKeys = getObjectDiff(snapshot, form);

        if (!changedKeys.length) return;

        const newForm: any = {};

        changedKeys.forEach((key: any) => newForm[key] = (form as any)[key]);

        onUpdate({
            ...newForm,
            id: formId,
            version,
        });

        syncPreview();

        return;

    };

    const getObjectDiff = (obj1: any, obj2: any) => {

        const diff = Object.keys(obj1).reduce((result, key) => {

            if ((key in obj2) === false) {

                result.push(key);

            } else if (isEqual(obj1[key], obj2[key])) {

                const resultKeyIndex = result.indexOf(key);

                result.splice(resultKeyIndex, 1);

            }

            return result;

        }, Object.keys(obj2));

        return diff;

    };

    const syncPreview = () => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (!ele) return;

        // const renderEngineUrl = Config.RenderEngineUrl();

        ele.contentWindow?.postMessage({
            type: "form",
            payload: form
        }, "*");

    };

    const handleMessages = (e: MessageEvent<Messages.Types>) => {

        const data = e.data as Messages.Types;

        const renderEngineUrl = Config.RenderEngineUrl();

        if (!e.origin.startsWith(renderEngineUrl)) return;

        if (data.type === "form") syncPreview();

    };

    return <></>;

}