import { Config } from "@config";
import { QueryKeys, useTestimonialsApiClient } from "@hooks";
import { useWallOfLoveStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { WallOfLovePreviewHeader } from "./header";


export function WallOfLoveTemplatePreview() {

    const { id } = useParams();

    const { showSelectTestimonials, settings } = useWallOfLoveStore((store) => ({
        showSelectTestimonials: store.showSelectTestimonials,
        settings: store.settings
    }));

    const queryClient = useQueryClient();

    const renderEngineUrl = Config.RenderEngineUrl() + "/wall-of-love/preview/" + id;

    const testimonials = queryClient.getQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const { getAll } = useTestimonialsApiClient({});

    const [searchParams] = useSearchParams();

    const [loaded, setLoaded] = useState(true);

    const activeTab = searchParams.get("tab");

    const loadTestimonials = testimonials?.testimonials ? false : true;

    useEffect(() => {

        if (!testimonials?.testimonials) {
            getAll.refetch();
        }

    }, [loadTestimonials]);

    useEffect(() => { onSyncWithPreview(); }, [JSON.stringify(settings)]);

    useEffect(() => { sendTestimonialsToPreview(); }, [activeTab, loaded]);

    const onSyncWithPreview = () => {

        const ele = document.getElementById("wall-of-love-preview") as HTMLIFrameElement;

        if (!ele) return;

        ele.contentWindow?.postMessage({
            type: "wallOfLoveSettings",
            payload: settings
        }, "*");

    };

    const sendTestimonialsToPreview = () => {

        const ele = document.getElementById("wall-of-love-preview") as HTMLIFrameElement;

        if (!ele) return;

        if (!testimonials?.testimonials || !testimonials.testimonials.length) {
            getAll.refetch();
            return;
        };

        const parsedTestimonials = testimonials.testimonials.map(item => {

            delete item.customerDetails["email"];

            return {
                id: item.id,
                customerDetails: item.customerDetails,
                imageAttachments: item.imageAttachments,
                rating: item.rating,
                source: item.source,
                submittedAt: item.submittedAt,
                textTestimonialContent: item.textTestimonialContent,
                transcribed: item.transcribed,
                transcribedText: item.transcribedText,
                type: item.type,
                url: item.url,
                videoTestimonialThumbnail: item.videoTestimonialThumbnail
            } as WallOfLoveTestimonialType;

        });

        ele.contentWindow?.postMessage({
            type: "wallOfLoveTestimonials",
            payload: parsedTestimonials ?? []
        }, "*");

    };


    return (
        <div className="w-full h-full">

            <div className={cn(`w-full h-full max-h-full overflow-y-hidden flex flex-col items-center justify-center`, {
                "invisible": showSelectTestimonials
            })}>

                <WallOfLovePreviewHeader />

                <div className="overflow-y-scroll h-full w-full">

                    <iframe
                        id="wall-of-love-preview"
                        src={renderEngineUrl}
                        className="w-full h-full"
                        onLoad={() => setLoaded(false)}
                    />


                </div>

            </div>

        </div>
    );
}