import { cn } from "@utils/cn";

type Props = {
    type: TabsTypes;
    previewImage: string;
    templateId: string;
    name: string,
    className?: string,
    imgClassName?: string,
    onClick: () => void;
};

export function PreviewCard({ previewImage, templateId, className, imgClassName, onClick }: Props) {
    return (
        <div
            key={templateId}
            className={cn(`
                border border-gray-300 bg-gray-100 rounded-md 
                w-[260px] h-[240px] relative
                flex items-center justify-center
                cursor-pointer group shadow-lg overflow-hidden
            `, className)}
            onClick={() => onClick()}
        >

            <img
                src={previewImage}
                className={cn(`
                    group-hover:scale-[1.03] w-full rounded-lg
                    transition-all duration-300 ease-in-out 
                `, imgClassName)}
            />

        </div>
    );
}