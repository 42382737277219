import { StoreKeys } from "@store";

enum Keys {
    formSnapshot = "_formSnapshot",
    onboardingCompleted = "_co",
    lastSendForgotPassword = "l_fp",
    auth = "_a",
    widgetSnapshot = "_ws"
};

type storeKeysTypes = keyof (typeof StoreKeys);

type keyTypes = (keyof (typeof Keys));

type keys = keyTypes | storeKeysTypes;

export class LocalStorageUtil {

    static get<T>(key: keys) {
        try {

            const item = localStorage.getItem(key);

            if (!item) return null;

            const parsed = JSON.parse(item);

            return parsed as T;

        } catch (err) {
            return null;
        }
    };

    static set<T>(key: keys, item: T) {

        localStorage.setItem(key, JSON.stringify(item));

    }

    static remove(key: keys) {

        localStorage.removeItem(key);

    }

    static clear(keys?: keys[]) {

        if (keys && keys.length) {

            for (let key of keys) {

                localStorage.removeItem(key);

            }

        }

        localStorage.clear();

    }

}
