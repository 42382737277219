import { Fragment } from "react";
import { CollectCard } from "./emptyTestimonials/collectCard";
import { ImportCard } from "./emptyTestimonials/importCard";
import { Stats } from "./stats";
import { cn } from "@utils/cn";
import { QueryKeys, useGetQueryData } from "@hooks";
import { SpacesHeader } from "./header";
import { TestimonialsTrendChart } from "./trend";

export function DashboardPage() {

    const data = useGetQueryData<Api.SpacesClient.getDetails>([QueryKeys.spaces.getDetails]);

    const noTestimonials = (data?.analytics) ? false : true;

    return (
        <section className={cn(`
            w-full h-full overflow-y-auto scrollbar space-y-2 
        `, {
            "px-4": noTestimonials,
            "px-8 space-y-2": !noTestimonials
        })}>

            <SpacesHeader />

            {noTestimonials ?
                <div className="w-full flex items-start justify-evenly h-[36%] py-2">

                    <CollectCard />

                    <ImportCard />

                </div> :
                <div className="w-full flex flex-col items-start justify-evenly space-y-6">

                    <Stats />

                    {data.monthlyTestimonialsTrend && <TestimonialsTrendChart />}

                </div>
            }

        </section>
    );
}